<template>
  <vis-data-table :headers="columns" :items="fields">
    <template #top>
      <vis-sheet>
        <v-row>
          <v-col cols="6">
            <strong>
              {{ showAsTable ? 'Page view' : 'Table view' }}
            </strong>
          </v-col>
          <v-col cols="6" class="text-end">
            <vis-btn @click="showAsTable = !showAsTable" size="small">{{
              showAsTable ? 'Click to switch to page view' : 'Click to switch to table view'
            }}</vis-btn
            >&nbsp;
            <v-tooltip v-if="hasAddEventListener" text="Tooltip" location="left">
              <template #activator="{ props }">
                <vis-add-icon-btn v-bind="props" @click="emit('add')" />
              </template>
              {{ $t('create') }}
            </v-tooltip>
          </v-col>
        </v-row>
      </vis-sheet>
    </template>
    <template #bottom />
    <template #body="{ items }">
      <template v-if="fields.length > 0">
        <draggable
          :list="getOrderedItems([...items])"
          item-key="id"
          tag="tbody"
          @change="onReorder"
        >
          <template #item="{ element: field }: { element: Field }">
            <tr class="v-data-table__tr v-data-table__tr--clickable" @click="onRowClick(field)">
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="id" :project="project" :value="field.key" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="string" :project="project" :value="field.label" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="id" :project="project" :value="field.type" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <vis-switch
                  v-if="showAsTable"
                  :model-value="field.hiddenInList"
                  @click.stop="emit('hiddenInList', field)"
                  :disabled="!hasHiddenInList"
                />
                <vis-switch
                  v-else
                  :model-value="field.hiddenInDetails"
                  @click.stop="emit('hiddenInDetails', field)"
                  :disabled="!hasHiddenInDetails"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <vis-switch
                  :model-value="field.filterable"
                  @click.stop="emit('filterable', field)"
                  :disabled="!hasFilterable"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <vis-icon-btn
                  :icon="icons[field.sortable].icon"
                  :color="icons[field.sortable].color"
                  @click.stop="emit('sortable', field)"
                  :disabled="!hasSortable"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip v-if="!field.isSystem && hasDeleteEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(field)" />
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasReorderEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-drag-icon-btn v-bind="props" />
                  </template>
                  {{ $t('dragToReorder') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div></template
      >
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import Draggable from 'vuedraggable'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import type Field from '@/types/field'
import type Project from '@/types/project'
import { useI18n } from 'vue-i18n'
import { getCurrentInstance } from 'vue'

const { fields } = defineProps<{
  project: Project
  fields: Array<Field>
}>()

const emit = defineEmits<{
  add: []
  delete: [field: Field]
  select: [field: Field]
  reorder: [reordered: Array<Partial<Field>>]
  hiddenInDetails: [field: Field]
  hiddenInList: [field: Field]
  filterable: [field: Field]
  sortable: [field: Field]
}>()

defineSlots<{
  top(props: {}): any
}>()

const hasAddEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onAdd)
const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)
const hasReorderEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onReorder)
const hasHiddenInDetails = computed(() => !!getCurrentInstance()?.vnode.props?.onHiddenInDetails)
const hasHiddenInList = computed(() => !!getCurrentInstance()?.vnode.props?.onHiddenInList)
const hasFilterable = computed(() => !!getCurrentInstance()?.vnode.props?.onFilterable)
const hasSortable = computed(() => !!getCurrentInstance()?.vnode.props?.onSortable)

const icons = {
  asc: { icon: 'mdi-arrow-up-thick', color: 'success' },
  desc: { icon: 'mdi-arrow-down-thick', color: 'success' },
  enabled: { icon: 'mdi-minus-thick', color: 'success' },
  disabled: { icon: 'mdi-close-thick', color: 'error' }
}

const showAsTable = ref(true)

const { t } = useI18n()

const columns = [
  { title: t('key'), key: 'key', sortable: false },
  { title: t('label'), key: 'label', sortable: false },
  { title: t('type'), key: 'type', sortable: false },
  { title: t('hide'), key: 'hide', sortable: false },
  { title: t('filterable'), key: 'filterable', sortable: false },
  { title: t('sortable'), key: 'sortable', sortable: false },
  { title: '', key: 'actions', sortable: false }
]

function onRowDelete(field: Field) {
  emit('delete', field)
}

function onRowClick(field: Field) {
  emit('select', field)
}

const onReorder = (event: Moved<Field>) => {
  const copy = getOrderedItems([...fields])
  const left = copy[event.moved.oldIndex]
  const right = copy[event.moved.newIndex]
  copy[event.moved.oldIndex] = right
  copy[event.moved.newIndex] = left
  copy.forEach((c, i) => {
    if (showAsTable.value) {
      c.orderInList = i
    } else {
      c.orderInDetails = i
    }
  })
  emit('reorder', copy)
}

function getOrderedItems(fs: Array<Field>) {
  return fs.sort((a, b) => {
    if (showAsTable.value) {
      return a.orderInList - b.orderInList
    } else {
      return a.orderInDetails - b.orderInDetails
    }
  })
}

onMounted(() => {
  const sortableTbody = document.querySelector('.v-table__wrapper > table > tbody > tbody')
  const tbody = document.querySelector('.v-table__wrapper > table > tbody')
  tbody!.parentNode!.append(sortableTbody!)
  tbody!.remove()
})
</script>
