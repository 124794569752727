import type { InjectionKey, Ref } from 'vue'
import type { useFetch } from '@vueuse/core'
import type KeycloakProvider from './configs/keycloak/KeycloakProvider'

const Drawer = Symbol() as InjectionKey<Ref<boolean>>
const Drawable = Symbol() as InjectionKey<Ref<boolean>>
const Api = Symbol() as InjectionKey<typeof useFetch>
const Keycloak = Symbol() as InjectionKey<KeycloakProvider>

export { Drawer, Drawable, Api, Keycloak }
