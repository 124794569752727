import type KeycloakScope from '@/configs/keycloak/KeycloakScope'
import useAuthStore from '@/stores/auth'
import type { RouteRecordRaw } from 'vue-router'
import scopeCheck from './scopeCheck'

interface MenuItem {
  key: string
  icon: string
  name: string
  exact?: boolean
  children?: Array<MenuItem>
}

function walkRoute(route: RouteRecordRaw, parent: Array<MenuItem>, scopes: Array<KeycloakScope>) {
  let menu: MenuItem | undefined
  if (route.meta && 'menu' in route.meta) {
    let included = true
    if ('scopes' in route.meta) {
      if (!scopeCheck(scopes, ...(route.meta.scopes as Array<KeycloakScope>))) {
        included = false
      }
    }
    if (included) {
      menu = route.meta.menu as MenuItem
      parent.push(menu)
    }
  }
  if (route.children) {
    for (const child of route.children) {
      if (menu) {
        if (!menu.children) {
          menu.children = []
        }
        walkRoute(child, menu.children, scopes)
      } else {
        walkRoute(child, parent, scopes)
      }
    }
  }
}

export default function routesToMenuConverter(
  routeTree: Readonly<Array<RouteRecordRaw>>
): Array<MenuItem> {
  const authStore = useAuthStore()
  const scopes = authStore.scopes
  const menu: Array<MenuItem> = []
  for (const node of routeTree) {
    walkRoute(node, menu, scopes)
  }
  return menu
}
