import type { UnwrapRef } from 'vue'
import type { Ref } from 'vue'
import { ref, watch } from 'vue'

type FetchResultInference<T> = T extends FetchResult<infer U> ? U : T

export default function useWatchFetchResult<T extends FetchResult<any>>(
  source: T,
  def?: any
): Ref<UnwrapRef<FetchResultInference<T>>> {
  const refToSource = ref<FetchResultInference<T>>(def)
  watch(
    () => source.data,
    () => (refToSource.value = source.data || def),
    { immediate: true }
  )
  return refToSource
}
