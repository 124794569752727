import type { RouteRecordRaw } from 'vue-router'
import BuildingListView from '@/views/project/buildings/BuildingListView.vue'
import createBuildingRoute from './createBuildings'
import updateBuildingRoute from './updateBuilding'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const buildingsRoute: RouteRecordRaw = {
  path: '/buildings',
  name: 'buildings',
  component: BuildingListView,
  meta: {
    breadcrumb: 'buildings',
    menu: {
      key: 'buildings',
      icon: 'mdi-domain',
      name: 'buildings'
    },
    scopes: [KeycloakScope.BUILDING_LIST]
  },
  children: [createBuildingRoute, updateBuildingRoute]
}

export default buildingsRoute
