<template>
  <vis-loader :loading="images.isFetching || patch.isFetching">
    <vis-form @submit.prevent="onSubmit">
      <vis-sheet>
        <MediaGallery
          :project="project.data!"
          :images="images.data!"
          :preSelected="preSelected"
          :multiple="multiple"
          :hasSelectEventListener="allowSelect"
          @select="onSelect"
          @delete="onDelete"
        />
        <v-divider></v-divider>

        <div class="d-flex align-center justify-space-around">
          <div class="file-input-container">
            <vis-file-input
              v-model="files"
              multiple
              accept="image/png, image/jpeg, image/bmp"
              :label="t('uploadNewImages')"
            />
          </div>
          <div v-if="!category" class="pa-3 align-self-center">
            <v-select
              :label="t('category')"
              v-model="categoryInput"
              :items="[...Object.values(ProjectImagesCategory)]"
            >
            </v-select>
          </div>
          <div class="flex-grow-1 ml-2">
            <vis-submit-btn class="mt-2 display-block" type="submit">{{
              $t('uploadImages')
            }}</vis-submit-btn>
          </div>
        </div>
      </vis-sheet>
    </vis-form>
  </vis-loader>
</template>

<script setup lang="ts">
import MediaGallery from '@/components/media/MediaGallery.vue'
import useImageStore from '@/stores/image'
import useProjectStore from '@/stores/project'
import { ProjectImagesCategory } from '@/types/image'
import { onMounted, ref } from 'vue'
import type Image from '@/types/image'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  preSelected: { type: Array<number>, default: () => [] },
  multiple: { type: Boolean, default: false },
  allowSelect: { type: Boolean, default: false },
  category: { type: String, default: undefined }
})

const categoryInput = ref()
const { project } = useProjectStore()
const { t } = useI18n()

onMounted(() => {
  categoryInput.value = ProjectImagesCategory.ContentAssets

  if (props.category) {
    categoryInput.value = props.category
  }
})

const { images, patch, listImages, createImage, patchImages, deleteImage } = useImageStore()
images.data || listImages()

const files = ref<Array<File>>([])

const onDelete = async (image: Image) => {
  if (confirm(t('areYouSureYouWantToDeleteTheImage', [image.id]))) {
    await deleteImage(image.id)
    listImages()
  }
}

// const onReorder = async (reordered: Array<Partial<Image>>) => {
//   await patchImages(reordered)
//   listImages()
// }

const onSubmit = async () => {
  const formData = new FormData()
  for (const file of files.value) {
    formData.append(categoryInput.value, file)
  }
  await createImage(formData)
  listImages()
}

const onSelect = (images: Array<Image>) => {
  emit('onSelect', images)
}

const emit = defineEmits<{
  onSelect: [images: Array<Image>]
}>()
</script>
<style scoped>
.file-input-container {
  min-width: 300px;
  width: 70%;
}
</style>
