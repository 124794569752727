import exp from 'constants'

// Database table
export default interface ProjectStyle {
  id: number
  projectId: number
  title: string
  isDefault: boolean
  style: Style
  updatedAt: string
  createdAt: string
}

export interface Style {
  materials: Array<RoomSurfaceStyle>
  mouldings: Array<RoomMouldings>
  doors: DoorSpecification
  windows: object
  assets: object
}

export interface AssetWithMaterial {
  assetId: ArtistAssetId
  materials: Array<AssetMaterial> | null
}

export interface AssetMaterial {
  key: string
  value: ArtistAssetId
}

export interface DoorSpecification {
  exteriorDoor: AssetWithMaterial
  interiorDoor: AssetWithMaterial
  slidingDoor: AssetWithMaterial | null
  balconyDoor: AssetWithMaterial | null
}

export enum RoomType {
  'Balcony' = 'Balcony',
  'Bathroom' = 'Bathroom',
  'Bedroom' = 'Bedroom',
  'Diningroom' = 'Diningroom',
  'Entrance' = 'Entrance',
  'Generic' = 'Generic',
  'Hallway' = 'Hallway',
  'Kitchen' = 'Kitchen',
  'Livingroom' = 'Livingroom',
  'Recreational' = 'Recreational',
  'Office' = 'Office',
  'Storage' = 'Storage',
  'Techroom' = 'Techroom',
  'WC' = 'WC'
}

export interface RoomSurfaceStyle {
  key: RoomType
  label: string
  surfaces: Array<MaterialOrAssetSpecification>
}

type ArtistAssetId = string

// key : floor, celing, wall, ascent wall etc.
// id : M000001 or A000001 id if asset or material
// variant : identifier of the variant if any
export interface MaterialOrAssetSpecification {
  key: string
  id: ArtistAssetId
  variant: string | null
}

export interface RoomMouldings {
  key: RoomType
  label: string
  mouldings: Array<MouldingSpecification>
}

export interface MouldingSpecification {
  position: string
  id: ArtistAssetId
  variant: string | null
}
