import { useI18n } from 'vue-i18n'

export default function useTimeOfDayOptionTableDefinitions(): VDataTableColumns {
  const { t } = useI18n()
  return [
    { title: t('id'), key: 'id', type: 'number', searchable: true, filterable: false },
    { title: t('dateTime'), key: 'dateTime', type: 'id', filterable: false },
    {
      title: t('northDirectionAngle'),
      key: 'northDirectionAngle',
      type: 'number',
      searchable: true,
      filterable: false
    },
    { title: t('createdAt'), key: 'createdAt', type: 'date', filterable: false },
    { title: t('updatedAt'), key: 'updatedAt', type: 'date', filterable: false },
    { title: '', key: 'actions', sortable: false }
  ]
}
