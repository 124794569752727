import type { RouteRecordRaw } from 'vue-router'
import WebhookIntegrationUpdateView from '@/views/project/integrations/webhookIntegrations/WebhookIntegrationUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import createWebhookIntegrationFieldRoute from './webhookIntegrationField.ts/createWebhookIntegrationField'
import updateWebhookIntegrationFieldRoute from './webhookIntegrationField.ts/updateWebhookIntegrationField'

const updateWebhookIntegrationRoute: RouteRecordRaw = {
  path: ':integrationId',
  name: 'updateWebhookIntegration',
  component: WebhookIntegrationUpdateView,
  meta: {
    breadcrumb: 'updateWebhookIntegration',
    scopes: [KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  },
  children: [createWebhookIntegrationFieldRoute, updateWebhookIntegrationFieldRoute]
}

export default updateWebhookIntegrationRoute
