<template>
  <vis-loader :loading="status.isFetching">
    <vis-sheet>
      <status-form @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useStatusStore from '@/stores/status'
import StatusForm from '@/components/forms/StatusForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { status, listStatuses, createStatus } = useStatusStore()

const onSubmit = useSubmitThenRoute(
  createStatus,
  (b) => ({ name: 'updateStatus', params: { statusId: b.data!.id } }),
  () => listStatuses()
)
</script>
