<template>
  <template v-if="route.name === 'units'">
    <vis-loader :loading="units.isFetching || buildings.isFetching || statuses.isFetching">
      <v-row>
        <v-col sm="3" cols="12">
          <data-table-filter
            :columns="columns"
            :items="items"
            :statuses="statuses.data!"
            @update="filtered = $event"
            search-on-bottom
          />
        </v-col>
        <v-col sm="9" cols="12">
          <data-table
            :columns="columns"
            :items="filtered"
            :project="project.data!"
            :statuses="statuses.data!"
            v-on="{
              add: check(KeycloakScope.UNIT_CREATE) ? onAdd : undefined,
              select: check(KeycloakScope.UNIT_UPDATE) ? onSelect : undefined,
              delete: check(KeycloakScope.UNIT_DELETE) ? onDelete : undefined
            }"
          />
        </v-col>
      </v-row>
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useUnitStore from '@/stores/unit'
import useProjectStore from '@/stores/project'
import useStatusStore from '@/stores/status'
import useBuildingStore from '@/stores/building'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import useUnitTableDefinitions from '@/composables/tableDefinitions/useUnitTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import type Unit from '@/types/unit'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()
const { statuses, listStatuses } = useStatusStore()

statuses.data || listStatuses()
const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { units, listUnits, deleteUnit } = useUnitStore()
units.data || listUnits()

const items = useWatchFetchResult(units, [])
const columns = computed(() => useUnitTableDefinitions(buildings.data!))
const filtered = ref<Array<Unit>>([])

const onAdd = () => router.push({ name: 'createUnit' })

const onSelect = (unit: Unit) =>
  router.push({
    name: 'updateUnit',
    params: {
      unitId: unit.id
    }
  })

const onDelete = async (unit: Unit) => {
  if (confirm(t('areYouSureYouWantToDeleteTheUnit', [unit.id]))) {
    await deleteUnit(unit.id)
    listUnits()
  }
}
</script>
