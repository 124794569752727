<template>
  <vis-btn size="small">
    <v-icon icon="mdi-web" />&nbsp;{{ language.toUpperCase() }}
    <v-menu activator="parent" location="bottom end" transition="fade-transition">
      <v-list density="compact" min-width="250" slim>
        <v-list-item
          link
          v-for="locale of locales"
          :key="locale"
          :disabled="language === locale"
          @click="switchLanguage(locale)"
          >{{ $t(`lang${locale.toUpperCase()}`) }}</v-list-item
        >
      </v-list>
    </v-menu>
  </vis-btn>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const i18n = useI18n()
const language = i18n.locale
const locales = i18n.availableLocales
const switchLanguage = (locale: string) => {
  i18n.locale.value = locale
}
</script>
