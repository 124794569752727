<template>
  <template v-if="route.name === 'timeOfDayOptions'">
    <vis-loader :loading="timeOfDayOptions.isFetching">
      <v-row>
        <v-col sm="3" cols="12">
          <data-table-filter
            :columns="columns"
            :items="items"
            @update="filtered = $event"
            search-on-bottom
          />
        </v-col>
        <v-col sm="9" cols="12">
          <data-table
            :columns="columns"
            :items="filtered"
            :project="project.data!"
            v-on="{
              add: check(KeycloakScope.ASSETS_UPDATE) ? onAdd : undefined,
              select: check(KeycloakScope.ASSETS_UPDATE) ? onSelect : undefined,
              delete: check(KeycloakScope.ASSETS_UPDATE) ? onDelete : undefined
            }"
          />
        </v-col>
      </v-row>
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useTimeOfDayOptionStore from '@/stores/timeOfDayOption'
import useProjectStore from '@/stores/project'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import type TimeOfDayOption from '@/types/timeOfDayOption'
import useTimeOfDayOptionTableDefinitions from '@/composables/tableDefinitions/useTimeOfDayOptionTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { timeOfDayOptions, listTimeOfDayOptions, deleteTimeOfDayOption } = useTimeOfDayOptionStore()
timeOfDayOptions.data || listTimeOfDayOptions()

const items = useWatchFetchResult(timeOfDayOptions, [])
const columns = computed(() => useTimeOfDayOptionTableDefinitions())
const filtered = ref<Array<TimeOfDayOption>>([])

const onAdd = () => router.push({ name: 'createTimeOfDayOption' })

const onSelect = (timeOfDayOption: TimeOfDayOption) =>
  router.push({
    name: 'updateTimeOfDayOption',
    params: {
      timeOfDayOptionId: timeOfDayOption.id
    }
  })

const onDelete = async (timeOfDayOption: TimeOfDayOption) => {
  if (confirm(t('areYouSureYouWantToDeleteTheTimeOfDayOption', [timeOfDayOption.id]))) {
    await deleteTimeOfDayOption(timeOfDayOption.id)
    listTimeOfDayOptions()
  }
}
</script>
