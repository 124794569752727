<template>
  <vis-loader
    class="h-100"
    :loading="
      analytics.isFetching || units.isFetching || buildings.isFetching || statuses.isFetching
    "
  >
    <div ref="chartContainer" class="h-100 w-100">
      <v-row v-if="chartsHeight !== 0 && chartsWidth !== 0">
        <v-col cols="12" class="d-flex justify-center align-center">
          <Line
            :height="chartsHeight / 2"
            :width="chartsWidth"
            :data="analyticsData"
            :options="options(t('pageViews'), 'end')"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-center">
          <Doughnut
            :height="chartsHeight / 2"
            :width="chartsWidth / 4"
            :data="buildingsMostVisited"
            :options="options(t('visitedBuildings'))"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-center">
          <h2 v-if="!unitsMostVisited">{{ t('noVisitData') }}</h2>
          <Doughnut
            v-else
            :height="chartsHeight / 2"
            :width="chartsWidth / 4"
            :data="unitsMostVisited"
            :options="options(t('visitedUnits'))"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-center">
          <Doughnut
            :height="chartsHeight / 2"
            :width="chartsWidth / 4"
            :data="unitsStatuses"
            :options="options(t('unitStatuses'))"
          />
        </v-col>
        <v-col cols="3" class="d-flex justify-center align-center">
          <h2 v-if="!cameraStatuses">{{ t('noCameraData') }}</h2>
          <Doughnut
            v-else
            :height="chartsHeight / 2"
            :width="chartsWidth / 4"
            :data="cameraStatuses"
            :options="options('cameraData')"
          />
        </v-col>
      </v-row>
    </div>
  </vis-loader>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { Line, Doughnut } from 'vue-chartjs'
import useAnalyticsStore from '@/stores/analytics'
import useUnitStore from '@/stores/unit'
import useBuildingStore from '@/stores/building'
import useStatusStore from '@/stores/status'
import useI18nTranslator from '@/composables/useI18nTranslator'
import { watchEffect } from 'vue'

const { t } = useI18n()
const translator = useI18nTranslator()
const chartContainer = ref<HTMLDivElement>()

const { analytics, listAnalytics } = useAnalyticsStore()
analytics.data || listAnalytics()

const { units, listUnits } = useUnitStore()
units.data || listUnits()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const analyticsData = computed(() => {
  return {
    labels: Object.keys(analytics.data!),
    datasets: [
      { key: 'activeUsers' },
      { key: 'averageSessionDuration' },
      { key: 'engagedSessions' },
      { key: 'engagementRate' },
      { key: 'newUsers' },
      { key: 'screenPageViews' },
      { key: 'screenPageViewsPerSession' },
      { key: 'sessions' },
      { key: 'sessionsPerUser' },
      { key: 'userEngagementDuration' }
    ].map(({ key }) => {
      const data = Object.values(analytics.data!).map((a) =>
        a.sessionAnalytics.reduce((acc, sa) => {
          return acc + (sa as any)[key]
        }, 0)
      )
      return {
        label: t(key),
        borderWidth: 1,
        data
      }
    })
  }
})

const buildingsMostVisited = computed(() => {
  const buildingData: { [key: string]: number } = {}
  Object.values(analytics.data!).forEach((a) => {
    a.sessionAnalytics.forEach((sa) => {
      if (sa.building) {
        if (!(sa.building in buildingData)) {
          buildingData[sa.building] = 0
        }
        buildingData[sa.building] += sa.sessions
      }
    })
  })
  return {
    labels: buildings.data!.map((b) => b.name),
    datasets: [
      {
        data: buildings.data!.map((b) => {
          return b.slug! in buildingData ? buildingData[b.slug!] : 0
        })
      }
    ]
  }
})

const unitsMostVisited = computed(() => {
  const unitData: { [key: string]: number } = {}
  Object.values(analytics.data!).forEach((a) => {
    a.sessionAnalytics.forEach((sa) => {
      if (sa.unit) {
        if (!(sa.unit in unitData)) {
          unitData[sa.unit] = 0
        }
        unitData[sa.unit] += sa.sessions
      }
    })
  })
  const sorted = Object.entries(unitData)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .sort(([_, qtyA], [__, qtyB]) => qtyA - qtyB)
    .reduce<{ [key: string]: number }>((acc, [key, qty], index, array) => {
      if (array.length > 5) {
        if (index < 4) {
          const unit = units.data!.find((u) => u.unitId === key)?.title
          return { ...acc, [unit || key]: qty }
        } else if (index === 4) {
          return { ...acc, [t('other')]: qty }
        } else {
          ;(acc as any)[t('other') as any] += qty as any
          return acc
        }
      } else {
        const unit = units.data!.find((u) => u.unitId === key)?.title
        return { ...acc, [unit || key]: qty }
      }
    }, {})
  return Object.keys(sorted).length === 0
    ? false
    : {
        labels: Object.keys(sorted),
        datasets: [
          {
            data: Object.values(sorted)
          }
        ]
      }
})

const unitsStatuses = computed(() => {
  const unitStatusData: { [key: number]: number } = {}
  units.data!.forEach((u) => {
    if (!(u.status in unitStatusData)) {
      unitStatusData[u.status] = 0
    }
    unitStatusData[u.status]++
  })
  return {
    labels: statuses.data!.map((s) => translator(s.label)),
    datasets: [
      {
        backgroundColor: statuses.data!.map((s) => s.textColor),
        data: statuses.data!.map((s) => {
          return s.id! in unitStatusData ? unitStatusData[s.id!] : 0
        })
      }
    ]
  }
})

const cameraStatuses = computed(() => {
  const unitData: { [key: string]: number } = {}
  Object.values(analytics.data!).forEach((a) => {
    a.cameraAnalytics.forEach((ca) => {
      if (ca.cameraTitle) {
        if (!(ca.cameraTitle in unitData)) {
          unitData[ca.cameraTitle] = 0
        }
        unitData[ca.cameraTitle] += ca.sessions
      }
    })
  })
  const sorted = Object.entries(unitData)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .sort(([_, qtyA], [__, qtyB]) => qtyA - qtyB)
    .reduce<{ [key: string]: number }>((acc, [key, qty], index, array) => {
      if (array.length > 5) {
        if (index < 4) {
          return { ...acc, [key]: qty }
        } else if (index === 4) {
          return { ...acc, [t('other')]: qty }
        } else {
          ;(acc as any)[t('other') as any] += qty as any
          return acc
        }
      } else {
        return { ...acc, [key]: qty }
      }
    }, {})
  return Object.keys(sorted).length === 0
    ? false
    : {
        labels: Object.keys(sorted),
        datasets: [
          {
            data: Object.values(sorted)
          }
        ]
      }
})

const options = (text: string, align = 'center'): any => ({
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'left'
    },
    title: {
      font: {
        size: 24
      },
      display: true,
      align,
      position: 'top',
      fullSize: false,
      text
    }
  },
  borderWidth: 0
})

const chartsWidth = ref(0)
const chartsHeight = ref(0)
watchEffect(() => {
  if (chartContainer.value) {
    const resizeObserver = new ResizeObserver(([e]) => {
      chartsHeight.value = e.contentRect.height - 32
      chartsWidth.value = e.contentRect.width - 32
    })
    resizeObserver.observe(chartContainer.value!)
  }
})
</script>
