<template>
  <v-col cols="4">
    <vis-loader :loading="integration.isFetching">
      <vis-sheet>
        <h4>{{ $t('createWebhookIntegration') }}</h4>
        <webhook-integration-form @submit="onSubmit" />
      </vis-sheet>
    </vis-loader>
  </v-col>
  <v-col cols="8"></v-col>
</template>

<script setup lang="ts">
import useIntegrationStore from '@/stores/integration'
import WebhookIntegrationForm from '@/components/forms/WebhookIntegrationForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { integration, listIntegrations, createIntegration } = useIntegrationStore()

const onSubmit = useSubmitThenRoute(
  createIntegration,
  (u) => ({ name: 'updateWebhookIntegration', params: { integrationId: u.data!.id } }),
  () => listIntegrations()
)
</script>
