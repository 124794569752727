<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="role"
          :placeholder="$t('role')"
          :label="$t('role')"
          v-model="contact.role"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="name"
          :placeholder="$t('name')"
          :label="$t('name')"
          v-model="contact.name"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="email"
          :placeholder="$t('email')"
          :label="$t('email')"
          v-model="contact.email"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="phone"
          :placeholder="$t('phone')"
          :label="$t('phone')"
          v-model="contact.phone"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-switch
          :placeholder="$t('uploadNewImage')"
          :label="$t('uploadNewImage')"
          v-model="uploadNewImage"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <template v-if="uploadNewImage">
          <vis-file-input
            @change="onFileChange"
            :label="$t('newImage')"
            :placeholder="$t('newImage')"
            accept="image/png, image/jpeg, image/bmp"
          />
        </template>
        <template v-else>
          <vis-select
            name="projectImageId"
            :items="images"
            item-title="filename"
            item-value="id"
            :placeholder="$t('image')"
            :label="$t('image')"
            v-model="contact.projectImageId"
          >
            <template #item="{ props, item }">
              <v-list-item v-bind="props">
                <img
                  height="50"
                  :src="`${project.assetRoot}/images/${item.raw.category}/${item.raw.filename}`"
                />
              </v-list-item>
            </template>
          </vis-select>
        </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type Contact from '@/types/contact'
import type Image from '@/types/image'
import type Project from '@/types/project'

const props = defineProps<{
  contact?: Contact
  project: Project
  images: Array<Image>
}>()

const emit = defineEmits<{
  submit: [contact: Partial<Contact>, projectImage?: File]
}>()

const uploadNewImage = ref(false)
const uploadedImage = ref<File>()
function onFileChange(e: Event) {
  uploadedImage.value = (e.target as HTMLInputElement).files?.item(0) || undefined
}

const contact = reactive<Partial<Contact>>(props.contact || {})

const { formRef, submit } = useFormPartials(contact, (partial: Partial<Contact>) =>
  emit('submit', partial, uploadNewImage.value ? uploadedImage.value : undefined)
)
</script>
