<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="name"
          :placeholder="$t('name')"
          :label="$t('name')"
          v-model="building.name"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="slug"
          :placeholder="$t('slug')"
          :label="$t('slug')"
          v-model="building.slug"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="publicId"
          :placeholder="$t('publicId')"
          :label="$t('publicId')"
          v-model="building.publicId"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <v-label class="small">{{ $t('buyUrlStart') }}</v-label>
        <vue-date-picker
          :format="(date: Date) => date.toLocaleString()"
          utc
          name="buyUrlStart"
          v-model="building.buyUrlStart"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-switch
          name="active"
          :placeholder="$t('active')"
          :label="$t('active')"
          v-model="building.active"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type Building from '@/types/building'

const props = defineProps<{ building?: Building }>()
const emit = defineEmits<{
  submit: [building: Partial<Building>]
}>()

const building = reactive<Partial<Building>>(props.building || {})

const { formRef, submit } = useFormPartials(
  building,
  (partial: Partial<Building>) => emit('submit', partial),
  'buyUrlStart'
)
</script>
