<template>
  <vis-sheet>
    <v-row>
      <v-col cols="12">
        <vis-sheet
          ><h4>
            {{ $t('webhookIntegrations') }}
            <v-tooltip location="left">
              <template #activator="{ props }">
                <vis-add-icon-btn
                  style="float: right"
                  v-bind="props"
                  @click="() => router.push({ name: 'createWebhookIntegration' })"
                />
              </template>
              {{ $t('create') }}
            </v-tooltip>
          </h4>
          <vis-loader :loading="integrations.isFetching">
            <data-table
              :columns="columns"
              :items="integrations.data! || []"
              @select="onSelect"
              @delete="onDelete"
            />
          </vis-loader>
        </vis-sheet>
      </v-col>
      <vis-loader style="align-items: flex-start; width: 100%" :loading="integrations.isFetching">
        <router-view />
      </vis-loader>
    </v-row>
  </vis-sheet>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import DataTable from '@/components/dataTable/DataTable.vue'
import useIntegrationTableDefinitions from '@/composables/tableDefinitions/useIntegrationTableDefinitions'
import useIntegrationStore from '@/stores/integration'
import type Integration from '@/types/integration'

const { t } = useI18n()
const router = useRouter()
const { integrations, listIntegrations, deleteIntegration } = useIntegrationStore()
integrations.data || listIntegrations()
const columns = useIntegrationTableDefinitions()

async function onSelect(data: Partial<Integration>) {
  router.push({
    name: 'updateWebhookIntegration',
    params: { integrationId: data.id }
  })
}

async function onDelete(data: Partial<Integration>) {
  if (confirm(t('areYouSureYouWantToDeleteTheIntegration'))) {
    router.push({ name: 'webhookIntegrations' })
    await deleteIntegration(data.id)
    await listIntegrations()
  }
}
</script>
