import { type NavigationGuardWithThis } from 'vue-router'
import useProjectStore from '@/stores/project'
import useContextStore from '@/stores/context'
import useAuthStore from '@/stores/auth'
import type KeycloakScope from '@/configs/keycloak/KeycloakScope'
import useAppStore from '@/stores/app'
import i18n from '@/configs/i18n'
import scopeCheck from '@/utils/scopeCheck'

const auth: NavigationGuardWithThis<undefined> = async function (to, _from, next) {
  const authStore = useAuthStore()
  const appStore = useAppStore()
  const t = i18n.global.t

  await new Promise<void>((resolve) => {
    if (authStore.ready) {
      resolve()
    } else {
      const unsubscribe = authStore.$subscribe((_mutation, state) => {
        if (state.ready) {
          unsubscribe()
          resolve()
        }
      })
    }
  })

  let redirect = false
  const { projectId, selectProjectId } = useContextStore()
  if (to.meta.needProject) {
    if (!projectId) {
      redirect = true
    } else {
      const { project, readProject } = useProjectStore()
      if (!project || !project.data || project.data.id !== projectId) {
        const p = await readProject(projectId)
        if (!p || !p.data || p.data.id !== projectId) {
          redirect = true
        }
      }
    }
  }

  if (!redirect && !authStore.isAdmin) {
    if (to.meta.scopes) {
      if (!scopeCheck(authStore.scopes, ...(to.meta.scopes as Array<KeycloakScope>))) {
        redirect = true
        appStore.addMessage({ message: t('missingScope'), color: 'error' })
      }
    }
  }

  if (redirect) {
    selectProjectId(null)
    next({ name: 'selectProject' })
  } else {
    next()
  }
}

export default auth
