<template>
  <v-dialog max-width="300" v-model="openDialog" persistent>
    <v-card>
      <v-card-title>Room Style</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-select
            :items="Object.keys(RoomType)"
            v-model="roomName"
            label="Select Room Type*"
            required
          ></v-select>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text="Close" variant="plain" @click="openDialog = false"></v-btn>
        <v-btn color="primary" text="Save" @click="$emit('save', roomName)"></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script setup lang="ts">
import { RoomType } from '@/types/projectStyle'
import { ref, watch } from 'vue'

const props = defineProps<{
  open: boolean
  room: RoomType
}>()

const roomName = ref(props.room)
const openDialog = ref(props.open)

defineEmits(['close', 'save'])

watch(
  () => props.open,
  (newValue, _) => {
    openDialog.value = newValue
  }
)
</script>
