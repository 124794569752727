export enum ProjectFieldType {
  id = 'id',
  status = 'status',
  string = 'string',
  number = 'number',
  boolean = 'boolean',
  date = 'date',
  currency = 'currency',
  area = 'area',
  url = 'url',
  html = 'html'
}

export enum ProjectFieldSort {
  asc = 'asc',
  desc = 'desc',
  enabled = 'enabled',
  disabled = 'disabled'
}

export default interface Field {
  id?: number
  projectId: number
  key: string
  type: ProjectFieldType
  label: Translatable
  prefix: Translatable
  suffix: Translatable
  sortable: ProjectFieldSort
  filterable: boolean
  hiddenInList: boolean
  orderInList: number
  hiddenInDetails: boolean
  orderInDetails: number
  updatedAt: string
  createdAt: string
  isSystem: boolean
  isReadOnly: boolean
  isMandatory: boolean
}
