<template>
  <vis-loader :loading="integrationField.isFetching || fields.isFetching">
    <vis-sheet>
      <h4>{{ $t('createWebhookIntegrationField') }}</h4>
      <webhook-integration-field-form :fields="allFields" @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import useIntegrationFieldStore from '@/stores/integrationField'
import WebhookIntegrationFieldForm from '@/components/forms/WebhookIntegrationFieldForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useFieldStore from '@/stores/field'

const { integrationField, integrationFields, listIntegrationFields, createIntegrationField } =
  useIntegrationFieldStore()
const { fields, listFields } = useFieldStore()
fields.data || listFields()

const allFields = computed(() => {
  if (fields.data && integrationFields.data) {
    const usedIds = integrationFields.data.map((f) => f.projectFieldId)
    return fields.data.filter((f) => !usedIds.includes(f.id!))
  } else {
    return []
  }
})

const onSubmit = useSubmitThenRoute(
  createIntegrationField,
  (u) => ({ name: 'updateWebhookIntegrationField', params: { integrationFieldId: u.data!.id } }),
  () => listIntegrationFields()
)
</script>
