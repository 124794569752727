<template>
  <h4>Mouldings</h4>

  <div
    v-for="(mouldingRoom, mouldingRomIndex) of mouldings"
    :key="`moulding-room-${mouldingRoom.key}-${mouldingRomIndex}`"
    class="ma-0 pa-0 bg-white"
  >
    <div>
      <div class="d-flex align-center">
        <div class="roomstyle-title pa-2 border-b align-self-stretch d-flex">
          <div>
            <span class="text-bold">{{ mouldingRoom.label || mouldingRoom.key }} </span>
            <span class="text-caption d-block">({{ mouldingRoom.key }})</span>
          </div>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn class="ml-3" size="x-small" icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>
            <v-list density="compact">
              <v-list-item
                v-for="(item, menuItemsIndex) in mouldingsRoomMenu(mouldingRoom)"
                :value="menuItemsIndex"
                :key="menuItemsIndex"
              >
                <template v-slot:prepend>
                  <v-icon :icon="item.icon" size="x-small"></v-icon>
                </template>
                <v-list-item-title @click="item.onClick" class="text-caption">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="flex-grow-1">
          <div
            class="ma-0 single-specification"
            v-for="(specification, index) of mouldingRoom.mouldings"
            :key="index + mouldingRoom.key + specification.id"
          >
            <div class="single-specification-controls">
              <v-btn
                size="x-small"
                icon="mdi-delete"
                class="ma-2"
                @click="deleteMouldingSpecification(mouldingRoom, specification.position)"
              ></v-btn>
            </div>

            <div class="single-surface d-flex align-center border pl-2 flex-grow-1">
              <div class="surface-name text-caption">
                {{ specification.position }}
              </div>
              <div class="border-s surface-spec position-relative">
                <SingleMaterialOrAsset
                  :key="`${mouldingRoom.key}-${specification.position}-${specification.id}-${specification.variant}-${index}`"
                  :artistAsset="specification"
                  :artistResources="artistResources ? artistResources : []"
                  @update="
                    (...args) =>
                      updateMouldingSpecification(mouldingRoom, specification, args[0], args[1])
                  "
                />
              </div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex justify-center ma-2">
    <v-btn
      @click="roomSurfaceStyleMouldingsDialog = true"
      size="small"
      class="rounded-xl"
      prependIcon="mdi-plus"
    >
      Add Room
    </v-btn>
  </div>

  <RoomSelector
    :open="roomSurfaceStyleMouldingsDialog"
    :room="RoomType.Generic"
    @save="($event: any) => addRoomStyleToMouldings($event)"
    @close="roomSurfaceStyleMouldingsDialog = false"
  >
  </RoomSelector>
</template>
<script setup lang="ts">
import type ArtistResource from '@/types/artistResource'
import { RoomType, type MouldingSpecification, type RoomMouldings } from '@/types/projectStyle'
import SingleMaterialOrAsset from './SingleMaterialOrAsset.vue'
import RoomSelector from './RoomSelector.vue'
import { watch, ref, onMounted, type Ref } from 'vue'
import _ from 'lodash'

const props = defineProps(['artistResources', 'projectStyleMouldings'])
const emits = defineEmits<{
  updateMouldings: [Array<RoomMouldings>]
}>()

const mouldings: Ref<Array<RoomMouldings> | undefined> = ref([])
const roomSurfaceStyleMouldingsDialog = ref(false)

onMounted(() => {
  mouldings.value = props.projectStyleMouldings
})

const mouldingsRoomMenu = (mouldingRoom: RoomMouldings) => {
  return [
    {
      label: 'Add Position',
      icon: 'mdi-plus',
      onClick: () => addMouldingSpecification(mouldingRoom)
    },
    { label: 'Edit', icon: 'mdi-pencil', onClick: () => editMouldingRoomStyle(mouldingRoom) },
    {
      label: 'Duplicate',
      icon: 'mdi-content-copy',
      onClick: () => duplicateMouldingRoomStyle(mouldingRoom)
    },
    { label: 'Delete', icon: 'mdi-delete', onClick: () => deleteMouldingRoomStyle(mouldingRoom) }
  ]
}

const defaultRoomMouldingStyle: RoomMouldings = {
  key: RoomType.Generic,
  label: RoomType.Generic,
  mouldings: [
    {
      position: 'top',
      id: '000955',
      variant: null
    },
    {
      position: 'bottom',
      id: '001022',
      variant: null
    }
  ]
}

const addMouldingSpecification = (roomMoulding: RoomMouldings) => {
  const key = prompt('Enter a key', 'position' + roomMoulding.mouldings.length)

  if (!key) return

  let newMoulding: MouldingSpecification = {
    position: key,
    id: '000955',
    variant: null
  }
  roomMoulding.mouldings = [...roomMoulding.mouldings, newMoulding]
}

const deleteMouldingSpecification = (
  roomMoulding: RoomMouldings,
  specificationPosition: string
) => {
  if (
    !confirm(`Are you sure you want to delete ${specificationPosition} for ${roomMoulding.label}?`)
  )
    return
  roomMoulding.mouldings = roomMoulding.mouldings.filter(
    (specification) => specification.position !== specificationPosition
  )
}

const editMouldingRoomStyle = (roomMoulding: RoomMouldings) => {
  const newName = prompt('Enter name', roomMoulding.label)
  if (!newName) return
  roomMoulding.label = newName
}

const addRoomStyleToMouldings = (roomName: string) => {
  roomSurfaceStyleMouldingsDialog.value = false

  let roomType: string = RoomType[roomName as keyof typeof RoomType] as string

  if (!roomType) {
    roomType = roomName
  }

  const currentRooms = mouldings.value?.map((room) => room.key as string) || []

  if (currentRooms.includes(roomType)) {
    roomType = roomType + (currentRooms.length + 1)
  }

  const defaultMouldingStyle = _.cloneDeep(defaultRoomMouldingStyle)
  const newMoulding = {
    ...defaultMouldingStyle,
    key: roomType as RoomType,
    label: roomName
  }

  mouldings.value = [...(mouldings.value as RoomMouldings[]), newMoulding]
}

const duplicateMouldingRoomStyle = (roomMoulding: RoomMouldings) => {
  const newMoulding = {
    ..._.cloneDeep(roomMoulding),
    key: (roomMoulding.key + '-copy') as RoomType,
    label: roomMoulding.label
  }

  mouldings.value = [...(mouldings.value as RoomMouldings[]), newMoulding]
}

const deleteMouldingRoomStyle = (roomMoulding: RoomMouldings) => {
  if (!confirm(`Are you sure you want to delete ${roomMoulding.key} mouldings?`)) return

  mouldings.value = mouldings.value?.filter((rm) => rm.key !== roomMoulding.key)
}

const updateMouldingSpecification = (
  mouldingRoom: RoomMouldings,
  specification: MouldingSpecification,
  artistResource: ArtistResource,
  variant: any
) => {
  const newSpec = mouldingRoom.mouldings.map((spec) => {
    if (spec.position === specification.position) {
      spec.id = artistResource.publicId
      spec.variant = variant?.id
    }
    return spec
  })

  mouldingRoom.mouldings = newSpec
}

watch(
  mouldings,
  () => {
    updateMouldings(mouldings.value as RoomMouldings[])
  },
  {
    deep: true
  }
)

const updateMouldings = (mouldings: Array<RoomMouldings>) => {
  emits('updateMouldings', mouldings)
}
</script>
<style scoped>
.surface-spec {
  width: 100%;
}

.single-specification {
  position: relative;
}
.single-specification:hover .single-specification-controls {
  display: block;
}

.single-specification-controls {
  display: none;
  z-index: 10;
  position: absolute;
  top: 15px;
  right: 0;
  scale: 0.9;
}

.roomstyle-title {
  min-width: 200px;
}

.surface-name {
  min-width: 100px;
}
</style>
