import { ref, type reactive } from 'vue'
import { VForm } from 'vuetify/components'

export default function useFormPartials<T extends { [key: string]: any }>(
  input: ReturnType<typeof reactive<T>>,
  onSubmit: (
    partial: Partial<ReturnType<typeof reactive<T>>>,
    isValid: boolean,
    event: SubmitEvent
  ) => void,
  ...additionalRefs: Array<keyof T>
): {
  formRef: ReturnType<typeof ref<VForm>>
  submit: (event: SubmitEvent) => void
} {
  const formRef = ref<VForm>()
  const submit = (event: SubmitEvent) => {
    const keys = formRef.value?.items.map((k) => k.id) || ([] as Array<string>)
    const partial: Partial<ReturnType<typeof reactive<T>>> = {}
    for (const key of keys) {
      ;(partial as any)[key] = input[key]
    }
    if (additionalRefs) {
      for (const key of additionalRefs) {
        ;(partial as any)[key] = input[key as any]
      }
    }
    onSubmit(partial, formRef.value?.isValid || false, event)
  }
  return {
    formRef,
    submit
  }
}
