import type { RouteRecordRaw } from 'vue-router'
import TimeOfDayOptionListView from '@/views/project/renderSettings/timeOfDayOptions/TimeOfDayOptionListView.vue'
import createTimeOfDayOptionRoute from './createTimeOfDayOption'
import updateTimeOfDayOptionRoute from './updateTimeOfDayOption'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const timeOfDayOptionsRoute: RouteRecordRaw = {
  path: 'timeOfDayOptions',
  name: 'timeOfDayOptions',
  component: TimeOfDayOptionListView,
  meta: {
    breadcrumb: 'timeOfDayOptions',
    menu: {
      key: 'timeOfDayOptions',
      icon: 'mdi-weather-sunset',
      name: 'timeOfDayOptions'
    },
    scopes: [KeycloakScope.ASSETS_LIST]
  },
  children: [createTimeOfDayOptionRoute, updateTimeOfDayOptionRoute]
}

export default timeOfDayOptionsRoute
