import { useI18n } from 'vue-i18n'

export default function useIntegrationTableDefinitions(): VDataTableColumns {
  const { t } = useI18n()
  return [
    { title: t('id'), key: 'id', type: 'number', searchable: false, filterable: false },
    {
      title: t('callbackChallenge'),
      key: 'callbackChallenge',
      type: 'id',
      searchable: false,
      filterable: false
    },
    {
      title: t('callbackJsonPathIdentifier'),
      key: 'callbackJsonPathIdentifier',
      type: 'id',
      searchable: false,
      filterable: false
    },
    {
      title: t('callbackRequestUrl'),
      key: 'callbackRequestUrl',
      type: 'id',
      searchable: false,
      filterable: false
    },
    {
      title: t('oAuth2TokenEndpoint'),
      key: 'oAuth2TokenEndpoint',
      type: 'id',
      searchable: false,
      filterable: false
    },
    { title: t('createdAt'), key: 'createdAt', type: 'date', filterable: false },
    { title: t('updatedAt'), key: 'updatedAt', type: 'date', filterable: false },
    { title: '', key: 'actions', sortable: false }
  ]
}
