import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type TimeOfDayOption from '@/types/timeOfDayOption'
import executeApi from '@/utils/executeApi'

const useTimeOfDayOptionStore = defineStore('timeOfDayOption', () => {
  const api = useApi()

  const timeOfDayOptionsUrl = ref('/project')
  const timeOfDayOptionsApi = api<Array<TimeOfDayOption>>(timeOfDayOptionsUrl).get().json()
  const timeOfDayOptions: FetchResult<Array<TimeOfDayOption>> =
    reactiveFetchResult(timeOfDayOptionsApi)
  const listTimeOfDayOptions = async (
    projectId?: number
  ): Promise<FetchResult<Array<TimeOfDayOption>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    timeOfDayOptionsUrl.value = `/project/${projectParam}/timeOfDayOption`
    await executeApi(timeOfDayOptionsApi)
    return timeOfDayOptions
  }

  const timeOfDayOptionUrl = ref('/project')
  const timeOfDayOptionApi = api<Array<TimeOfDayOption>>(timeOfDayOptionUrl).json()

  const timeOfDayOption: FetchResult<TimeOfDayOption> = reactiveFetchResult(timeOfDayOptionApi)
  const readTimeOfDayOption = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<TimeOfDayOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || timeOfDayOption.data?.id
    timeOfDayOptionUrl.value = `/project/${projectParam}/timeOfDayOption/${idParam}`
    await executeApi(timeOfDayOptionApi.get())
    return timeOfDayOption
  }

  const createTimeOfDayOption = async (
    data: Partial<TimeOfDayOption>,
    projectId?: number
  ): Promise<FetchResult<TimeOfDayOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    timeOfDayOptionUrl.value = `/project/${projectParam}/timeOfDayOption`
    await executeApi(timeOfDayOptionApi.put(data), true)
    return timeOfDayOption
  }

  const updateTimeOfDayOption = async (
    data: Partial<TimeOfDayOption>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<TimeOfDayOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || timeOfDayOption.data?.id
    timeOfDayOptionUrl.value = `/project/${projectParam}/timeOfDayOption/${idParam}`
    await executeApi(timeOfDayOptionApi.post(data), true)
    return timeOfDayOption
  }

  const deleteTimeOfDayOption = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<TimeOfDayOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || timeOfDayOption.data?.id
    timeOfDayOptionUrl.value = `/project/${projectParam}/timeOfDayOption/${idParam}`
    await executeApi(timeOfDayOptionApi.delete(), false)
    timeOfDayOption.data = null
    return timeOfDayOption
  }

  const $reset = () => {
    resetFetchResult(timeOfDayOptions)
    resetFetchResult(timeOfDayOption)
  }

  return {
    listTimeOfDayOptions,
    readTimeOfDayOption,
    createTimeOfDayOption,
    updateTimeOfDayOption,
    deleteTimeOfDayOption,
    timeOfDayOptions,
    timeOfDayOption,
    $reset
  }
})

export default useTimeOfDayOptionStore
