import type { RouteRecordRaw } from 'vue-router'
import CrmIntegrationView from '@/views/project/integrations/CrmIntegrationView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const crmIntegrationRoute: RouteRecordRaw = {
  path: '',
  name: 'crmIntegration',
  component: CrmIntegrationView,
  meta: {
    breadcrumb: 'crmIntegration',
    menu: {
      key: 'crmIntegration',
      icon: 'mdi-book-open',
      name: 'crmIntegration',
      exact: true
    },
    scopes: [KeycloakScope.PROJECT_UPDATE, KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  }
}

export default crmIntegrationRoute
