import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type ProjectVersion from '@/types/projectVersion'
import executeApi from '@/utils/executeApi'

const useProjectVersionStore = defineStore('projectVersion', () => {
  const api = useApi()

  const projectVersionsUrl = ref('/project')
  const projectVersionsApi = api<Array<ProjectVersion>>(projectVersionsUrl).get().json()
  const projectVersions: FetchResult<Array<ProjectVersion>> =
    reactiveFetchResult(projectVersionsApi)
  const listProjectVersions = async (
    projectId?: number
  ): Promise<FetchResult<Array<ProjectVersion>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    projectVersionsUrl.value = `/project/${projectIdParam}/version`
    await executeApi(projectVersionsApi)
    return projectVersions
  }

  const $reset = () => resetFetchResult(projectVersions)

  return {
    listProjectVersions,
    projectVersions,
    $reset
  }
})

export default useProjectVersionStore
