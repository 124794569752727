import type { RouteRecordRaw } from 'vue-router'
import UserListView from '@/views/project/users/UserListView.vue'
import createUserRoute from './createUser'
import updateUserRoute from './updateUser'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const usersRoute: RouteRecordRaw = {
  path: '/users',
  name: 'users',
  component: UserListView,
  meta: {
    breadcrumb: 'users',
    menu: {
      key: 'users',
      icon: 'mdi-account-supervisor',
      name: 'users'
    },
    scopes: [KeycloakScope.USER_LIST]
  },
  children: [createUserRoute, updateUserRoute]
}

export default usersRoute
