import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Building from '@/types/building'
import executeApi from '@/utils/executeApi'

const useBuildingStore = defineStore('building', () => {
  const api = useApi()

  const buildingsUrl = ref('/project')
  const buildingsApi = api<Array<Building>>(buildingsUrl).json()
  const buildings: FetchResult<Array<Building>> = reactiveFetchResult(buildingsApi)
  const listBuildings = async (projectId?: number): Promise<FetchResult<Array<Building>>> => {
    if (buildingApi.isFetching.value) {
      return buildings
    }
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    buildingsUrl.value = `/project/${projectIdParam}/building`
    await executeApi(buildingsApi.get())
    return buildings
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<Building>>(patchUrl).json()
  const patch: FetchResult<Array<Building>> = reactiveFetchResult(patchApi)
  const patchBuildings = async (
    data: Array<Partial<Building>>,
    projectId?: number,
    commit: boolean = true
  ): Promise<Array<Building>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/building${commit === false ? '?commit=false' : ''}`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch.data!
  }

  const buildingUrl = ref('/project')
  const buildingApi = api<Array<Building>>(buildingUrl).json()
  const building: FetchResult<Building> = reactiveFetchResult(buildingApi)

  const readBuilding = async (id?: number, projectId?: number): Promise<FetchResult<Building>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || building.data?.id
    buildingUrl.value = `/project/${projectIdParam}/building/${idParam}`
    await executeApi(buildingApi.get())
    return building
  }

  const createBuilding = async (
    data: Partial<Building>,
    projectId?: number
  ): Promise<FetchResult<Building>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    buildingUrl.value = `/project/${projectIdParam}/building`
    await executeApi(buildingApi.post(data), true)
    return building
  }

  const updateBuilding = async (
    data: Partial<Building>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Building>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || building.data?.id
    buildingUrl.value = `/project/${projectIdParam}/building/${idParam}`
    await executeApi(buildingApi.put(data), true)
    return building
  }

  const deleteBuilding = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Building>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || building.data?.id
    buildingUrl.value = `/project/${projectIdParam}/building/${idParam}`
    await executeApi(buildingApi.delete(), false)
    building.data = null
    return building
  }

  const $reset = () => {
    resetFetchResult(buildings)
    resetFetchResult(building)
  }

  return {
    listBuildings,
    readBuilding,
    createBuilding,
    updateBuilding,
    deleteBuilding,
    patchBuildings,
    buildings,
    building,
    $reset
  }
})

export default useBuildingStore
