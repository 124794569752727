<template>
  <vis-loader :loading="contact.isFetching || images.isFetching || image.isFetching">
    <vis-sheet>
      <contact-form
        :project="project.data!"
        :images="images.data!.filter((i) => i.category === ProjectImagesCategory.Contacts)"
        :contact="contact.data!"
        @submit="onSubmit"
      />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useContactStore from '@/stores/contact'
import ContactForm from '@/components/forms/ContactForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import type Contact from '@/types/contact'
import { ProjectImagesCategory } from '@/types/image'
import useImageStore from '@/stores/image'
import useProjectStore from '@/stores/project'

const { contact, listContacts, readContact, updateContact } = useContactStore()
const { project } = useProjectStore()
const { images, image, listImages, createImage } = useImageStore()
images.data || listImages()

const onSubmit = useSubmitThenRoute(
  async (payload: Partial<Contact>, file?: File) => {
    if (file) {
      const formData = new FormData()
      formData.append(ProjectImagesCategory.Contacts, file)
      const image = await createImage(formData)
      payload.projectImageId = image[0].id
    }
    return updateContact(payload)
  },
  () => ({ name: 'contacts' }),
  () => {
    listContacts()
    listImages()
  }
)

const contactId = useRouteId('contactId')
readContact(contactId)
</script>
