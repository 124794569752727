<template>
  <vis-data-table :headers="columns" :items="projectStyles" @click:row="onRowClick">
    <template #top>
      <vis-sheet v-if="hasAddEventListener" class="text-end">
        <v-tooltip location="left">
          <template #activator="{ props }">
            <vis-add-icon-btn v-bind="props" @click="emit('add')" />
          </template>
          {{ $t('create') }}
        </v-tooltip>
      </vis-sheet>
    </template>
    <template #bottom />
    <template #body="{ items }">
      <template v-if="projectStyles.length > 0">
        <draggable :list="[...items]" item-key="id" tag="tbody">
          <template #item="{ element: projectStyle }: { element: ProjectStyle }">
            <tr
              class="v-data-table__tr v-data-table__tr--clickable"
              @click="onRowClick(projectStyle)"
            >
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="number"
                  :project="project"
                  :value="projectStyle.id"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                {{ projectStyle.title }}
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="dateTime"
                  :project="project"
                  :value="dateTimeToHumanReadable(projectStyle.createdAt)"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="dateTime"
                  :project="project"
                  :value="dateTimeToHumanReadable(projectStyle.updatedAt)"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip v-if="hasPatchEventListener" location="left">
                  <template #activator="{ props }">
                    <v-btn
                      size="x-small"
                      class="ml-2"
                      @click.stop="onMakeDefault(projectStyle)"
                      v-bind="props"
                    >
                      Default
                      <data-table-cell-renderer
                        type="boolean"
                        :project="project"
                        :value="projectStyle.isDefault"
                      />
                    </v-btn>
                  </template>
                  {{ $t('makeDefault') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasDeleteEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(projectStyle)" />
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
                &nbsp;
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div>
      </template>
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted } from 'vue'
import Draggable from 'vuedraggable'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import type ProjectStyle from '@/types/projectStyle'
import type Project from '@/types/project'
import { useI18n } from 'vue-i18n'

const { projectStyles } = defineProps<{
  project: Project
  projectStyles: Array<ProjectStyle>
}>()

const emit = defineEmits<{
  add: []
  delete: [projectStyle: ProjectStyle]
  select: [projectStyle: ProjectStyle]
  patch: [patched: Array<Partial<ProjectStyle>>]
}>()

defineSlots<{
  top(props: {}): any
}>()

const hasAddEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onAdd)
const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)
const hasPatchEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onPatch)

const { t } = useI18n()

const columns = [
  { title: t('id'), key: 'id', sortable: false },
  { title: t('title'), key: 'title', sortable: false },
  { title: t('createdAt'), key: 'createdAt', sortable: false },
  { title: t('updatedAt'), key: 'updatedAt', sortable: false },
  { title: '', key: 'actions', sortable: false }
]

const dateTimeToHumanReadable = (dateTime: string) => {
  const d = new Date(dateTime)
  return d.toLocaleString()
}

function onRowDelete(projectStyle: ProjectStyle) {
  emit('delete', projectStyle)
}

function onRowClick(projectStyle: ProjectStyle) {
  emit('select', projectStyle)
}

function onMakeDefault(projectStyle: ProjectStyle) {
  const copy = [...projectStyles]
  copy.forEach((c) => {
    c.isDefault = c.id === projectStyle.id
  })
  emit('patch', copy)
}

onMounted(() => {
  if (projectStyles.length > 0) {
    const sortableTbody = document.querySelector('.v-table__wrapper > table > tbody > tbody')
    const tbody = document.querySelector('.v-table__wrapper > table > tbody')
    tbody!.parentNode!.append(sortableTbody!)
    tbody!.remove()
  }
})
</script>
