<template>
  <vis-loader :loading="!authStore.authenticated">
    <router-view />
  </vis-loader>
  <span class="app-version">v - {{ appVersion }}</span>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Client } from '@stomp/stompjs'
import useKeycloakProvider from './composables/useKeycloakProvider'
import useAuthStore from './stores/auth'
import useProjectStore from './stores/project'
import useAppStore from './stores/app'
import { QUEUE_SESSION_ID } from './configs/api'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const { addMessage, appVersion } = useAppStore()
const { project } = useProjectStore()
const keycloakProvider = useKeycloakProvider()
const authStore = useAuthStore()

keycloakProvider.init(() => authStore.setFromProvider())

const client = ref<Client | null>()

watch(
  () => project.data?.id,
  (projectId: number | undefined) => {
    if (projectId) {
      connectToRabbitMq(projectId)
    } else {
      if (client.value) {
        client.value.deactivate({ force: true })
        client.value = null
      }
    }
  },
  {
    immediate: true
  }
)

function connectToRabbitMq(project: number) {
  if (client.value) {
    client.value.deactivate({ force: true })
  }
  client.value = new Client({
    brokerURL: import.meta.env.VITE_RMQ_STOMP_URL,
    connectHeaders: {
      login: import.meta.env.VITE_RMQ_USER,
      passcode: import.meta.env.VITE_RMQ_PASS,
      host: import.meta.env.VITE_RMQ_VHOST
    },
    onConnect: () => {
      client.value!.subscribe(`/exchange/ex.api.public/api.v1.project.${project}`, (message) => {
        if (isMsgFromSomeoneElse(message)) {
          const { action } = JSON.parse(message.body)
          if (action === 'updated') {
            addMessage({
              message: t('projectUpdated'),
              color: 'warning'
            })
          } else if (action === 'deleted') {
            addMessage({
              message: t('projectDeleted'),
              color: 'error'
            })
          }
        }
      })
      client.value!.subscribe(
        `/exchange/ex.api.public/api.v1.project.${project}.building.*`,
        (message) => {
          if (isMsgFromSomeoneElse(message)) {
            const { action, building } = JSON.parse(message.body)
            if (action === 'updated') {
              addMessage({
                message: t('buildingUpdated'),
                color: 'warning',
                action: `/buildings/${building}`
              })
            } else if (action === 'deleted') {
              addMessage({
                message: t('buildingDeleted'),
                color: 'error'
              })
            }
          }
        }
      )
      client.value!.subscribe(
        `/exchange/ex.api.public/api.v1.project.${project}.building.*.unit.*`,
        (message) => {
          if (isMsgFromSomeoneElse(message)) {
            const { action, unit } = JSON.parse(message.body)
            if (action === 'updated') {
              addMessage({
                message: t('unitUpdated'),
                color: 'warning',
                action: `/units/${unit}`
              })
            } else if (action === 'deleted') {
              addMessage({
                message: t('unitDeleted'),
                color: 'error'
              })
            }
          }
        }
      )
      client.value!.subscribe(
        `/exchange/ex.api.public/api.v1.project.${project}.cache`,
        (message) => {
          const { action } = JSON.parse(message.body)
          if (action === 'started') {
            addMessage({
              message: t('cachingProject'),
              color: 'warning'
            })
          } else if (action === 'cached') {
            addMessage({
              message: t('cachingCompleted'),
              color: 'success'
            })
          }
        }
      )
      client.value!.subscribe(`/exchange/ex.api.public/api.v1.cache`, (message) => {
        const { action } = JSON.parse(message.body)
        if (action === 'started') {
          addMessage({
            message: t('cachingProjects'),
            color: 'warning'
          })
        } else if (action === 'finished') {
          addMessage({
            message: t('cachingCompleted'),
            color: 'success'
          })
        }
      })
    }
  })

  // if (import.meta.env.VITE_API_URL.includes('127.0.0.1')) {
  //   client.value.debug = (msg: string) => {
  //     console.log(msg)
  //   }
  // }

  client.value.activate()
}

function isMsgFromSomeoneElse(msg: any) {
  return (
    'x-queue-session-token' in msg.headers &&
    msg.headers['x-queue-session-token'] !== QUEUE_SESSION_ID
  )
}
</script>

<style>
.vertical-align-middle {
  vertical-align: middle;
}
.v-skeleton-loader {
  background: transparent !important;
}
.v-data-table__td:last-child {
  text-align: end !important;
  white-space: nowrap !important;
}
.v-data-table__th {
  font-weight: bold !important;
}
thead tr th {
  font-weight: bold !important;
}
h4 {
  margin-bottom: 16px;
}

.app-version {
  position: fixed;
  bottom: 2px;
  right: 2px;
  font-size: 8px;
  color: #666666;
  display: inline-block;
  background: #ededed;
}
</style>
