import type { RouteRecordRaw } from 'vue-router'
import FieldUpdateView from '@/views/project/settings/fields/FieldUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateFieldRoute: RouteRecordRaw = {
  path: ':fieldId',
  name: 'updateField',
  component: FieldUpdateView,
  meta: {
    breadcrumb: 'updateField',
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default updateFieldRoute
