<template>
  <vis-data-table :headers="columns" :items="interiorOptions" @click:row="onRowClick">
    <template #top>
      <vis-sheet v-if="hasAddEventListener" class="text-end">
        <v-tooltip location="left">
          <template #activator="{ props }">
            <vis-add-icon-btn v-bind="props" @click="emit('add')" />
          </template>
          {{ $t('create') }}
        </v-tooltip>
      </vis-sheet>
    </template>
    <template #bottom />
    <template #body="{ items }">
      <template v-if="interiorOptions.length > 0">
        <draggable
          :list="[...items].sort((a, b) => a.order - b.order)"
          item-key="id"
          tag="tbody"
          @change="onReorder"
        >
          <template #item="{ element: interiorOption }: { element: InteriorOption }">
            <tr
              class="v-data-table__tr v-data-table__tr--clickable"
              @click="onRowClick(interiorOption)"
            >
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="number"
                  :project="project"
                  :value="interiorOption.id"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="id"
                  :project="project"
                  :value="interiorOption.guid"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="boolean"
                  :project="project"
                  :value="interiorOption.isDefault"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="string"
                  :project="project"
                  :value="interiorOption.title"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="string"
                  :project="project"
                  :value="interiorOption.description"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="date"
                  :project="project"
                  :value="interiorOption.createdAt"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="date"
                  :project="project"
                  :value="interiorOption.updatedAt"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip v-if="hasPatchEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-view-icon-btn
                      :disabled="interiorOption.isDefault"
                      v-bind="props"
                      @click.stop="onMakeDefault(interiorOption)"
                    />
                  </template>
                  {{ $t('makeDefault') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasDeleteEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(interiorOption)" />
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasPatchEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-drag-icon-btn v-bind="props" />
                  </template>
                  {{ $t('dragToReorder') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div></template
      >
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable'
import { computed, getCurrentInstance, onMounted } from 'vue'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import type InteriorOption from '@/types/interiorOption'
import type Project from '@/types/project'
import { useI18n } from 'vue-i18n'

const { interiorOptions } = defineProps<{
  project: Project
  interiorOptions: Array<InteriorOption>
}>()

const emit = defineEmits<{
  add: []
  delete: [interiorOption: InteriorOption]
  select: [interiorOption: InteriorOption]
  patch: [patched: Array<Partial<InteriorOption>>]
}>()

defineSlots<{
  top(props: {}): any
}>()

const hasAddEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onAdd)
const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)
const hasPatchEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onPatch)

const { t } = useI18n()

const columns = [
  { title: t('id'), key: 'id', sortable: false },
  { title: t('guid'), key: 'guid', sortable: false },
  { title: t('isDefault'), key: 'isDefault', sortable: false },
  { title: t('title'), key: 'title', sortable: false },
  { title: t('description'), key: 'description', sortable: false },
  { title: t('createdAt'), key: 'createdAt', sortable: false },
  { title: t('updatedAt'), key: 'updatedAt', sortable: false },
  { title: '', key: 'actions', sortable: false }
]

function onRowDelete(interiorOption: InteriorOption) {
  emit('delete', interiorOption)
}

function onRowClick(interiorOption: InteriorOption) {
  emit('select', interiorOption)
}

function onReorder(event: Moved<InteriorOption>) {
  const copy = [...interiorOptions].sort((a, b) => a.order - b.order)
  const left = copy[event.moved.oldIndex]
  const right = copy[event.moved.newIndex]
  copy[event.moved.oldIndex] = right
  copy[event.moved.newIndex] = left
  copy.forEach((c, i) => {
    c.order = i
  })
  emit('patch', copy)
}

function onMakeDefault(interiorOption: InteriorOption) {
  const copy = [...interiorOptions]
  copy.forEach((c) => {
    c.isDefault = c.guid === interiorOption.guid
  })
  emit('patch', copy)
}

onMounted(() => {
  if (interiorOptions.length > 0) {
    const sortableTbody = document.querySelector('.v-table__wrapper > table > tbody > tbody')
    const tbody = document.querySelector('.v-table__wrapper > table > tbody')
    tbody!.parentNode!.append(sortableTbody!)
    tbody!.remove()
  }
})
</script>
