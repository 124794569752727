<template>
  <template v-if="route.name === 'contacts'">
    <vis-loader :loading="contacts.isFetching || patch.isFetching">
      <contacts-table
        :project="project.data!"
        :contacts="contacts.data!"
        v-on="{
          add: check(KeycloakScope.PROJECT_UPDATE) ? onAdd : undefined,
          select: check(KeycloakScope.PROJECT_UPDATE) ? onSelect : undefined,
          delete: check(KeycloakScope.PROJECT_UPDATE) ? onDelete : undefined,
          reorder: check(KeycloakScope.PROJECT_UPDATE) ? onReorder : undefined
        }"
      />
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useContactStore from '@/stores/contact'
import ContactsTable from '@/components/tables/ContactsTable.vue'
import useProjectStore from '@/stores/project'
import type Contact from '@/types/contact'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { contacts, patch, listContacts, deleteContact, patchContacts } = useContactStore()
contacts.data || listContacts()

const onAdd = () => router.push({ name: 'createContact' })

const onSelect = (contact: Contact) =>
  router.push({
    name: 'updateContact',
    params: {
      contactId: contact.id
    }
  })

const onDelete = async (contact: Contact) => {
  if (confirm(t('areYouSureYouWantToDeleteTheContact', [contact.id]))) {
    await deleteContact(contact.id)
    listContacts()
  }
}

const onReorder = async (reordered: Array<Partial<Contact>>) => {
  await patchContacts(reordered)
  listContacts()
}
</script>
