import type { RouteRecordRaw } from 'vue-router'
import ClientListView from '@/views/client/ClientListView.vue'
import clientCreateRoute from './clientCreate'
import clientUpdateRoute from './clientUpdate'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const clientIndexRoute: RouteRecordRaw = {
  path: '/clients',
  name: 'clients',
  component: ClientListView,
  meta: {
    scopes: [KeycloakScope.CLIENT_LIST]
  },
  children: [clientCreateRoute, clientUpdateRoute]
}

export default clientIndexRoute
