import type { RouteRecordRaw } from 'vue-router'
import ProjectStyleUpdateView from '@/views/project/renderSettings/projectStylesDesigner/ProjectStyleUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateProjectStyleRoute: RouteRecordRaw = {
  path: ':projectStyleId',
  name: 'updateProjectStyle',
  component: ProjectStyleUpdateView,
  meta: {
    breadcrumb: 'updateProjectStyle',
    scopes: [KeycloakScope.ASSETS_UPDATE]
  }
}

export default updateProjectStyleRoute
