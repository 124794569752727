import { watch } from 'vue'
import { createI18n, type I18nOptions } from 'vue-i18n'

const availableLocales = (window as any).APP_LANGS as Array<string>
const i18n = createI18n({
  legacy: false,
  availableLocales,
  messages: availableLocales.reduce(
    (acc: any, lang: any) => ({ ...acc, ...{ [lang]: {} } }),
    {}
  ) as I18nOptions['messages'],
  locale: localStorage.getItem('LOCALE') || 'en',
  fallbackLocale: 'en'
})

let messages: { [key: string]: { [key: string]: string } }
let vuetifyMessages: { [key: symbol]: { [key: string]: string } }

watch(
  i18n.global.locale,
  async () => {
    const locale = i18n.global.locale.value
    localStorage.setItem('LOCALE', locale)
    if (!messages) {
      const response = await fetch(`https://l10n.visuado.com/all.json`)
      messages = await response.json()
    }
    if (!vuetifyMessages) {
      vuetifyMessages = await import('vuetify/locale')
    }
    i18n.global.setLocaleMessage(locale, {
      ...messages[locale],
      $vuetify: {
        ...(locale in vuetifyMessages
          ? (vuetifyMessages as any)[locale]
          : vuetifyMessages['en' as any])
      }
    })
  },
  {
    immediate: true
  }
)

export default i18n
export { messages }
