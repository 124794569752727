import '@qispace/vue3-player/style.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './configs/router'
import pinia from './configs/pinia'
import vuetify from './configs/vuetify'
import i18n from './configs/i18n'
import api from './configs/api'
import keycloak from './configs/keycloak'
import { sentry } from './configs/sentry'
import JsonViewer from 'vue3-json-viewer'
import 'vue3-json-viewer/dist/index.css'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js'
import quill from './configs/quill'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Colors
)

const app = createApp(App)

sentry(app, router)
app.use(pinia)
app.use(router)
app.use(vuetify)
app.use(i18n)
app.use(keycloak)
app.use(api)
app.use(JsonViewer)
quill(app)

app.mount('#app')
