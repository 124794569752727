<template>
  <div class="localized-container">
    <slot
      :locale="code"
      :input="input"
      :localized="input && input[code] ? input[code] : undefined"
    ></slot>
    <v-menu offset-y origin="top right">
      <template #activator="{ props }">
        <div class="button-container">
          <v-btn icon flat v-bind="props">
            <v-icon>mdi-web</v-icon>
          </v-btn>
          <strong class="primary--text">{{ code }}</strong>
        </div>
      </template>
      <v-list dense>
        <v-list-item
          v-for="(locale, index) in locales"
          :key="locale"
          :disabled="localeIndex === index"
          @click="localeIndex = index"
          :value="localeIndex"
        >
          <v-list-item-title>
            <v-tooltip left :disabled="!input || !input[locale]">
              <template #activator="{ props }">
                <div v-bind="props">
                  <div
                    :class="{
                      'icon-container': true,
                      empty: !input || !input[locale]
                    }"
                  >
                    <v-icon>mdi-web</v-icon>
                    <strong :class="`${!input || !input[locale] ? 'error' : 'primary'}--text`">{{
                      locale
                    }}</strong>
                  </div>
                  &nbsp;
                  {{ $t(`lang${locale.toUpperCase()}`) }}
                </div>
              </template>
              <span>{{ input[locale] }}</span>
            </v-tooltip>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const { input } = defineProps<{
  input: Translatable
}>()

defineSlots<{
  default(props: { input: Translatable; locale: string; localized?: string }): any
}>()

const i18n = useI18n()
const locales = i18n.availableLocales as Array<string>
const localeIndex = ref(locales.findIndex((l) => l === i18n.locale.value))
const code = computed(() => locales[localeIndex.value])
</script>

<style scoped>
.localized-container {
  position: relative;
}
.button-container {
  position: absolute;
  top: 3px;
  right: 6px;
}
.icon-container {
  position: relative;
  display: inline-block;
}
.icon-container.empty {
  opacity: 0.5;
}
strong {
  user-select: none;
  pointer-events: none;
  text-transform: uppercase;
  transform: translateY(-50%) translateX(-50%);
  text-shadow:
    -1px -1px 0 #fff,
    1px -1px 0 #fff,
    -1px 1px 0 #fff,
    1px 1px 0 #fff;
  font-size: 75%;
  position: absolute;
  left: 50%;
  top: 50%;
}
</style>
