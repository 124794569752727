<template>
  <vis-loader :loading="projectStyle.isFetching">
    <ProjectStylesDesignerForm
      :projectStyle="projectStyle.data!"
      :artistResources="artistResources.data!"
      @submit="onSubmit"
    />
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useProjectStyleStore from '@/stores/projectStyle'
import ProjectStylesDesignerForm from '@/components/forms/ProjectStylesDesignerForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useArtistResourceStore from '@/stores/artistResources'

const { projectStyle, listProjectStyles, readProjectStyle, updateProjectStyle } =
  useProjectStyleStore()

const { artistResources, listArtistResources } = useArtistResourceStore()
artistResources.data || listArtistResources()

const onSubmit = useSubmitThenRoute(updateProjectStyle, { name: 'projectStyleDesigner' }, () =>
  listProjectStyles()
)

const projectStyleId = useRouteId('projectStyleId')
readProjectStyle(projectStyleId)
</script>
