<template>
  <v-main>
    <v-container fluid class="h-100 overflow-auto">
      <h4>
        {{ $t('manageAssets') }}
        <vis-icon-btn icon="mdi-arrow-left" :to="{ name: 'selectProject' }" class="float-right" />
      </h4>
      <vis-loader :loading="projects.isFetching || assets.isFetching">
        <vis-sheet>
          <v-row>
            <v-col cols="3">
              <vis-select
                v-model="filter.project"
                :items="projects.data!"
                item-value="slug"
                item-title="name"
                :label="t('projectFilter')"
                :placeholder="t('projectFilter')"
                density="compact"
                clearable
                @click:clear="filter.project = undefined"
              />
            </v-col>
            <v-col cols="3">
              <vis-select
                v-model="filter.asset"
                :items="assetTypes"
                :label="t('assetTypeFilter')"
                :placeholder="t('assetTypeFilter')"
                density="compact"
                clearable
                @click:clear="filter.asset = undefined"
              />
            </v-col>
            <v-col cols="6">
              <vis-text-field
                v-model="filter.text"
                :label="t('fieldsFilter')"
                :placeholder="t('fieldsFilter')"
                density="compact"
                clearable
                @click:clear="filter.text = undefined"
              />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col class="pb-0 pt-1" cols="12">
              <p>
                {{ t('showing') }} {{ filteredItems.length }}/{{ assets.data?.length
                }}<template v-if="selectedItems.length > 0"
                  >, {{ t('selected') }} {{ selectedItems.length }}
                  <template v-if="invisibleSelected.length > 0"
                    >({{ t('ofWhichInvisible') }} {{ invisibleSelected.length }})</template
                  ></template
                >
              </p>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="12">
              <v-data-table-virtual
                :headers="headers"
                :items="filteredItems"
                fixed-header
                hide-default-footer
              >
                <template #header.select>
                  <vis-checkbox
                    :model-value="isAllSelected"
                    @update:model-value="() => toggleSelectAll()"
                    :false-value="false"
                    :true-value="true"
                  />
                </template>
                <template #item.updatedAt="{ value }">
                  {{ renderData(value) }}
                </template>
                <template #item.select="{ item: i }">
                  <vis-checkbox
                    :model-value="selectedItems?.includes(i.id)"
                    @update:model-value="() => toggleSelect(i.id)"
                    :false-value="false"
                    :true-value="true"
                    class="mr-6"
                    hide-details
                  />
                </template>
                <template
                  v-for="field of editableMetadataFields"
                  :key="field.key"
                  #[getFieldKey(field)]="item: any"
                >
                  <div class="cell">
                    <template v-if="item.value !== false && !item.value">
                      <vis-btn
                        class="edit-btn mr-2"
                        size="x-small"
                        icon="mdi-pencil"
                        @click="onCellClick(getMetadataFromItem(item), item.value, item.item.id)"
                      />
                    </template>
                    <template v-else>
                      <a
                        href="javascript:void"
                        @click="onCellClick(getMetadataFromItem(item), item.value, item.item.id)"
                      >
                        <template v-if="getMetadataFromItem(item).type === 'string'">
                          {{ item.value }}
                        </template>
                        <template v-else-if="getMetadataFromItem(item).type === 'number'">
                          {{ item.value }}
                        </template>
                        <template v-else-if="getMetadataFromItem(item).type === 'boolean'">
                          {{ item.value }}
                        </template>
                        <template v-else-if="getMetadataFromItem(item).type === 'array'">
                          {{ item.value ? item.value.join(', ') : '' }}
                        </template>
                      </a>
                    </template>
                  </div>
                </template>
              </v-data-table-virtual>
            </v-col>
          </v-row>
        </vis-sheet>
      </vis-loader>
    </v-container>
    <v-dialog v-model="editContext.modal">
      <v-card class="pa-4">
        <vis-form @submit.prevent="onSubmit()">
          <v-alert v-if="selectedItems.length > 1" class="mb-4" color="info"
            >{{ t('editingInstances') }}: {{ selectedItems.length }}</v-alert
          >
          <template v-if="editContext.type === 'string'">
            <vis-text-field
              v-model="editContext.value"
              :placeholder="
                t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)
              "
              :label="t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)"
            />
          </template>
          <template v-else-if="editContext.type === 'number'">
            <vis-text-field
              :model-value="editContext.value"
              @update:model-value="editContext.value = Number($event)"
              :placeholder="
                t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)
              "
              :label="t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)"
            />
          </template>
          <template v-else-if="editContext.type === 'boolean'">
            <vis-checkbox
              v-model="editContext.value"
              false-value="False"
              true-value="True"
              :placeholder="
                t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)
              "
              :label="t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)"
            />
          </template>
          <template v-else-if="editContext.type === 'array'">
            <vis-text-field
              :model-value="editContext.value?.join(', ')"
              @update:model-value="editContext.value = $event.split(', ')"
              :placeholder="
                t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)
              "
              :label="t(editableMetadataFields.find(({ key }) => key === editContext.field)?.key!)"
            />
          </template>
          <vis-btn class="mt-4" type="submit">{{ t('update') }}</vis-btn>
        </vis-form>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import useAssetStore from '@/stores/asset'
import { useI18n } from 'vue-i18n'
import useProjectStore from '@/stores/project'
import type Asset from '@/types/asset'

type MetadataField = {
  key: string
  type: 'string' | 'number' | 'boolean' | 'array'
}

const i18n = useI18n()
const { t } = i18n

const projectStore = useProjectStore()
const { listProjects, projects } = projectStore
projects.data || listProjects()

const assetStore = useAssetStore()
const { listAssets, patchAssets, assets } = assetStore
assets.data || listAssets()

const editContext = reactive<{
  modal: boolean
  value?: any
  field?: string
  type?: string
}>({
  modal: false,
  value: undefined,
  field: undefined,
  type: undefined
})
const filter = reactive<{
  project?: string
  asset?: string
  text?: string
}>({
  project: undefined,
  asset: undefined,
  text: undefined
})
const selectedItems = ref<Array<number>>([])

const editableMetadataFields: Array<MetadataField> = [
  // { key: 'depth', type: 'number' },
  { key: 'title', type: 'string' },
  { key: 'description', type: 'string' },
  { key: 'manufacturer', type: 'string' },
  // { key: 'width', type: 'number' },
  // { key: 'height', type: 'number' },
  { key: 'series', type: 'string' },
  // { key: 'doorType', type: 'string' },
  // { key: 'archetype', type: 'string' },
  { key: 'categories', type: 'array' },
  { key: 'isEntrance', type: 'boolean' },
  { key: 'anchorpoint', type: 'string' },
  // { key: 'disableTime', type: 'string' },
  { key: 'possibleRooms', type: 'array' }
]

const headers = [
  { key: 'select', title: '', sortable: false },
  // { key: 'id', title: t('id') },
  { key: 'publicId', title: t('id') },
  { key: 'projectId', title: t('project') },
  // { key: 'resourceType', title: t('assetType') },
  { key: 'version', title: t('version') },
  { key: 'updatedAt', title: t('updatedAt') },
  ...editableMetadataFields.map((field) => ({
    metadataField: field,
    key: `metadata.${field.key}`,
    title: t(field.key)
  }))
]

const assetTypes = [
  {
    title: t('asset'),
    value: 'asset'
  },
  {
    title: t('material'),
    value: 'material'
  },
  {
    title: t('assetGroup'),
    value: 'asset-group'
  },
  {
    title: t('lightbulb'),
    value: 'lightbulb'
  }
]

watch(
  () => editContext.modal,
  () => {
    if (editContext.modal === false) {
      if (selectedItems.value.length === 1) {
        selectedItems.value = []
      }
    }
  }
)

const filteredItems = computed<Array<Asset>>(() => {
  if (assets.data) {
    return assets.data
      .filter((a) => (filter.project ? a.projectId === filter.project : true))
      .filter((a) => (filter.asset ? a.resourceType === filter.asset : true))
      .filter((a) =>
        filter.text
          ? editableMetadataFields
              .map(({ key }) => a.metadata[key])
              .some((f) => !!f && f.toString().toLowerCase().includes(filter.text))
          : true
      )
  } else {
    return []
  }
})

const selectedFilteredItems = computed<Array<Asset>>(() => {
  return filteredItems.value.filter(({ id }) => selectedItems.value.includes(id))
})

const isAllSelected = computed(() => {
  return (
    filteredItems.value.filter(({ id }) =>
      selectedFilteredItems.value.map(({ id }) => id).includes(id)
    ).length === filteredItems.value.length
  )
})

const invisibleSelected = computed(() => {
  return selectedItems.value.filter(
    (id) => !selectedFilteredItems.value.map(({ id }) => id).includes(id)
  )
})

function getMetadataFromItem(item: any): MetadataField {
  return item.column.metadataField
}

function getFieldKey(field: MetadataField): any {
  return `item.metadata.${field.key}`
}

function isSelected(id: number) {
  return !!selectedItems.value.includes(id)
}

function toggleSelect(id: number) {
  isSelected(id)
    ? (selectedItems.value = selectedItems.value?.filter((i) => i !== id))
    : (selectedItems.value = [...selectedItems.value!, id])
}

function toggleSelectAll() {
  isAllSelected.value
    ? (selectedItems.value = [])
    : (selectedItems.value = filteredItems.value!.map((i) => i.id))
}

function renderData(date: string) {
  return new Date(date).toLocaleString()
}

function onCellClick(
  {
    key,
    type
  }: {
    key: string
    type: 'string' | 'number' | 'boolean' | 'array'
  },
  value: any,
  id: number
) {
  if (!selectedItems.value.includes(id)) {
    selectedItems.value.push(id)
  }
  editContext.field = key
  editContext.value = value
  editContext.type = type
  editContext.modal = true
}

async function onSubmit() {
  const data = [...assets.data!.filter((a) => selectedItems.value.includes(a.id))]
  for (const row of data) {
    ;(row as any).metadata[editContext.field!] = editContext.value
  }
  await patchAssets(data)
  await listAssets()
  editContext.field = undefined
  editContext.value = undefined
  editContext.type = undefined
  editContext.modal = false
}
</script>

<style scoped>
:deep(.v-table__wrapper) {
  height: calc(100vh - 252px) !important;
  width: calc(100vw - 64px) !important;
}

:deep(.v-data-table__td .cell) {
  white-space: nowrap;
}

:deep(.v-data-table__td .cell .edit-btn) {
  display: none;
}

:deep(.v-data-table__td:hover .cell .edit-btn) {
  display: inline-block;
}

:deep(.v-data-table__td .cell a) {
  color: rgba(117, 117, 117, 0.87);
  text-decoration: none;
}

:deep(.v-data-table__td:hover .cell a) {
  color: rgba(117, 117, 117, 1);
  text-decoration: underline;
}

:deep(.v-data-table__td:last) {
  text-align: left;
}
</style>
