import type { RouteRecordRaw } from 'vue-router'
// @ts-ignore
import floorplansImportView from '@/views/project/tools/floorplansImportView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const floorplansImport: RouteRecordRaw = {
  path: 'floorplansImport',
  name: 'floorplansImport',
  component: floorplansImportView,
  meta: {
    breadcrumb: 'floorplansImport',
    menu: {
      key: 'floorplansImport',
      icon: 'mdi-floor-plan',
      name: 'floorplansImport'
    },
    scopes: [KeycloakScope.UNIT_LIST, KeycloakScope.UNIT_UPDATE]
  }
}

export default floorplansImport
