import type { RouteRecordRaw } from 'vue-router'
import InteriorOptionCreateView from '@/views/project/renderSettings/interiorOptions/InteriorOptionCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createInteriorOptionRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createInteriorOption',
  component: InteriorOptionCreateView,
  meta: {
    breadcrumb: 'createInteriorOption',
    scopes: [KeycloakScope.ASSETS_CREATE]
  }
}

export default createInteriorOptionRoute
