<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <vis-localized-input :input="status.label!" #default="{ input, locale }">
          <vis-text-field
            name="label"
            :placeholder="$t('label')"
            :label="$t('label')"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <vis-switch name="isSold" :label="$t('isSold')" v-model="status.isSold" />
      </v-col>
      <v-col cols="6">
        <vis-switch
          name="persistentBuildingColor"
          :label="$t('persistentBuildingColor')"
          v-model="status.persistentBuildingColor"
        />
      </v-col>
      <v-col cols="6">
        <vis-switch name="hidePage" :label="$t('hidePage')" v-model="status.hidePage" />
      </v-col>
      <v-col cols="6">
        <vis-switch
          name="persistentUnitColor"
          :label="$t('persistentUnitColor')"
          v-model="status.persistentUnitColor"
        />
      </v-col>
      <v-col cols="12">
        <vis-switch
          name="isFilteredByDefault"
          :label="$t('isFilteredByDefault')"
          v-model="status.isFilteredByDefault"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <div>{{ $t('textColor') }}</div>
        <vis-btn size="small" class="mb-2" @click="copy(status.textColor!)">{{
          $t('copyColorToOthers')
        }}</vis-btn>
      </v-col>
      <v-col cols="9">
        <v-color-picker
          v-model="status.textColor"
          mode="rgb"
          hide-canvas
          width="100%"
          :modes="['rgb', 'hex', 'hsl']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <div>{{ $t('idleColor') }}</div>
        <vis-btn size="small" class="mb-2" @click="copy(status.idleColor!)">{{
          $t('copyColorToOthers')
        }}</vis-btn>
        <vis-text-field
          name="idleStrokeWidth"
          :label="$t('idleStrokeWidth')"
          :placeholder="$t('idleStrokeWidth')"
          v-model="status.idleStrokeWidth"
          hide-details
        />
      </v-col>
      <v-col cols="9">
        <v-color-picker
          v-model="status.idleColor"
          mode="rgba"
          hide-canvas
          width="100%"
          :modes="['rgba', 'hsla', 'hexa']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <div>{{ $t('highlightColor') }}</div>
        <vis-btn size="small" class="mb-2" @click="copy(status.highlightColor!)">{{
          $t('copyColorToOthers')
        }}</vis-btn>
        <vis-text-field
          name="highlightStrokeWidth"
          :label="$t('highlightStrokeWidth')"
          :placeholder="$t('highlightStrokeWidth')"
          v-model="status.highlightStrokeWidth"
          hide-details
        />
      </v-col>
      <v-col cols="9">
        <v-color-picker
          v-model="status.highlightColor"
          mode="rgba"
          hide-canvas
          width="100%"
          :modes="['rgba', 'hsla', 'hexa']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <div>{{ $t('activeColor') }}</div>
        <vis-btn size="small" class="mb-2" @click="copy(status.activeColor!)">{{
          $t('copyColorToOthers')
        }}</vis-btn>
        <vis-text-field
          name="activeStrokeWidth"
          :label="$t('activeStrokeWidth')"
          :placeholder="$t('activeStrokeWidth')"
          v-model="status.activeStrokeWidth"
          hide-details
        />
      </v-col>
      <v-col cols="9">
        <v-color-picker
          v-model="status.activeColor"
          mode="rgba"
          hide-canvas
          width="100%"
          :modes="['rgba', 'hsla', 'hexa']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" class="text-right">
        <div>{{ $t('inactiveColor') }}</div>
        <vis-btn size="small" class="mb-2" @click="copy(status.inactiveColor!)">{{
          $t('copyColorToOthers')
        }}</vis-btn>
        <vis-text-field
          name="inactiveStrokeWidth"
          :label="$t('inactiveStrokeWidth')"
          :placeholder="$t('inactiveStrokeWidth')"
          v-model="status.inactiveStrokeWidth"
          hide-details
        />
      </v-col>
      <v-col cols="9">
        <v-color-picker
          v-model="status.inactiveColor"
          mode="rgba"
          hide-canvas
          width="100%"
          :modes="['rgba', 'hsla', 'hexa']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import useFormPartials from '@/composables/useFormPartials'
import type Status from '@/types/status'
import { reactive } from 'vue'
import VisLocalizedInput from '../inputs/VisLocalizedInput.vue'

const props = defineProps<{ status?: Status }>()
const emit = defineEmits<{
  submit: [status: Partial<Status>]
}>()

const status = reactive<Partial<Status>>(
  props.status || {
    label: {},
    activeColor: '#2abb9b90',
    highlightColor: '#2abb9b90',
    idleColor: '#2abb9b5a',
    inactiveColor: '#7f7f7f5A',
    textColor: '#2abb9b',
    highlightStrokeWidth: 2,
    idleStrokeWidth: 2,
    activeStrokeWidth: 2,
    inactiveStrokeWidth: 2,
    isSold: false,
    hidePage: false,
    isFilteredByDefault: false,
    persistentBuildingColor: true,
    persistentUnitColor: true
  }
)

const copy = (color: string) => {
  status.activeColor = color.substring(0, 7) + status.activeColor!.substring(7, 9)
  status.highlightColor = color.substring(0, 7) + status.highlightColor!.substring(7, 9)
  status.idleColor = color.substring(0, 7) + status.idleColor!.substring(7, 9)
  status.textColor = color.substring(0, 7)
}

const { formRef, submit } = useFormPartials(
  status,
  (partial: Partial<Status>) => emit('submit', partial),
  'activeColor',
  'highlightColor',
  'idleColor',
  'inactiveColor',
  'textColor'
)
</script>
