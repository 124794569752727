import type { RouteRecordRaw } from 'vue-router'
import InteriorOptionListView from '@/views/project/renderSettings/interiorOptions/InteriorOptionListView.vue'
import createInteriorOptionRoute from './createInteriorOption'
import updateInteriorOptionRoute from './updateInteriorOption'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const interiorOptionsRoute: RouteRecordRaw = {
  path: '',
  name: 'interiorOptions',
  component: InteriorOptionListView,
  meta: {
    breadcrumb: 'interiorOptions',
    menu: {
      key: 'interiorOptions',
      icon: 'mdi-pencil-ruler',
      name: 'interiorOptions',
      exact: true
    },
    scopes: [KeycloakScope.ASSETS_LIST]
  },
  children: [createInteriorOptionRoute, updateInteriorOptionRoute]
}

export default interiorOptionsRoute
