<template>
  <vis-form fast-fail ref="formRef" @submit.prevent="(e) => validateAndSubmit(e)">
    <v-row>
      <v-col cols="12">
        <vis-text-field
          name="title"
          :placeholder="$t('title')"
          :label="$t('title')"
          :required="true"
          :rules="[(v) => !!v || $t('required')]"
          v-model="projectStyle.title"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ProjectStyleMaterials
          :projectStyleMaterials="projectStyle.style?.materials"
          :artistResources="artistResources"
          @updateMaterials="updateMaterials"
        />
      </v-col>

      <v-divider class="my-2"></v-divider>

      <v-col cols="12">
        <ProjectStyleMouldings
          :projectStyleMouldings="projectStyle.style?.mouldings"
          :artistResources="artistResources"
          @updateMouldings="updateMouldings"
        />
      </v-col>

      <v-divider class="my-2"></v-divider>

      <v-col cols="12">
        <ProjectStyleDoors
          v-if="projectStyle.style"
          :projectStyleDoors="_.isEmpty(projectStyle.style.doors) ? null : projectStyle.style.doors"
          :artistResources="artistResources"
          @updateDoors="updateDoors"
        />
      </v-col>

      <!-- 
      <v-col cols="12">
        <h4>Windows</h4>
      </v-col>
      -->
    </v-row>
    <v-row>
      <v-col cols="12">
        <div>
          <JsonViewer :value="projectStyle" :expandDepth="7" expanded copyable />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>
<script setup lang="ts">
import { reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'

import type ProjectStyle from '@/types/projectStyle'
import type ArtistResource from '@/types/artistResource'
import _ from 'lodash'
import {
  type DoorSpecification,
  type RoomMouldings,
  type RoomSurfaceStyle
} from '@/types/projectStyle'

import ProjectStyleMaterials from '../projectStyle/ProjectStyleMaterials.vue'
import ProjectStyleMouldings from '../projectStyle/ProjectStyleMouldings.vue'
import ProjectStyleDoors from '../projectStyle/ProjectStyleDoors.vue'

const props = defineProps<{
  projectStyle?: ProjectStyle
  artistResources?: Array<ArtistResource>
  getThumbnail?: (url: string) => string
}>()

const emit = defineEmits<{
  submit: [projectStyle: Partial<ProjectStyle>]
}>()

const updateMaterials = (materials: Array<RoomSurfaceStyle>) => {
  if (projectStyle.style?.materials) {
    projectStyle.style.materials = materials
  }
}

const updateMouldings = (mouldings: Array<RoomMouldings>) => {
  if (projectStyle.style?.mouldings) {
    projectStyle.style.mouldings = mouldings
  }
}

const updateDoors = (doors: DoorSpecification) => {
  if (projectStyle.style) {
    projectStyle.style.doors = doors
  }
}

const projectStyle = reactive<Partial<ProjectStyle>>(
  props.projectStyle || {
    title: '',
    isDefault: false,
    style: {
      materials: [],
      mouldings: [],
      doors: undefined,
      windows: {},
      assets: {}
    } as any
  }
)

const validateAndSubmit = async ($event: SubmitEvent) => {
  const form: { valid: boolean; errors: any } | undefined = await formRef.value?.validate()

  if (form?.valid === false) {
    return
  }

  submit($event)
}

const { formRef, submit } = useFormPartials(projectStyle, (partial: Partial<ProjectStyle>) => {
  emit('submit', projectStyle)
})
</script>
