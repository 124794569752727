import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Unit from '@/types/unit'
import executeApi from '@/utils/executeApi'

const useUnitStore = defineStore('unit', () => {
  const api = useApi()

  const unitsUrl = ref('/project')
  const unitsApi = api<Array<Unit>>(unitsUrl).json()
  const units: FetchResult<Array<Unit>> = reactiveFetchResult(unitsApi)
  const listUnits = async (projectId?: number): Promise<FetchResult<Array<Unit>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    unitsUrl.value = `/project/${projectIdParam}/unit`
    await executeApi(unitsApi.get())
    return units
  }

  const unitUrl = ref('/project')
  const unitApi = api<Array<Unit>>(unitUrl).json()
  const unit: FetchResult<Unit> = reactiveFetchResult(unitApi)
  const readUnit = async (id?: number, projectId?: number): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}`
    await executeApi(unitApi.get())
    return unit
  }

  const createUnit = async (
    data: Partial<Unit>,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    unitUrl.value = `/project/${projectIdParam}/unit`
    await executeApi(unitApi.post(data), true)
    return unit
  }

  const updateUnit = async (
    data: Partial<Unit>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}`
    await executeApi(unitApi.put(data), true)
    return unit
  }

  const deleteUnit = async (id?: number, projectId?: number): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}`
    await executeApi(unitApi.delete(), false)
    unit.data = null
    return unit
  }

  const uploadPdfToUnit = async (
    data: FormData,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}`
    await executeApi(unitApi.post(data))
    return unit
  }

  const updateUnitCameraRotation = async (
    interiorOptionId: string,
    cameraId: string,
    rotation: string,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}/id/${interiorOptionId}/${cameraId}`
    await executeApi(unitApi.put({ rotation }), true)
    return unit
  }

  const reorderUnitThumbnails = async (
    interiorOptionId: string,
    thumbnails: Array<string>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}/id/${interiorOptionId}`
    await executeApi(unitApi.post({ thumbnails }), true)
    return unit
  }

  const updateUnitThumbnail = async (
    interiorOptionId: string,
    cameraId: string,
    formData: FormData,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}/id/${interiorOptionId}/${cameraId}`
    await executeApi(unitApi.post(formData), true)
    return unit
  }

  const deleteUnitThumbnail = async (
    interiorOptionId: string,
    cameraId: string,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Unit>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/${idParam}/id/${interiorOptionId}/${cameraId}`
    await executeApi(unitApi.delete(), true)
    return unit
  }

  const apartmentDataUrl = ref('/project')
  const apartmentDataApi = api<{ cameras: Array<{ id: string; type: string }> }>(
    apartmentDataUrl
  ).json()
  const apartmentData: FetchResult<{ cameras: Array<{ id: string; type: string }> }> =
    reactiveFetchResult(apartmentDataApi)
  const listApartmentData = async (
    interiorOptionId: string,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<{ cameras: Array<{ id: string; type: string }> }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    apartmentDataUrl.value = `/project/${projectIdParam}/unit/${idParam}/id/${interiorOptionId}/apartmentData`
    await executeApi(apartmentDataApi.get())
    return apartmentData
  }

  const thumbnailDataUrl = ref('/project')
  const thumbnailDataApi = api<{ cameras: Array<{ id: string }> }>(thumbnailDataUrl).json()
  const thumbnailData: FetchResult<{ cameras: Array<{ id: string }> }> =
    reactiveFetchResult(thumbnailDataApi)
  const listThumbnailData = async (
    interiorOptionId: string,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<{ cameras: Array<{ id: string }> }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    thumbnailDataUrl.value = `/project/${projectIdParam}/unit/${idParam}/id/${interiorOptionId}/thumbnailData`
    await executeApi(thumbnailDataApi.get())
    return thumbnailData
  }

  const $reset = () => {
    resetFetchResult(units)
    resetFetchResult(unit)
    resetFetchResult(apartmentData)
    resetFetchResult(thumbnailData)
  }

  return {
    listUnits,
    readUnit,
    createUnit,
    updateUnit,
    deleteUnit,
    uploadPdfToUnit,
    units,
    unit,
    updateUnitCameraRotation,
    reorderUnitThumbnails,
    updateUnitThumbnail,
    deleteUnitThumbnail,
    listApartmentData,
    listThumbnailData,
    apartmentData,
    thumbnailData,
    $reset
  }
})

export default useUnitStore
