<template>
  <vis-loader :loading="user.isFetching || buildings.isFetching || roles.isFetching">
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <user-form @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useUserStore from '@/stores/user'
import UserForm from '@/components/forms/UserForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useBuildingStore from '@/stores/building'
import useRoleStore from '@/stores/role'

const { user, listUsers, createUser } = useUserStore()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { roles, listRoles } = useRoleStore()
roles.data || listRoles()

const onSubmit = useSubmitThenRoute(
  createUser,
  (u) => ({ name: 'updateUser', params: { userId: u.data!.id } }),
  () => listUsers()
)
</script>
