<template>
  <vis-loader :loading="interiorOption.isFetching">
    <interior-option-form :interiorOption="interiorOption.data!" @submit="onSubmit" />
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useInteriorOptionStore from '@/stores/interiorOption'
import InteriorOptionForm from '@/components/forms/InteriorOptionForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { interiorOption, listInteriorOptions, readInteriorOption, updateInteriorOption } =
  useInteriorOptionStore()

const onSubmit = useSubmitThenRoute(updateInteriorOption, { name: 'interiorOptions' }, () =>
  listInteriorOptions()
)

const interiorOptionId = useRouteId('interiorOptionId')
readInteriorOption(interiorOptionId)
</script>
