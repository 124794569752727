import { VIcon } from 'vuetify/components'
import { h } from 'vue'
import useI18nTranslator from '@/composables/useI18nTranslator'
import type Field from '@/types/field'
import type { ProjectFieldType } from '@/types/field'
import { useI18n } from 'vue-i18n'
import useCurrencyFormatter from '@/composables/useCurrencyFormatter'

type Props = DataTableCellProps & { type: Field | ProjectFieldType | string }

type TypeRendererMapHelpers = {
  translator: ReturnType<typeof useI18nTranslator>
  i18n: ReturnType<typeof useI18n>
  currencyFormatter: ReturnType<typeof useCurrencyFormatter>
}

// "any" type is a volar issue
type TypeRendererMap = {
  [key: string]: {
    valueRenderer: (props: DataTableCellProps<any>, helpers: TypeRendererMapHelpers) => string
    componentRenderer: (props: DataTableCellProps<any>, helpers: TypeRendererMapHelpers) => any
    needsProject: boolean
    needsStatuses: boolean
    needsField: boolean
  }
}

const typeRendererMap: TypeRendererMap = {
  area: {
    valueRenderer: (props: DataTableCellProps<number>) => {
      return `${props.value} ${props.project!.sizeUnit}`
    },
    componentRenderer: (props: DataTableCellProps<number>) => {
      return h('span', `${props.value} ${props.project!.sizeUnit}`)
    },
    needsProject: true,
    needsStatuses: false,
    needsField: false
  },
  boolean: {
    valueRenderer: (props: DataTableCellProps<boolean>, { i18n }) => {
      return props.value ? i18n.t('yes') : i18n.t('no')
    },
    componentRenderer: (props: DataTableCellProps<boolean>) => {
      return h(
        'span',
        props.value
          ? [h(VIcon, { color: 'success', icon: 'mdi-check-bold' })]
          : [h(VIcon, { color: 'error', icon: 'mdi-close-thick' })]
      )
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  },
  currency: {
    valueRenderer: (props: DataTableCellProps<number>, { currencyFormatter }) => {
      return currencyFormatter(props.value, props.project!.currency)
    },
    componentRenderer: (props: DataTableCellProps<number>, { currencyFormatter }) => {
      return h('span', [currencyFormatter(props.value, props.project!.currency)])
    },
    needsProject: true,
    needsStatuses: false,
    needsField: false
  },
  date: {
    valueRenderer: (props: DataTableCellProps<string | Date>) => {
      let date = props.value
      if (!(date instanceof Date)) {
        date = new Date(props.value)
      }
      return date.toLocaleDateString()
    },
    componentRenderer: (props: DataTableCellProps<string | Date>) => {
      let date = props.value
      if (!(date instanceof Date)) {
        date = new Date(props.value)
      }
      return h('span', [date.toLocaleDateString()])
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  },
  html: {
    valueRenderer: (props: DataTableCellProps<Translatable>, { translator }) => {
      return translator(props.value)
    },
    componentRenderer: (props: DataTableCellProps<Translatable>, { translator }) => {
      return h('span', { innerHTML: translator(props.value) })
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  },
  id: {
    valueRenderer: (props: DataTableCellProps<string>) => {
      return props.value
    },
    componentRenderer: (props: DataTableCellProps<string>) => {
      return h('span', [props.value])
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  },
  number: {
    valueRenderer: (props: DataTableCellProps<number>) => {
      return props.value.toString()
    },
    componentRenderer: (props: DataTableCellProps<number>) => {
      return h('span', [props.value])
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  },
  status: {
    valueRenderer: (props: DataTableCellProps<number>) => {
      const status = props.statuses!.find((s) => s.id === props.value)
      const translator = useI18nTranslator()
      return translator(status!.label)
    },
    componentRenderer: (props: DataTableCellProps<number>) => {
      const status = props.statuses!.find((s) => s.id === props.value)
      const translator = useI18nTranslator()
      return h('span', { style: { color: status!.textColor } }, translator(status!.label))
    },
    needsProject: false,
    needsStatuses: true,
    needsField: false
  },
  string: {
    valueRenderer: (props: DataTableCellProps<Translatable>, { translator }) => {
      return translator(props.value)
    },
    componentRenderer: (props: DataTableCellProps<Translatable>, { translator }) => {
      return h('span', { innerHTML: translator(props.value) })
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  },
  url: {
    valueRenderer: (props: DataTableCellProps<Translatable>, { translator }) => {
      const value = translator(props.value)
      const array = value.split('|')
      return array.length === 2 ? array[1] : array[0]
    },
    componentRenderer: (props: DataTableCellProps<Translatable>, { translator }) => {
      const value = translator(props.value)
      const array = value.split('|')
      if (array.length === 2) {
        return h('a', { innerHTML: array[0], src: array[1], target: '_blank' })
      } else {
        return h('a', { innerHTML: array[0], src: array[0], target: '_blank' })
      }
    },
    needsProject: false,
    needsStatuses: false,
    needsField: false
  }
}

export default function DataTableCellRenderer(props: Props) {
  const { type, field, customRenderer, emptyRenderer, value, status, fieldStatusOption } = props
  let checkedType
  let fieldProp = field
  if (typeof type === 'string') {
    checkedType = type
  } else {
    checkedType = type.type
    if (!fieldProp) {
      fieldProp = type
    }
  }

  const typeRenderer =
    checkedType in typeRendererMap ? typeRendererMap[checkedType] : typeRendererMap['id']

  if (typeRenderer.needsProject && !props.project) {
    throw new Error('missing project in props')
  }

  if (typeRenderer.needsStatuses && !props.statuses) {
    throw new Error('missing statuses in props')
  }

  if (typeRenderer.needsField && !props.field) {
    throw new Error('missing field in props')
  }

  const translator = useI18nTranslator()
  const i18n = useI18n()
  const currencyFormatter = useCurrencyFormatter()

  const helpers = {
    translator,
    i18n,
    currencyFormatter
  }

  let emptyRendererProp = emptyRenderer
  let customRendererProp = customRenderer
  if (
    fieldStatusOption &&
    status &&
    field &&
    fieldStatusOption.projectFieldId === field.id &&
    fieldStatusOption.projectStatusId === status.id
  ) {
    if (fieldStatusOption.emptyRenderer) {
      emptyRendererProp = fieldStatusOption.emptyRenderer
    }
    if (fieldStatusOption.customRenderer) {
      customRendererProp = fieldStatusOption.customRenderer
    }
  }
  const showEmptyRenderer = !!emptyRendererProp && !value

  if (typeof customRendererProp === 'function') {
    customRendererProp = customRendererProp(props)
  }

  return (
    <>
      {fieldProp && fieldProp.prefix && <span>{translator(fieldProp.prefix)}&nbsp;</span>}
      {showEmptyRenderer && h('span', { innerHTML: emptyRendererProp })}
      {!showEmptyRenderer && !customRendererProp && typeRenderer.componentRenderer(props, helpers)}
      {!showEmptyRenderer &&
        customRendererProp &&
        typeof customRendererProp === 'string' &&
        h('span', {
          innerHTML: customRendererProp
            .replace('{value}', typeRenderer.valueRenderer(props, helpers))
            .replace('{raw}', value)
        })}
      {!showEmptyRenderer &&
        customRendererProp &&
        typeof customRendererProp !== 'string' &&
        h('span', [customRendererProp])}
      {fieldProp && fieldProp.suffix && <span>&nbsp;{translator(fieldProp.suffix)}</span>}
    </>
  )
}
