import type KeycloakScope from '@/configs/keycloak/KeycloakScope'
import useAuthStore from '@/stores/auth'
import scopeCheck from '@/utils/scopeCheck'

export default function useScopeCheck() {
  const authStore = useAuthStore()
  return (...scopes: Array<KeycloakScope>) => {
    return scopeCheck(authStore.scopes, ...scopes)
  }
}
