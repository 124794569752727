import type { RouteRecordRaw } from 'vue-router'
import AssetManagementView from '@/views/AssetManagementView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const manageAssetsRoute: RouteRecordRaw = {
  path: '/manageAssets',
  name: 'manageAssets',
  component: AssetManagementView,
  meta: {
    scopes: [KeycloakScope.ASSETS_UPDATE]
  }
}

export default manageAssetsRoute
