<template>
  <template v-if="route.name === 'fields'">
    <vis-loader :loading="field.isFetching || fields.isFetching || patch.isFetching">
      <fields-table
        :project="project.data!"
        :fields="fields.data!"
        v-on="{
          add: check(KeycloakScope.PROJECT_UPDATE) ? onAdd : undefined,
          select: check(KeycloakScope.PROJECT_UPDATE) ? onSelect : undefined,
          delete: check(KeycloakScope.PROJECT_UPDATE) ? onDelete : undefined,
          reorder: check(KeycloakScope.PROJECT_UPDATE) ? onReorder : undefined,
          hiddenInList: check(KeycloakScope.PROJECT_UPDATE) ? onHiddenInList : undefined,
          hiddenInDetails: check(KeycloakScope.PROJECT_UPDATE) ? onHiddenInDetails : undefined,
          sortable: check(KeycloakScope.PROJECT_UPDATE) ? onSortable : undefined,
          filterable: check(KeycloakScope.PROJECT_UPDATE) ? onFilterable : undefined
        }"
      />
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useFieldStore from '@/stores/field'
import FieldsTable from '@/components/tables/FieldsTable.vue'
import useProjectStore from '@/stores/project'
import type Field from '@/types/field'
import { ProjectFieldSort } from '@/types/field'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { field, fields, patch, listFields, updateField, deleteField, patchFields } = useFieldStore()
fields.data || listFields()

const onAdd = () => router.push({ name: 'createField' })

const onSelect = (field: Field) => {
  router.push({
    name: 'updateField',
    params: {
      fieldId: field.id
    }
  })
}

const onDelete = async (field: Field) => {
  if (confirm(t('areYouSureYouWantToDeleteTheField', [field.id]))) {
    await deleteField(field.id)
    listFields()
  }
}

const onReorder = async (reordered: Array<Partial<Field>>) => {
  await patchFields(reordered)
  listFields()
}

const onHiddenInList = async (field: Field) => {
  await updateField({ ...field, hiddenInList: !field.hiddenInList }, field.id)
  listFields()
}

const onHiddenInDetails = async (field: Field) => {
  await updateField({ ...field, hiddenInDetails: !field.hiddenInDetails }, field.id)
  listFields()
}

const onSortable = async (field: Field) => {
  const sortableFields = [...fields.data!]
  const sortable =
    field.sortable === ProjectFieldSort.asc
      ? ProjectFieldSort.desc
      : field.sortable === ProjectFieldSort.desc
        ? ProjectFieldSort.enabled
        : field.sortable === ProjectFieldSort.enabled
          ? ProjectFieldSort.disabled
          : ProjectFieldSort.asc
  if (sortable === ProjectFieldSort.asc || sortable === ProjectFieldSort.desc) {
    sortableFields.forEach((f) => {
      if (f.id === field.id) {
        f.sortable = sortable
      } else if (f.sortable !== ProjectFieldSort.disabled) {
        f.sortable = ProjectFieldSort.enabled
      }
    })
  } else {
    sortableFields.find((f) => f.id === field.id)!.sortable = sortable
    if (
      sortableFields.filter(
        (f) => f.sortable === ProjectFieldSort.asc || f.sortable === ProjectFieldSort.desc
      ).length === 0
    ) {
      const first = sortableFields.find((f) => f.sortable === ProjectFieldSort.enabled)
      if (first) {
        first.sortable = ProjectFieldSort.asc
      } else {
        sortableFields.find((f) => f.id === field.id)!.sortable = ProjectFieldSort.asc
      }
    }
  }
  await patchFields(sortableFields)
  listFields()
}

const onFilterable = async (field: Field) => {
  await updateField({ ...field, filterable: !field.filterable }, field.id)
  listFields()
}
</script>
