import { useI18n } from 'vue-i18n'

export default function useI18nTranslator(): (
  i18nObject: Translatable,
  language?: string
) => string {
  const { locale } = useI18n()
  const localLocaleValue = locale.value
  return (i18nObject: Translatable, language?: string) => {
    const lang = language || localLocaleValue
    if (i18nObject) {
      if (lang in i18nObject && i18nObject[lang]) {
        return i18nObject[lang]
      } else {
        for (const l in i18nObject) {
          if (i18nObject[l]) {
            return i18nObject[l]
          }
        }
      }
    }
    return ''
  }
}
