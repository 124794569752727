import { h } from 'vue'
import type Field from '@/types/field'
import { useI18n } from 'vue-i18n'
import useI18nTranslator from '../useI18nTranslator'

export default function useIntegrationFieldTableDefinitions(
  fields: Array<Field>
): VDataTableColumns {
  const { t } = useI18n()
  const translator = useI18nTranslator()
  return [
    { title: t('id'), key: 'id', type: 'number', filterable: false },
    {
      title: t('field'),
      key: 'projectFieldId',
      type: 'number',
      customRenderer: ({ value }: any) => {
        const field = fields?.find((c) => c.id === value)
        return field
          ? h('span', { data: { label: translator(field.label) } }, [translator(field.label)])
          : h('span', '-')
      }
    },
    { title: t('jsonPath'), key: 'jsonPath', type: 'id', filterable: false },
    {
      title: t('isIdentifierKey'),
      key: 'isIdentifierKey',
      type: 'boolean',
      filterable: false
    },
    { title: '', key: 'actions', sortable: false }
  ]
}
