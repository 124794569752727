export default class CsvExporter {
  rows
  headers
  filename

  constructor(rows: Array<Array<any>>, headers: Array<string>, filename: string) {
    this.rows = rows
    this.headers = headers
    this.filename = filename || 'export'
  }

  convertToCSV(rows: Array<Array<any>>) {
    let str = ''

    for (let i = 0; i < rows.length; i++) {
      let line = ''
      for (let j = 0; j < rows[i].length; j++) {
        if (line !== '') {
          line += ','
        }
        if (typeof rows[i][j] === 'string') {
          line += `"${rows[i][j].replace(/"/g, '""')}"`.trim()
        } else if (typeof rows[i][j] === 'boolean') {
          line += rows[i][j] ? 'yes' : 'no'
        } else if (rows[i][j] === undefined || rows[i][j] === null) {
          line += ''
        } else {
          line += rows[i][j]
        }
      }
      str += line + '\r\n'
    }

    return str
  }

  log() {
    console.log('Headers', this.headers)
    console.log('Rows', this.rows)
  }

  exportCsvFile() {
    const rows = [...this.rows]
    if (this.headers) {
      rows.unshift(this.headers)
    }

    const csv = this.convertToCSV(rows)
    const filename = this.filename + '.csv'
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
