import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type User from '@/types/user'
import executeApi from '@/utils/executeApi'

const useUserStore = defineStore('user', () => {
  const api = useApi()

  const usersUrl = ref('/project')
  const usersApi = api<Array<User>>(usersUrl).get().json()
  const users: FetchResult<Array<User>> = reactiveFetchResult(usersApi)
  const listUsers = async (projectId?: number): Promise<FetchResult<Array<User>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    usersUrl.value = `/project/${projectIdParam}/user`
    await executeApi(usersApi)
    return users
  }

  const userUrl = ref('/project')
  const userApi = api<Array<User>>(userUrl).json()

  const user: FetchResult<User> = reactiveFetchResult(userApi)
  const readUser = async (id?: number, projectId?: number): Promise<FetchResult<User>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || user.data?.id
    userUrl.value = `/project/${projectIdParam}/user/${idParam}`
    await executeApi(userApi.get())
    return user
  }

  const createUser = async (
    data: Partial<User>,
    projectId?: number
  ): Promise<FetchResult<User>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    userUrl.value = `/project/${projectIdParam}/user`
    await executeApi(userApi.post(data), true)
    return user
  }

  const updateUser = async (
    data: Partial<User>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<User>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || user.data?.id
    userUrl.value = `/project/${projectIdParam}/user/${idParam}`
    await executeApi(userApi.put(data), true)
    return user
  }

  const deleteUser = async (id?: number, projectId?: number): Promise<FetchResult<User>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || user.data?.id
    userUrl.value = `/project/${projectIdParam}/user/${idParam}`
    await executeApi(userApi.delete(), false)
    user.data = null
    return user
  }

  const $reset = () => {
    resetFetchResult(users)
    resetFetchResult(user)
  }

  return {
    listUsers,
    readUser,
    createUser,
    updateUser,
    deleteUser,
    users,
    user,
    $reset
  }
})

export default useUserStore
