<template>
  <h4>Doors</h4>

  <div v-for="(singleDoor, doorTypeKey, index) in doors" class="ma-0 pa-0 bg-white">
    <div>
      <div class="d-flex align-center">
        <div class="door-title pa-2 border-b align-self-stretch d-flex">
          <span class="text-bold">{{ doorTypeKey }} </span>
        </div>
        <div class="flex-grow-1 border">
          <div v-if="singleDoor" class="ma-5">
            <SingleMaterialOrAsset
              :key="`${doorTypeKey}-${singleDoor.assetId}-${index}`"
              :artistAsset="
                {
                  key: doorTypeKey,
                  id: singleDoor.assetId,
                  variant: null
                } as MaterialOrAssetSpecification
              "
              :artistResources="artistResources ? artistResources : []"
              @update="
                (material: ArtistResource, variant) =>
                  updateOneDoorSpecification(doorTypeKey, material, variant)
              "
            />

            <div class="ml-10">
              <div
                v-for="(changeableMaterial, cmIndex) in getSelectedChangeableMaterials(singleDoor)"
                class="d-flex align-center"
              >
                <div class="material-name" :title="changeableMaterial.key">
                  <span class="text-caption text-no-wrap">{{ changeableMaterial.key }}</span>
                </div>

                <div class="flex-grow-1 pa-1 border">
                  <SingleMaterialOrAsset
                    :key="`${doorTypeKey}-${singleDoor.assetId}-cm-${cmIndex}`"
                    :artistAsset="
                      {
                        key: changeableMaterial.key,
                        id: changeableMaterial.value,
                        variant: null
                      } as MaterialOrAssetSpecification
                    "
                    :artistResources="artistResourceMaterials ? artistResourceMaterials : []"
                    @update="
                      (material: ArtistResource, variant) =>
                        updateChangeableMaterial(
                          doorTypeKey,
                          changeableMaterial.key,
                          material,
                          variant
                        )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="text-caption ma-5 text-center">
            <SingleMaterialOrAsset
              :key="`${doorTypeKey}-0-${index}`"
              :artistAsset="
                {
                  key: doorTypeKey,
                  id: '000',
                  variant: null
                } as MaterialOrAssetSpecification
              "
              :artistResources="artistResources ? artistResources : []"
              @update="
                (material: ArtistResource, variant) =>
                  updateOneDoorSpecification(doorTypeKey, material, variant)
              "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type ArtistResource from '@/types/artistResource'
import {
  type DoorSpecification,
  type MaterialOrAssetSpecification,
  type AssetWithMaterial,
  type AssetMaterial
} from '@/types/projectStyle'
import SingleMaterialOrAsset from './SingleMaterialOrAsset.vue'
import { watch, computed, ref, onMounted, type ComputedRef, type ModelRef, type Ref } from 'vue'
import _ from 'lodash'

const props = defineProps(['artistResources', 'projectStyleDoors'])
const emits = defineEmits<{
  updateDoors: [DoorSpecification]
}>()

const defaultDoorSpecification: DoorSpecification = {
  exteriorDoor: {
    assetId: '000824',
    materials: null
  },
  interiorDoor: {
    assetId: '001276',
    materials: null
  },
  slidingDoor: null,
  balconyDoor: null
}

const doors: Ref<DoorSpecification> = ref(defaultDoorSpecification)

onMounted(() => {
  if (props.projectStyleDoors) {
    doors.value = { ...defaultDoorSpecification, ...props.projectStyleDoors }
    return
  }
})

const artistResourceMaterials: ComputedRef<Array<ArtistResource>> = computed(() => {
  return props.artistResources?.filter(
    (resource: ArtistResource) => resource.resourceType === 'material'
  )
})

const getChangeableMaterials = (resourceId: string): Array<AssetMaterial> => {
  const resource = props.artistResources?.find(
    (resource: ArtistResource) => resource.publicId === resourceId
  )

  if (resource) {
    if (!resource.metadata.changeableMaterials) return []
    return resource.metadata.changeableMaterials.map((m: any) => {
      return { key: m.materialName, value: m.id }
    }) as Array<AssetMaterial>
  }

  return []
}

const getSelectedChangeableMaterials = (singleDoor: AssetWithMaterial): Array<AssetMaterial> => {
  const assetChangeableMaterials: Array<AssetMaterial> = getChangeableMaterials(singleDoor.assetId)

  if (!singleDoor.materials) {
    return getChangeableMaterials(singleDoor.assetId)
  }

  const selectedMaterials = singleDoor.materials

  let merged = []

  for (let index = 0; index < assetChangeableMaterials.length; index++) {
    merged.push({
      ...assetChangeableMaterials[index],
      ...selectedMaterials.find((m) => m.key === assetChangeableMaterials[index].key)
    })
  }

  return merged
}

const updateChangeableMaterial = (
  doorTypeKey: keyof DoorSpecification,
  materialName: string,
  material: ArtistResource,
  variant: any
) => {
  const doorsCopy = doors.value

  let oneDoorType = doorsCopy[doorTypeKey]

  if (!oneDoorType) {
    return
  }

  if (oneDoorType.materials === null) {
    oneDoorType.materials = []
  } else {
    if (oneDoorType.materials?.find((m) => m.key === materialName)) {
      oneDoorType.materials = oneDoorType.materials.filter((m) => m.key !== materialName)
    }
  }

  oneDoorType.materials?.push({
    key: materialName,
    value: material.publicId
  })

  doorsCopy[doorTypeKey] = oneDoorType

  doors.value = doorsCopy
}

const updateOneDoorSpecification = (
  doorTypeKey: keyof DoorSpecification,
  asset: ArtistResource,
  variant: any
) => {
  const doorsCopy = doors.value

  doorsCopy[doorTypeKey] = {
    assetId: asset.publicId,
    materials: null
  }

  doors.value = doorsCopy
}

watch(
  doors,
  () => {
    updateDoors(doors.value as DoorSpecification)
  },
  {
    deep: true
  }
)

const updateDoors = (doors: DoorSpecification) => {
  emits('updateDoors', doors)
}
</script>
<style scoped>
.door-title {
  min-width: 200px;
}

.material-name {
  max-width: 180px;
  overflow: hidden;
  width: 30%;
}
</style>
