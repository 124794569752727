<template>
  <vis-loader
    :loading="
      project.isFetching ||
      buildings.isFetching ||
      fields.isFetching ||
      statuses.isFetching ||
      units.isFetching ||
      unit.isFetching
    "
  >
    <unit-rows-table
      :project="project.data!"
      :buildings="buildings.data!"
      :fields="fields.data!"
      :statuses="statuses.data!"
      :unitRows="units.data!"
      @edit="save"
    />
  </vis-loader>
</template>

<script setup lang="ts">
import UnitRowsTable from '@/components/tables/UnitRowsTable.vue'
import useBuildingStore from '@/stores/building'
import useFieldStore from '@/stores/field'
import useProjectStore from '@/stores/project'
import useStatusStore from '@/stores/status'
import useUnitRowStore from '@/stores/unitRow'

const { project } = useProjectStore()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { fields, listFields } = useFieldStore()
fields.data || listFields()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { units, unit, listUnits, updateUnit } = useUnitRowStore()
units.data || listUnits()

const save = async (rowData: { [key: string]: any }) => {
  await updateUnit(rowData, rowData.id)
  listUnits()
}
</script>
