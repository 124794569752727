import { type NavigationHookAfter, useLink } from 'vue-router'
import useAppStore from '@/stores/app'
import i18n from '../../i18n'

const breadcrumb: NavigationHookAfter = function (toRoute) {
  const t = i18n.global.t
  const crumbs: Array<{ title: string; to: string }> = []
  for (const route of toRoute.matched) {
    if ('breadcrumb' in route.meta) {
      const params: { [key: string]: any } = {}
      for (const param in toRoute.params) {
        if (route.path.includes(`:${param}`)) {
          params[param] = toRoute.params[param]
        }
      }
      const to = useLink({ to: { name: route.name, params } }).href.value
      crumbs.push({ title: t(route.meta.breadcrumb as string), to })
    }
  }

  const appStore = useAppStore()
  appStore.setBreadcrumbs(crumbs)
}

export default breadcrumb
