import type { RouteRecordRaw } from 'vue-router'
import StatusCreateView from '@/views/project/settings/statuses/StatusCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createStatusRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createStatus',
  component: StatusCreateView,
  meta: {
    breadcrumb: 'createStatus',
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default createStatusRoute
