<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="given_name"
          :placeholder="$t('firstname')"
          :label="$t('firstname')"
          v-model="user.given_name"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="surname"
          :placeholder="$t('lastname')"
          :label="$t('lastname')"
          v-model="user.surname"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="email"
          :placeholder="$t('email')"
          :label="$t('email')"
          v-model="user.email"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="phone_number"
          :placeholder="$t('phone')"
          :label="$t('phone')"
          v-model="user.phone_number"
        />
      </v-col>
      <v-col v-if="roles" sm="6" cols="12">
        <vis-select
          name="role"
          :items="roles.map(({ id: value, name: title }) => ({ value, title }))"
          :placeholder="$t('role')"
          :label="$t('role')"
          v-model="user.role"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type { Role } from '@/types/role'
import type User from '@/types/user'

const props = defineProps<{
  user?: User
  roles?: Array<Role>
}>()

const emit = defineEmits<{
  submit: [user: Partial<User>]
}>()

const user = reactive<Partial<User>>(props.user || {})

const { formRef, submit } = useFormPartials(user, (partial: Partial<User>) =>
  emit('submit', partial)
)
</script>
