<template>
  <div class="single-artistAsset-controls">
    <v-dialog transition="dialog-bottom-transition" fullscreen v-model="dialog">
      <template v-slot:default="{ isActive }">
        <v-card>
          <v-card-title class="bg-primary">Select Resource</v-card-title>
          <div class="position-absolute top-0 right-0">
            <v-btn
              icon="mdi-close"
              class="ma-1"
              size="small"
              @click="isActive.value = false"
            ></v-btn>
          </div>

          <ArtistResourceInput
            v-if="artistResources?.length"
            :artistResources="artistResources"
            :defaultFilter="[]"
            :selectedResourceId="artistAsset.id"
            :selectedResourceVariantId="artistAsset.variant"
            @onSelect="(...args) => update(args[0], args[1])"
          />

          <v-btn text="Close" @click="isActive.value = false"></v-btn>
        </v-card>
      </template>
    </v-dialog>
  </div>

  <div class="single-artistAsset position-relative d-flex align-center" @click="dialog = true">
    <div class="pa-1">
      <PreviewImage
        v-if="getArtistResource(artistAsset.id)"
        :src="getArtistResourceThumbnailSrc(artistAsset.id, artistAsset.variant) as string"
        :key="artistAsset.id"
        height="50px"
        width="50px"
      />
    </div>

    <div v-if="getArtistResource(artistAsset.id)" class="d-block bottom-0 left-0 pa-1">
      <div class="text-caption">{{ artistAsset.id }}</div>
      <div class="text-caption">{{ getArtistResourceTitle(artistAsset.id) }}</div>
      <div v-if="artistAsset.variant" class="text-caption">
        {{ artistAsset.variant }}
      </div>
    </div>
    <div v-else>
      <div class="text-caption text-grey-lighten-1" @click="dialog = true">
        (N/A) Click here to Select
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type ArtistResource from '@/types/artistResource'
import ArtistResourceInput from '../artistAssets/ArtistResourceInput.vue'
import type { MaterialOrAssetSpecification, MouldingSpecification } from '@/types/projectStyle'
import PreviewImage from '../artistAssets/PreviewImage.vue'

import { ref } from 'vue'

const dialog = ref(false)

const props = defineProps<{
  artistAsset: MaterialOrAssetSpecification | MouldingSpecification
  artistResources: Array<any>
}>()

const emits = defineEmits<{
  update: [artistResource: ArtistResource, variant: string]
  delete: []
}>()

const update: any = (artistResource: ArtistResource, variant: string) => {
  dialog.value = false
  emits('update', artistResource, variant)
}

const getArtistResourceTitle = (id: string) => {
  const resource = getArtistResource(id)
  return resource ? resource.metadata.title : ''
}

const getArtistResource = (id: string) => {
  return props.artistResources?.find((resource) => resource.publicId === id)
}

const getArtistResourceThumbnailSrc = (id: string, variant: string | null = null) => {
  let thumbnailUrl = getArtistResource(id)?.metadata.thumbnailUrl

  if (variant) {
    const variantThumbnailUrl = getArtistResource(id)?.metadata.variants.find(
      (v: any) => v.id === variant
    )?.thumbnailUrl

    if (variantThumbnailUrl) {
      thumbnailUrl = variantThumbnailUrl
    }
  }

  return thumbnailUrl
}
</script>
<style scoped>
.single-artistAsset {
  cursor: pointer;
}

.single-artistAsset:hover {
  background-color: rgba(208, 238, 255, 0.1);
}
</style>
