import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import type { Role } from '@/types/role'
import { useI18n } from 'vue-i18n'
import executeApi from '@/utils/executeApi'

const useRoleStore = defineStore('role', () => {
  const api = useApi()
  const { t } = useI18n()

  const rolesApi = api<Array<Role>>('/role', {
    afterFetch(ctx) {
      if (ctx.data && Array.isArray(ctx.data)) {
        ctx.data.unshift({ id: null, name: t('guest') })
      }
      return ctx
    }
  })
    .get()
    .json()
  const roles: FetchResult<Array<Role>> = reactiveFetchResult(rolesApi)
  const listRoles = async (): Promise<FetchResult<Array<Role>>> => {
    await executeApi(rolesApi)
    return roles
  }

  const $reset = () => {}

  return {
    roles,
    listRoles,
    $reset
  }
})

export default useRoleStore
