<template>
  <vis-loader
    :loading="
      statuses.isFetching || fieldStatusOptions.isFetching || field.isFetching || patch.isFetching
    "
  >
    <vis-sheet>
      <field-form
        :statuses="statuses.data!"
        :status-options="fieldStatusOptions.data!"
        :field="field.data!"
        @submit="onSubmit"
      />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useFieldStore from '@/stores/field'
import FieldForm from '@/components/forms/FieldForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useStatusStore from '@/stores/status'
import useFieldStatusOptionStore from '@/stores/fieldStatusOption'
import type Field from '@/types/field'
import type FieldStatusOption from '@/types/fieldStatusOption'

const { field, listFields, readField, updateField } = useFieldStore()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { fieldStatusOptions, patch, listFieldStatusOptions, patchFieldStatusOptions } =
  useFieldStatusOptionStore()

const onSubmit = useSubmitThenRoute<any, any>(
  async ({
    field,
    statusOptions
  }: {
    field: Partial<Field>
    statusOptions: Array<Partial<FieldStatusOption>>
  }) => {
    await updateField(field)
    await patchFieldStatusOptions(statusOptions)
  },
  { name: 'fields' },
  () => listFields()
)

const fieldId = useRouteId('fieldId')
readField(fieldId).then(() => {
  listFieldStatusOptions()
})
</script>
