import type { RouteRecordRaw } from 'vue-router'
import SettingsView from '@/views/project/settings/SettingsView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const settingsRoute: RouteRecordRaw = {
  path: '',
  name: 'settings',
  component: SettingsView,
  meta: {
    breadcrumb: 'settings',
    menu: {
      key: 'settings',
      icon: 'mdi-home-group',
      name: 'settings',
      exact: true
    },
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default settingsRoute
