import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import type User from '@/types/user'
import useUnitStore from './unit'
import { ref } from 'vue'
import resetFetchResult from '@/utils/resetFetchResult'
import executeApi from '@/utils/executeApi'

const useUnitPermissionStore = defineStore('unitPermission', () => {
  const api = useApi()

  const unitUsersUrl = ref('/project')
  const unitUsersApi = api<Array<User>>(unitUsersUrl).get().json()
  const unitUsers: FetchResult<Array<User>> = reactiveFetchResult(unitUsersApi)
  const listUnitUsers = async (
    unitId?: number,
    projectId?: number
  ): Promise<FetchResult<Array<User>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let unitIdParam = unitId
    if (!unitIdParam) {
      const unitStore = useUnitStore()
      unitIdParam = unitStore.unit.data!.id
    }
    unitUsersUrl.value = `/project/${projectIdParam}/unit/${unitIdParam}/permission`
    await executeApi(unitUsersApi.get())
    return unitUsers
  }

  const grantUnitPermissions = async (
    email: string,
    unitId?: number,
    projectId?: number
  ): Promise<FetchResult<boolean>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let unitIdParam = unitId
    if (!unitIdParam) {
      const unitStore = useUnitStore()
      unitIdParam = unitStore.unit.data!.id
    }
    const response = await executeApi(
      api<boolean>(`/project/${projectIdParam}/unit/${unitIdParam}/permission/${email}`).put(),
      true
    )
    return response.json()
  }

  const revokeUnitPermissions = async (
    email: string,
    unitId?: number,
    projectId?: number
  ): Promise<FetchResult<boolean>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let unitIdParam = unitId
    if (!unitIdParam) {
      const unitStore = useUnitStore()
      unitIdParam = unitStore.unit.data!.id
    }
    const response = await executeApi(
      api<boolean>(`/project/${projectIdParam}/unit/${unitIdParam}/permission/${email}`).delete(),
      true
    )
    return response.json()
  }

  const $reset = () => {
    resetFetchResult(unitUsers)
  }

  return {
    unitUsers,
    listUnitUsers,
    grantUnitPermissions,
    revokeUnitPermissions,
    $reset
  }
})

export default useUnitPermissionStore
