<template>
  <template v-if="route.name === 'statuses'">
    <vis-loader :loading="statuses.isFetching || patch.isFetching">
      <statuses-table
        :project="project.data!"
        :statuses="statuses.data!"
        v-on="{
          add: check(KeycloakScope.PROJECT_UPDATE) ? onAdd : undefined,
          select: check(KeycloakScope.PROJECT_UPDATE) ? onSelect : undefined,
          delete: check(KeycloakScope.PROJECT_UPDATE) ? onDelete : undefined,
          reorder: check(KeycloakScope.PROJECT_UPDATE) ? onReorder : undefined
        }"
      />
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useStatusStore from '@/stores/status'
import StatusesTable from '@/components/tables/StatusesTable.vue'
import useProjectStore from '@/stores/project'
import type Status from '@/types/status'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { statuses, patch, listStatuses, deleteStatus, patchStatuses } = useStatusStore()
statuses.data || listStatuses()

const onAdd = () => router.push({ name: 'createStatus' })

const onSelect = (status: Status) =>
  router.push({
    name: 'updateStatus',
    params: {
      statusId: status.id
    }
  })

const onDelete = async (status: Status) => {
  if (confirm(t('areYouSureYouWantToDeleteTheStatus', [status.id]))) {
    await deleteStatus(status.id)
    listStatuses()
  }
}

const onReorder = async (reordered: Array<Partial<Status>>) => {
  await patchStatuses(reordered)
  listStatuses()
}
</script>
