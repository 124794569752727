<template>
  <vis-loader :loading="statuses.isFetching || patch.isFetching">
    <vis-sheet>
      <field-form :statuses="statuses.data!" @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useFieldStore from '@/stores/field'
import FieldForm from '@/components/forms/FieldForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useStatusStore from '@/stores/status'
import useFieldStatusOptionStore from '@/stores/fieldStatusOption'
import type Field from '@/types/field'
import type FieldStatusOption from '@/types/fieldStatusOption'

const { listFields, createField } = useFieldStore()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { patch, patchFieldStatusOptions } = useFieldStatusOptionStore()

const onSubmit = useSubmitThenRoute<any, any>(
  async ({
    field,
    statusOptions
  }: {
    field: Partial<Field>
    statusOptions: Array<Partial<FieldStatusOption>>
  }) => {
    const f = await createField({
      ...field,
      filterable: true,
      hiddenInDetails: false,
      hiddenInList: false,
      orderInDetails: -1,
      orderInList: -1
    })
    await patchFieldStatusOptions(statusOptions, f.data!.id!)
  },
  { name: 'fields' },
  () => listFields()
)
</script>
