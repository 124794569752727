<template>
  <div class="resizable-container" ref="container">
    <!-- Left column -->
    <div class="column left-column" :style="{ width: leftWidth + 'px' }">
      <slot name="left"> &nbsp; </slot>
    </div>

    <!-- Draggable separator -->
    <div class="separator ma-2" @mousedown="startDragging" @touchstart="startDragging">
      <div class="drag-indicator">
        <v-icon>mdi-drag-vertical</v-icon>
      </div>
    </div>

    <!-- Right column -->
    <div class="column right-column pa-2" :style="{ width: rightWidth + 'px' }">
      <slot name="right"> &nbsp; </slot>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  initialLeftPercentage: {
    type: Number,
    default: 80
  },
  initialRightPercentage: {
    type: Number,
    default: 20
  },
  minWidth: {
    type: Number,
    default: 100
  }
})

const container = ref(null)
const leftWidth = ref(0)
const isDragging = ref(false)
const startX = ref(0)
const startLeftWidth = ref(0)

const rightWidth = computed(() => {
  if (!container.value) return 0
  const maxRightWidth = container.value.clientWidth * 0.5
  const calculatedWidth = container.value.clientWidth - leftWidth.value - 10
  return Math.min(calculatedWidth, maxRightWidth)
})

const getMinLeftWidth = () => {
  if (!container.value) return props.minWidth
  const containerWidth = container.value.clientWidth
  const minLeftToMaintainRightColumn = containerWidth * 0.5 - 10
  return Math.max(props.minWidth, minLeftToMaintainRightColumn)
}

const startDragging = (event) => {
  isDragging.value = true
  startX.value = event.type === 'mousedown' ? event.clientX : event.touches[0].clientX
  startLeftWidth.value = leftWidth.value

  document.addEventListener('mousemove', handleDragging)
  document.addEventListener('mouseup', stopDragging)
  document.addEventListener('touchmove', handleDragging)
  document.addEventListener('touchend', stopDragging)
}

const handleDragging = (event) => {
  if (!isDragging.value) return

  const currentX = event.type === 'mousemove' ? event.clientX : event.touches[0].clientX
  const difference = currentX - startX.value
  const newLeftWidth = startLeftWidth.value + difference

  const minLeftWidth = getMinLeftWidth()

  if (
    newLeftWidth >= minLeftWidth &&
    newLeftWidth <= container.value?.clientWidth - props.minWidth - 10
  ) {
    leftWidth.value = newLeftWidth
  }
}

const stopDragging = () => {
  isDragging.value = false

  document.removeEventListener('mousemove', handleDragging)
  document.removeEventListener('mouseup', stopDragging)
  document.removeEventListener('touchmove', handleDragging)
  document.removeEventListener('touchend', stopDragging)
}

onMounted(() => {
  if (container.value) {
    const containerWidth = container.value.clientWidth
    leftWidth.value = (containerWidth * props.initialLeftPercentage) / 100 - 5
  }
})

onUnmounted(() => {
  document.removeEventListener('mousemove', handleDragging)
  document.removeEventListener('mouseup', stopDragging)
  document.removeEventListener('touchmove', handleDragging)
  document.removeEventListener('touchend', stopDragging)
})
</script>

<style scoped>
.resizable-container {
  display: flex;
  width: 100%;
  min-height: 100%;

  overflow: hidden;
}

.column {
  height: 100%;
  overflow: auto;
}

.separator {
  width: 10px;
  position: relative;
  cursor: col-resize;
  background-image: linear-gradient(to bottom, #9e9e9e 33%, transparent 33%);
  background-size: 1px 3px;
  background-position: center;
  background-repeat: repeat-y;
}

.drag-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.separator:hover .drag-indicator {
  background: #f5f5f5;
}

.separator:active .drag-indicator {
  background: #eeeeee;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
</style>
