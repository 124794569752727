import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import useIntegrationStore from './integration'
import resetFetchResult from '@/utils/resetFetchResult'
import type IntegrationField from '@/types/integrationField'
import executeApi from '@/utils/executeApi'

const useIntegrationFieldStore = defineStore('integrationField', () => {
  const api = useApi()

  const integrationFieldsUrl = ref('/project')
  const integrationFieldsApi = api<Array<IntegrationField>>(integrationFieldsUrl).get().json()
  const integrationFields: FetchResult<Array<IntegrationField>> =
    reactiveFetchResult(integrationFieldsApi)
  const listIntegrationFields = async (
    projectIntegrationId?: number,
    projectId?: number
  ): Promise<FetchResult<Array<IntegrationField>>> => {
    let projectIntegrationParam = projectIntegrationId
    if (!projectIntegrationParam) {
      const projectIntegrationStore = useIntegrationStore()
      projectIntegrationParam = projectIntegrationStore.integration.data!.id
    }
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    integrationFieldsUrl.value = `/project/${projectParam}/integration/${projectIntegrationParam}/integrationField`
    await executeApi(integrationFieldsApi)
    return integrationFields
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<IntegrationField>>(patchUrl).json()
  const patch: FetchResult<Array<IntegrationField>> = reactiveFetchResult(patchApi)
  const patchIntegrationFields = async (
    data: Array<Partial<IntegrationField>>,
    projectIntegrationId?: number,
    projectId?: number
  ): Promise<FetchResult<Array<IntegrationField>>> => {
    let projectIntegrationParam = projectIntegrationId
    if (!projectIntegrationParam) {
      const projectIntegrationStore = useIntegrationStore()
      projectIntegrationParam = projectIntegrationStore.integration.data!.id
    }
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectParam}/integration/${projectIntegrationParam}/integrationField`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const integrationFieldUrl = ref('/project')
  const integrationFieldApi = api<Array<IntegrationField>>(integrationFieldUrl).json()
  const integrationField: FetchResult<IntegrationField> = reactiveFetchResult(integrationFieldApi)
  const readIntegrationField = async (
    id?: number,
    projectIntegrationId?: number,
    projectId?: number
  ): Promise<FetchResult<IntegrationField>> => {
    let projectIntegrationParam = projectIntegrationId
    if (!projectIntegrationParam) {
      const projectIntegrationStore = useIntegrationStore()
      projectIntegrationParam = projectIntegrationStore.integration.data!.id
    }
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || integrationField.data?.id
    integrationFieldUrl.value = `/project/${projectParam}/integration/${projectIntegrationParam}/integrationField/${idParam}`
    await executeApi(integrationFieldApi.get())
    return integrationField
  }

  const createIntegrationField = async (
    data: Partial<IntegrationField>,
    projectIntegrationId?: number,
    projectId?: number
  ): Promise<FetchResult<IntegrationField>> => {
    let projectIntegrationParam = projectIntegrationId
    if (!projectIntegrationParam) {
      const projectIntegrationStore = useIntegrationStore()
      projectIntegrationParam = projectIntegrationStore.integration.data!.id
    }
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    integrationFieldUrl.value = `/project/${projectParam}/integration/${projectIntegrationParam}/integrationField`
    await executeApi(integrationFieldApi.post(data), true)
    return integrationField
  }

  const updateIntegrationField = async (
    data: Partial<IntegrationField>,
    id?: number,
    projectIntegrationId?: number,
    projectId?: number
  ): Promise<FetchResult<IntegrationField>> => {
    let projectIntegrationParam = projectIntegrationId
    if (!projectIntegrationParam) {
      const projectIntegrationStore = useIntegrationStore()
      projectIntegrationParam = projectIntegrationStore.integration.data!.id
    }
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || integrationField.data?.id
    integrationFieldUrl.value = `/project/${projectParam}/integration/${projectIntegrationParam}/integrationField/${idParam}`
    await executeApi(integrationFieldApi.put(data), true)
    return integrationField
  }

  const deleteIntegrationField = async (
    id?: number,
    projectIntegrationId?: number,
    projectId?: number
  ): Promise<FetchResult<IntegrationField>> => {
    let projectIntegrationParam = projectIntegrationId
    if (!projectIntegrationParam) {
      const projectIntegrationStore = useIntegrationStore()
      projectIntegrationParam = projectIntegrationStore.integration.data!.id
    }
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || integrationField.data?.id
    integrationFieldUrl.value = `/project/${projectParam}/integration/${projectIntegrationParam}/integrationField/${idParam}`
    await executeApi(integrationFieldApi.delete(), false)
    integrationField.data = null
    return integrationField
  }

  const $reset = () => {
    resetFetchResult(integrationFields)
    resetFetchResult(integrationField)
  }

  return {
    listIntegrationFields,
    readIntegrationField,
    createIntegrationField,
    updateIntegrationField,
    deleteIntegrationField,
    patchIntegrationFields,
    integrationFields,
    integrationField,
    patch,
    $reset
  }
})

export default useIntegrationFieldStore
