import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import type Client from '@/types/client'
import executeApi from '@/utils/executeApi'
import resetFetchResult from '@/utils/resetFetchResult'

const useClientStore = defineStore('client', () => {
  const api = useApi()

  const clientsUrl = ref('')
  const clientsApi = api<Array<Client>>(clientsUrl).get().json()
  const clients: FetchResult<Array<Client>> = reactiveFetchResult(clientsApi)
  const listClients = async (): Promise<FetchResult<Array<Client>>> => {
    clientsUrl.value = `/client`
    await executeApi(clientsApi)
    return clients
  }

  const clientUrl = ref('/client')
  const clientApi = api<Array<Client>>(clientUrl).json()
  const client: FetchResult<Client> = reactiveFetchResult(clientApi)

  const readClient = async (id?: number): Promise<FetchResult<Client>> => {
    const idParam = id || client.data?.id
    clientUrl.value = `/client/${idParam}`
    await executeApi(clientApi.get())
    return client
  }

  const createClient = async (data: Partial<Client>): Promise<FetchResult<Client>> => {
    clientUrl.value = `/client`
    await executeApi(clientApi.post(data), true)
    return client
  }

  const updateClient = async (data: Partial<Client>, id?: number): Promise<FetchResult<Client>> => {
    const idParam = id || client.data?.id
    clientUrl.value = `/client/${idParam}`
    await executeApi(clientApi.put(data), true)
    return client
  }

  const deleteClient = async (id?: number): Promise<FetchResult<Client>> => {
    const idParam = id || client.data?.id
    clientUrl.value = `/client/${idParam}`
    await executeApi(clientApi.delete(), false)
    client.data = null
    return client
  }

  const $reset = () => {
    resetFetchResult(clients)
    resetFetchResult(client)
  }

  return {
    listClients,
    readClient,
    createClient,
    updateClient,
    deleteClient,
    clients,
    client,
    $reset
  }
})

export default useClientStore
