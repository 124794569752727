import type { RouteRecordRaw } from 'vue-router'
import IndexView from '@/views/IndexView.vue'
import selectProjectRoute from './selectProject'
import addProjectRoute from './addProject'
import projectRoute from './project'
import clientRoute from './client'
import manageAssetsRoute from './manageAssets'

const routes: Readonly<Array<RouteRecordRaw>> = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
    children: [projectRoute, selectProjectRoute, addProjectRoute, clientRoute, manageAssetsRoute]
  }
]

export default routes
