<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <vis-text-field
          name="guid"
          :placeholder="$t('guid')"
          :label="$t('guid')"
          v-model="interiorOption.guid"
        />
      </v-col>
      <v-col cols="12">
        <vis-localized-input :input="interiorOption.title!" #default="{ input, locale }">
          <vis-text-field
            name="title"
            :placeholder="$t('title')"
            :label="$t('title')"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
      <v-col cols="12">
        <vis-localized-input :input="interiorOption.description!" #default="{ input, locale }">
          <vis-text-field
            name="description"
            :placeholder="$t('description')"
            :label="$t('description')"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import VisLocalizedInput from '../inputs/VisLocalizedInput.vue'
import type InteriorOption from '@/types/interiorOption'

const props = defineProps<{ interiorOption?: InteriorOption }>()
const emit = defineEmits<{
  submit: [interiorOption: Partial<InteriorOption>]
}>()

const interiorOption = reactive<Partial<InteriorOption>>(
  props.interiorOption || {
    title: {},
    description: {}
  }
)

const { formRef, submit } = useFormPartials(interiorOption, (partial: Partial<InteriorOption>) =>
  emit('submit', partial)
)
</script>
