import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Contact from '@/types/contact'
import executeApi from '@/utils/executeApi'

const useContactStore = defineStore('contact', () => {
  const api = useApi()

  const contactsUrl = ref('/project')
  const contactsApi = api<Array<Contact>>(contactsUrl).get().json()
  const contacts: FetchResult<Array<Contact>> = reactiveFetchResult(contactsApi)
  const listContacts = async (projectId?: number): Promise<FetchResult<Array<Contact>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    contactsUrl.value = `/project/${projectParam}/contact`
    await executeApi(contactsApi)
    return contacts
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<Contact>>(patchUrl).json()
  const patch: FetchResult<Array<Contact>> = reactiveFetchResult(patchApi)
  const patchContacts = async (
    data: Array<Partial<Contact>>,
    projectId?: number
  ): Promise<FetchResult<Array<Contact>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/contact`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const contactUrl = ref('/project')
  const contactApi = api<Array<Contact>>(contactUrl).json()
  const contact: FetchResult<Contact> = reactiveFetchResult(contactApi)

  const readContact = async (id?: number, projectId?: number): Promise<FetchResult<Contact>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || contact.data?.id
    contactUrl.value = `/project/${projectParam}/contact/${idParam}`
    await executeApi(contactApi.get())
    return contact
  }

  const createContact = async (
    data: Partial<Contact>,
    projectId?: number
  ): Promise<FetchResult<Contact>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    contactUrl.value = `/project/${projectParam}/contact`
    await executeApi(contactApi.post(data), true)
    return contact
  }

  const updateContact = async (
    data: Partial<Contact>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Contact>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || contact.data?.id
    contactUrl.value = `/project/${projectParam}/contact/${idParam}`
    await executeApi(contactApi.put(data), true)
    return contact
  }

  const deleteContact = async (id?: number, projectId?: number): Promise<FetchResult<Contact>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || contact.data?.id
    contactUrl.value = `/project/${projectParam}/contact/${idParam}`
    await executeApi(contactApi.delete(), false)
    contact.data = null
    return contact
  }

  const $reset = () => {
    resetFetchResult(contacts)
    resetFetchResult(contact)
  }

  return {
    listContacts,
    readContact,
    createContact,
    updateContact,
    deleteContact,
    patchContacts,
    contacts,
    contact,
    patch,
    $reset
  }
})

export default useContactStore
