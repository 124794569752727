<template>
  <vis-loader :loading="integrationField.isFetching || fields.isFetching">
    <vis-sheet>
      <h4>{{ $t('updateWebhookIntegrationField') }}</h4>
      <webhook-integration-field-form
        :fields="allFields"
        :integrationField="integrationField.data!"
        @submit="onSubmit"
      />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import useIntegrationFieldStore from '@/stores/integrationField'
import WebhookIntegrationFieldForm from '@/components/forms/WebhookIntegrationFieldForm.vue'
import useFieldStore from '@/stores/field'
import type IntegrationField from '@/types/integrationField'

const {
  integrationField,
  integrationFields,
  listIntegrationFields,
  readIntegrationField,
  updateIntegrationField
} = useIntegrationFieldStore()
const { fields, listFields } = useFieldStore()
fields.data || listFields()

const route = useRoute()

const allFields = computed(() => {
  if (fields.data && integrationFields.data) {
    const usedIds = integrationFields.data.map((f) => f.projectFieldId)
    return fields.data.filter(
      (f) => !usedIds.includes(f.id!) || f.id === integrationField.data!.projectFieldId
    )
  } else {
    return []
  }
})

async function onSubmit(data: Partial<IntegrationField>) {
  await updateIntegrationField(data)
  await listIntegrationFields()
}

watch(
  [() => route.params.integrationId, () => route.params.integrationFieldId],
  () => {
    if (route.params.integrationId && route.params.integrationFieldId) {
      readIntegrationField(
        parseInt(route.params.integrationFieldId as string),
        parseInt(route.params.integrationId as string)
      )
    }
  },
  { immediate: true }
)
</script>
