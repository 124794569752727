import type { RouteRecordRaw } from 'vue-router'
import ImportView from '@/views/project/tools/ImportView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const importRoute: RouteRecordRaw = {
  path: 'import',
  name: 'import',
  component: ImportView,
  meta: {
    breadcrumb: 'import',
    menu: {
      key: 'import',
      icon: 'mdi-application-import',
      name: 'import'
    },
    scopes: [KeycloakScope.PROJECT_IMPORT]
  }
}

export default importRoute
