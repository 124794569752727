import type { RouteRecordRaw } from 'vue-router'
import DashboardView from '@/views/project/DashboardView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const dashboardRoute: RouteRecordRaw = {
  path: '/',
  name: 'dashboard',
  component: DashboardView,
  meta: {
    menu: {
      key: 'dashboard',
      icon: 'mdi-gauge',
      name: 'dashboard',
      exact: true
    },
    scopes: [KeycloakScope.PROJECT_READ]
  }
}

export default dashboardRoute
