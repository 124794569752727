<template>
  <template v-if="hasAccess()">
    <slot></slot>
  </template>
  <template v-else></template>
</template>

<script setup lang="ts">
import useAuthStore from '@/stores/auth'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import scopeCheck from '@/utils/scopeCheck'

const { scopes } = defineProps<{
  scopes: KeycloakScope | Array<KeycloakScope> | string | Array<string>
}>()

const authStore = useAuthStore()
const hasAccess = () => {
  let checks: Array<KeycloakScope> = (
    Array.isArray(scopes) ? scopes : [scopes]
  ) as Array<KeycloakScope>
  return scopeCheck(authStore.scopes, ...checks)
}
</script>
