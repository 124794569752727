import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import resetFetchResult from '@/utils/resetFetchResult'
import type Analytics from '@/types/analytics'
import useProjectStore from './project'
import executeApi from '@/utils/executeApi'

const useAnalyticsStore = defineStore('analytics', () => {
  const api = useApi()

  const analyticsUrl = ref('/project')
  const analyticsApi = api<Analytics>(analyticsUrl).get().json()
  const analytics: FetchResult<Analytics> = reactiveFetchResult(analyticsApi)
  const listAnalytics = async (projectId?: number): Promise<FetchResult<Analytics>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    analyticsUrl.value = `/project/${projectIdParam}/analytics`
    await executeApi(analyticsApi)
    return analytics
  }

  const $reset = () => resetFetchResult(analytics)

  return {
    listAnalytics,
    analytics,
    $reset
  }
})

export default useAnalyticsStore
