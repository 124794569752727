import type { RouteRecordRaw } from 'vue-router'
import WebhookIntegrationListView from '@/views/project/integrations/webhookIntegrations/WebhookIntegrationListView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import createWebhookIntegrationRoute from './createWebIntegration'
import updateWebhookIntegrationRoute from './updateWebhookIntegration'

const webhookIntegrationRoute: RouteRecordRaw = {
  path: 'webhookIntegrations',
  name: 'webhookIntegrations',
  component: WebhookIntegrationListView,
  meta: {
    breadcrumb: 'webhookIntegrations',
    menu: {
      key: 'webhookIntegrations',
      icon: 'mdi-web-sync',
      name: 'webhookIntegrations'
    },
    scopes: [KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  },
  children: [createWebhookIntegrationRoute, updateWebhookIntegrationRoute]
}

export default webhookIntegrationRoute
