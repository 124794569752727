import type { RouteRecordRaw } from 'vue-router'
import ExportView from '@/views/project/tools/ExportView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const exportRoute: RouteRecordRaw = {
  path: '',
  name: 'export',
  component: ExportView,
  meta: {
    breadcrumb: 'export',
    menu: {
      key: 'export',
      icon: 'mdi-application-export',
      name: 'export',
      exact: true
    },
    scopes: [KeycloakScope.PROJECT_IMPORT]
  }
}

export default exportRoute
