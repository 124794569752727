<template>
  <div>
    <div v-if="isLoading" class="loading">Loading...</div>
    <img
      v-else-if="imagePreviewUrl"
      :src="imagePreviewUrl"
      :alt="alt"
      :style="style"
      class="image-preview"
    />

    <div v-else-if="error" class="error">
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import { useImagePreview } from './useImagePreview'
import useAuthStore from '@/stores/auth'

interface Props {
  src: string
  alt?: string
  height?: string
  width?: string
}

const props = withDefaults(defineProps<Props>(), {
  alt: '',
  height: '200px',
  width: '200px'
})

const authHeaders = computed(() => {
  return {
    Authorization: `Bearer ${useAuthStore().token}`
  }
})

const style = computed(() => {
  return {
    height: props.height,
    width: props.width
  }
})

const { imagePreviewUrl, error, isLoading, fetchImagePreview } = useImagePreview(props.src, {
  headers: authHeaders.value
})

onMounted(() => {
  fetchImagePreview()
})

onUnmounted(() => {
  if (imagePreviewUrl.value) {
    URL.revokeObjectURL(imagePreviewUrl.value)
  }
})
</script>

<style scoped>
.loading,
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.error {
  color: red;
}
</style>
