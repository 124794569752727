import type { RouteRecordRaw } from 'vue-router'
import WebhookIntegrationFieldUpdateView from '@/views/project/integrations/webhookIntegrations/webhookIntegrationFields/WebhookIntegrationFieldUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateWebhookIntegrationFieldRoute: RouteRecordRaw = {
  path: ':integrationFieldId',
  name: 'updateWebhookIntegrationField',
  component: WebhookIntegrationFieldUpdateView,
  meta: {
    breadcrumb: 'updateWebhookIntegrationField',
    scopes: [KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  }
}

export default updateWebhookIntegrationFieldRoute
