<template>
  <vis-loader :loading="client.isFetching">
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <client-form :client="client.data!" @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useClientStore from '@/stores/client'
import ClientForm from '@/components/forms/ClientForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { client, listClients, readClient, updateClient } = useClientStore()
const onSubmit = useSubmitThenRoute(updateClient, { name: 'clients' }, () => listClients())

const clientId = useRouteId('clientId')
readClient(clientId)
</script>
