import { ref, type Ref, watch } from 'vue'

export default function usePropChange<T extends { [key: string]: any }, U extends keyof T>(
  props: T,
  key: U
): Ref<T[U]> {
  const refObject = ref(props[key]) as Ref<T[U]>
  watch(
    () => props[key],
    () => {
      refObject.value = props.items
    }
  )
  return refObject
}
