<template>
  <vis-data-table :headers="columns" :items="contacts" @click:row="onRowClick">
    <template #top>
      <vis-sheet v-if="hasAddEventListener" class="text-end">
        <v-tooltip location="left">
          <template #activator="{ props }">
            <vis-add-icon-btn v-bind="props" @click="emit('add')" />
          </template>
          {{ $t('create') }}
        </v-tooltip>
      </vis-sheet>
    </template>
    <template #bottom />
    <template #body="{ items }">
      <template v-if="contacts.length > 0">
        <draggable
          :list="[...items].sort((a, b) => a.order - b.order)"
          item-key="id"
          tag="tbody"
          @change="onReorder"
        >
          <template #item="{ element: contact }: { element: Contact }">
            <tr class="v-data-table__tr v-data-table__tr--clickable" @click="onRowClick(contact)">
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="number" :project="project" :value="contact.id" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="id" :project="project" :value="contact.name" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="id" :project="project" :value="contact.email" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="id" :project="project" :value="contact.phone" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="date"
                  :project="project"
                  :value="contact.createdAt"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="date"
                  :project="project"
                  :value="contact.updatedAt"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip v-if="hasDeleteEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(contact)" />
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasReorderEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-drag-icon-btn v-bind="props" />
                  </template>
                  {{ $t('dragToReorder') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div></template
      >
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import Draggable from 'vuedraggable'
import { computed, getCurrentInstance, onMounted } from 'vue'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import type Contact from '@/types/contact'
import type Project from '@/types/project'
import { useI18n } from 'vue-i18n'

const { contacts } = defineProps<{
  project: Project
  contacts: Array<Contact>
}>()

const emit = defineEmits<{
  add: []
  delete: [contact: Contact]
  select: [contact: Contact]
  reorder: [reordered: Array<Partial<Contact>>]
}>()

defineSlots<{
  top(props: {}): any
}>()

const hasAddEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onAdd)
const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)
const hasReorderEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onReorder)

const { t } = useI18n()

const columns = [
  { title: t('id'), key: 'id', sortable: false },
  { title: t('name'), key: 'name', sortable: false },
  { title: t('email'), key: 'email', sortable: false },
  { title: t('phone'), key: 'phone', sortable: false },
  { title: t('createdAt'), key: 'createdAt', sortable: false },
  { title: t('updatedAt'), key: 'updatedAt', sortable: false },
  { title: '', key: 'actions', sortable: false }
]

function onRowDelete(contact: Contact) {
  emit('delete', contact)
}

function onRowClick(contact: Contact) {
  emit('select', contact)
}

const onReorder = (event: Moved<Contact>) => {
  const copy = [...contacts].sort((a, b) => a.order - b.order)
  const left = copy[event.moved.oldIndex]
  const right = copy[event.moved.newIndex]
  copy[event.moved.oldIndex] = right
  copy[event.moved.newIndex] = left
  copy.forEach((c, i) => {
    c.order = i
  })
  emit('reorder', copy)
}

onMounted(() => {
  if (contacts.length > 0) {
    const sortableTbody = document.querySelector('.v-table__wrapper > table > tbody > tbody')
    const tbody = document.querySelector('.v-table__wrapper > table > tbody')
    tbody!.parentNode!.append(sortableTbody!)
    tbody!.remove()
  }
})
</script>
