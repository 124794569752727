import { ref } from 'vue'

interface UseImagePreviewOptions {
  headers?: Record<string, string>
}

export function useImagePreview(endpoint: string, options: UseImagePreviewOptions = {}) {
  const imagePreviewUrl = ref<string | null>(null)
  const error = ref<string | null>(null)
  const isLoading = ref(false)

  const fetchImagePreview = async () => {
    if (!endpoint) return

    isLoading.value = true
    error.value = null

    try {
      const response = await fetch(endpoint, {
        method: 'GET',
        headers: {
          ...options.headers
        }
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const blob = await response.blob()
      // Revoke previous blob URL if exists
      if (imagePreviewUrl.value) {
        URL.revokeObjectURL(imagePreviewUrl.value)
      }

      imagePreviewUrl.value = URL.createObjectURL(blob)
    } catch (err) {
      error.value = err instanceof Error ? err.message : 'An unknown error occurred'
      console.error('Error fetching image:', err)
    } finally {
      isLoading.value = false
    }
  }

  return {
    imagePreviewUrl,
    error,
    isLoading,
    fetchImagePreview
  }
}
