<template>
  <v-col cols="4">
    <vis-loader :loading="integration.isFetching">
      <vis-sheet>
        <h4>{{ $t('updateWebhookIntegration') }}</h4>
        <webhook-integration-form :integration="integration.data!" @submit="onSubmit" />
      </vis-sheet>
    </vis-loader>
  </v-col>
  <v-col cols="4">
    <vis-sheet
      ><h4>
        {{ $t('webhookIntegrationFields') }}
        <v-tooltip location="left">
          <template #activator="{ props }">
            <vis-add-icon-btn
              style="float: right"
              v-bind="props"
              @click="() => router.push({ name: 'createWebhookIntegrationField' })"
            />
          </template>
          {{ $t('create') }}
        </v-tooltip>
      </h4>
      <vis-loader :loading="integrationFields.isFetching || fields.isFetching">
        <data-table
          :columns="columns"
          :items="integrationFields.data! || []"
          @select="onSelect"
          @delete="onDelete"
        >
          <template #top>
            <h4>{{ $t('webhookIntegrationFields') }}</h4>
          </template>
        </data-table>
      </vis-loader>
    </vis-sheet>
  </v-col>
  <v-col cols="4">
    <vis-loader :loading="integration.isFetching">
      <router-view />
    </vis-loader>
  </v-col>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import useIntegrationStore from '@/stores/integration'
import WebhookIntegrationForm from '@/components/forms/WebhookIntegrationForm.vue'
import DataTable from '@/components/dataTable/DataTable.vue'
import useIntegrationFieldTableDefinitions from '@/composables/tableDefinitions/useIntegrationFieldTableDefinitions'
import useFieldStore from '@/stores/field'
import useIntegrationFieldStore from '@/stores/integrationField'
import type Integration from '@/types/integration'
import type IntegrationField from '@/types/integrationField'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { integrationFields, listIntegrationFields, deleteIntegrationField } =
  useIntegrationFieldStore()
const { integration, listIntegrations, readIntegration, updateIntegration } = useIntegrationStore()
const { fields, listFields } = useFieldStore()
fields.data || listFields()

async function onSubmit(data: Partial<Integration>) {
  await updateIntegration(data)
  await listIntegrations()
}

async function onSelect(data: Partial<IntegrationField>) {
  router.push({
    name: 'updateWebhookIntegrationField',
    params: { integrationFieldId: data.id }
  })
}

async function onDelete(data: Partial<IntegrationField>) {
  if (confirm(t('areYouSureYouWantToDeleteTheIntegrationField'))) {
    router.push({
      name: 'updateWebhookIntegration',
      params: { integrationId: data.projectIntegrationId }
    })
    await deleteIntegrationField(data.id)
    await listIntegrationFields()
  }
}

const columns = computed(() => useIntegrationFieldTableDefinitions(fields.data || []))

watch(
  () => route.params.integrationId,
  () => {
    if (route.params.integrationId) {
      const integrationId = parseInt(route.params.integrationId as string)
      readIntegration(integrationId)
      listIntegrationFields(integrationId)
    }
  },
  { immediate: true }
)
</script>
