<template>
  <vis-data-table :headers="columns" :items="statuses" :must-sort="false">
    <template #top>
      <vis-sheet v-if="hasAddEventListener" class="text-end">
        <v-tooltip text="Tooltip" location="left">
          <template #activator="{ props }">
            <vis-add-icon-btn v-bind="props" @click="emit('add')" />
          </template>
          {{ $t('create') }}
        </v-tooltip>
      </vis-sheet>
    </template>
    <template #bottom />
    <template #body="{ items }">
      <template v-if="statuses.length > 0">
        <draggable
          :list="[...items].sort((a, b) => a.order - b.order)"
          item-key="id"
          tag="tbody"
          @change="onReorder"
        >
          <template #item="{ element: status }: { element: Status }">
            <tr class="v-data-table__tr v-data-table__tr--clickable" @click="onRowClick(status)">
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="number" :project="project" :value="status.id" />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer type="string" :project="project" :value="status.label" />
              </td>
              <td
                style="white-space: nowrap"
                class="v-data-table__td v-data-table-column--align-start"
              >
                <v-tooltip right>
                  <template #activator="{ props }">
                    <div
                      class="swatch"
                      :style="`background-color: ${status.textColor}`"
                      v-bind="props"
                    ></div>
                  </template>
                  <span>{{ $t('textColor') }} </span>
                </v-tooltip>
                &nbsp;/&nbsp;
                <v-tooltip right>
                  <template #activator="{ props }">
                    <div
                      class="swatch"
                      :style="`background-color: ${status.idleColor}`"
                      v-bind="props"
                    ></div>
                  </template>
                  <span>{{ $t('idleColor') }} </span>
                </v-tooltip>
                &nbsp;/&nbsp;
                <v-tooltip right>
                  <template #activator="{ props }">
                    <div
                      class="swatch"
                      :style="`background-color: ${status.highlightColor}`"
                      v-bind="props"
                    ></div>
                  </template>
                  <span>{{ $t('highlightColor') }} </span>
                </v-tooltip>
                &nbsp;/&nbsp;
                <v-tooltip right>
                  <template #activator="{ props }">
                    <div
                      class="swatch"
                      :style="`background-color: ${status.activeColor}`"
                      v-bind="props"
                    ></div>
                  </template>
                  <span>{{ $t('activeColor') }} </span>
                </v-tooltip>
                &nbsp;/&nbsp;
                <v-tooltip right>
                  <template #activator="{ props }">
                    <div
                      class="swatch"
                      :style="`background-color: ${status.inactiveColor}`"
                      v-bind="props"
                    ></div>
                  </template>
                  <span>{{ $t('inactiveColor') }} </span>
                </v-tooltip>
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="boolean"
                  :project="project"
                  :value="status.isSold"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="boolean"
                  :project="project"
                  :value="status.hidePage"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="boolean"
                  :project="project"
                  :value="status.isFilteredByDefault"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="boolean"
                  :project="project"
                  :value="status.persistentBuildingColor"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-start">
                <data-table-cell-renderer
                  type="boolean"
                  :project="project"
                  :value="status.persistentUnitColor"
                />
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip v-if="hasDeleteEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(status)" />
                  </template>
                  {{ $t('delete') }}
                </v-tooltip>
                &nbsp;
                <v-tooltip v-if="hasReorderEventListener" location="left">
                  <template #activator="{ props }">
                    <vis-drag-icon-btn v-bind="props" />
                  </template>
                  {{ $t('dragToReorder') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div></template
      >
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import { computed, getCurrentInstance, onMounted } from 'vue'
import Draggable from 'vuedraggable'
import { useI18n } from 'vue-i18n'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import type Status from '@/types/status'
import type Project from '@/types/project'

const { statuses } = defineProps<{
  project: Project
  statuses: Array<Status>
}>()

const emit = defineEmits<{
  add: []
  delete: [status: Status]
  select: [status: Status]
  reorder: [reordered: Array<Partial<Status>>]
}>()

defineSlots<{
  top(props: {}): any
}>()

const hasAddEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onAdd)
const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)
const hasReorderEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onReorder)

const { t } = useI18n()

const columns = [
  { title: t('id'), key: 'id', sortable: false },
  { title: t('label'), key: 'label', sortable: false },
  { title: t('colors'), key: 'colors', sortable: false },
  { title: t('isSold'), key: 'isSold', sortable: false },
  { title: t('hidePages'), key: 'hidePages', sortable: false },
  { title: t('isFilteredByDefault'), key: 'isFilteredByDefault', sortable: false },
  { title: t('persistentBuildingColor'), key: 'persistentBuildingColor', sortable: false },
  { title: t('persistentUnitColor'), key: 'persistentUnitColor', sortable: false },
  { title: '', key: 'actions', sortable: false }
]

function onRowDelete(status: Status) {
  emit('delete', status)
}

function onRowClick(status: Status) {
  emit('select', status)
}

const onReorder = (event: Moved<Status>) => {
  const copy = [...statuses].sort((a, b) => a.order - b.order)
  const left = copy[event.moved.oldIndex]
  const right = copy[event.moved.newIndex]
  copy[event.moved.oldIndex] = right
  copy[event.moved.newIndex] = left
  copy.forEach((c, i) => {
    c.order = i
  })
  emit('reorder', copy)
}

onMounted(() => {
  const sortableTbody = document.querySelector('.v-table__wrapper > table > tbody > tbody')
  const tbody = document.querySelector('.v-table__wrapper > table > tbody')
  tbody!.parentNode!.append(sortableTbody!)
  tbody!.remove()
})
</script>

<style scoped>
:deep(.swatch) {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  display: inline-block;
}
</style>
