<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <h4>
          <vis-switch
            v-if="emailValid"
            class="d-inline-block vertical-align-middle"
            v-model="integrations.email!.enabled"
          />
          <vis-switch
            v-else
            class="d-inline-block vertical-align-middle"
            value="false"
            disabled
          />&nbsp;{{ $t('emailIntegration') }}
        </h4>
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          v-model="integrations.email!.subject"
          :label="$t('emailSubject')"
          :hide-details="false"
          :messages="[$t('thisFieldIsRequiredToEnableTheIntegration')]"
        />
      </v-col>
      <v-col v-for="(_, i) of integrations.email!.address" :key="i" sm="6" cols="12">
        <vis-text-field
          v-model="integrations.email!.address![i]"
          autocomplete="off"
          :hide-details="i > 0"
          :messages="i === 0 ? [$t('thisFieldIsRequiredToEnableTheIntegration')] : []"
          :rules="
            integrations.email!.address![i]
              ? [
                  /*validations.email*/
                ]
              : []
          "
          :label="i === 0 ? $t('emailAddress') : $t('additionalEmailAddresses')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h4>
          <vis-switch
            v-if="hubspotValid"
            class="d-inline-block vertical-align-middle"
            v-model="integrations.hubspot!.enabled"
          />
          <vis-switch
            v-else
            class="d-inline-block vertical-align-middle"
            value="false"
            disabled
          />&nbsp;{{ $t('hubspotIntegration') }}
        </h4>
      </v-col>
      <v-col cols="12">
        <v-alert type="info">
          <p>{{ $t('hubspotIntegrationGuidelines') }}:</p>
          <ul style="list-style-position: inside">
            <li>
              {{ $t('theApiKeyShouldHaveAtLeastTheFollowingScopes') }}:
              <pre>crm.objects.contacts.read</pre>
              ,
              <pre>crm.objects.contacts.write</pre>
              ,
              <pre>crm.objects.lists.write</pre>
              ,
              <pre>crm.objects.lists.write</pre>
              {{ $t('and') }}
              <pre>tickets</pre>
            </li>
            <li>
              {{ $t('theListIdFieldIsUsedToAddContactsToASpecificStaticList') }}
            </li>
            <li>
              {{ $t('theProjectBuildingAndUnitFieldsAreUsedInHubspotTickets') }}
            </li>
            <li>{{ $t('ticketsAreAssociatedWithUsers') }}</li>
          </ul>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <vis-text-field
          v-model="integrations.hubspot!.apiKey"
          autocomplete="off"
          :hide-details="false"
          :messages="[$t('thisFieldIsRequiredToEnableTheIntegration')]"
          :type="showApiKey ? 'text' : 'password'"
          :append-icon="showApiKey ? 'mdi-eye' : 'mdi-eye-off'"
          :prepend-inner-icon="isStoredApiKey ? 'mdi-content-save' : undefined"
          :label="$t('hubspotApiKey')"
          @click:append="showApiKey = !showApiKey"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          v-model="integrations.hubspot!.listIdField"
          :label="$t('hubspotListIdField')"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          v-model="integrations.hubspot!.projectField"
          :label="$t('hubspotProjectField')"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field
          v-model="integrations.hubspot!.buildingField"
          :label="$t('hubspotBuildingField')"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field v-model="integrations.hubspot!.unitField" :label="$t('hubspotUnitField')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type { CrmIntegrationSettings } from '@/types/project'
import type Project from '@/types/project'

const props = defineProps<{ project?: Project }>()
const emit = defineEmits<{
  submit: [project: Partial<Project>]
}>()

const project = reactive<Partial<Project>>(props.project || {})
const integrations: CrmIntegrationSettings = reactive({
  email: {
    enabled: false,
    address: [],
    ...project.crmIntegrations?.email
  },
  hubspot: {
    enabled: false,
    ...project.crmIntegrations?.hubspot
  }
})

watch(
  integrations,
  () => {
    if (
      integrations.email!.address!.length !==
      integrations.email!.address!.filter((e) => !!e.trim()).length + 1
    ) {
      integrations.email!.address = [...integrations.email!.address!.filter((e) => !!e.trim()), '']
    }
    project.crmIntegrations = integrations
  },
  { immediate: true, deep: true }
)

const isStoredApiKey = ref<boolean>(!!integrations.hubspot?.apiKey)
const showApiKey = ref<boolean>(false)

const emailValid = computed(
  () =>
    integrations.email &&
    integrations.email.address &&
    integrations.email.address.map((e) => e.trim()).filter((e) => !!e).length > 0 &&
    integrations.email.subject
)

const hubspotValid = computed(
  () => integrations.hubspot && (isStoredApiKey.value || integrations.hubspot.apiKey)
)

const { formRef, submit } = useFormPartials(project, () => {
  emit('submit', { crmIntegrations: integrations })
})
</script>

<style scoped>
pre {
  display: inline-block;
  background: lightgray;
  padding: 0 4px;
  border-radius: 4px;
}
</style>
