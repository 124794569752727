import type { UseFetchReturn } from '@vueuse/core'
import { reactive } from 'vue'

export default function reactiveFetchResult<T>(
  useFetchReturn: UseFetchReturn<T> & PromiseLike<UseFetchReturn<T>>
): FetchResult<T> {
  return reactive({
    data: useFetchReturn.data,
    isFetching: useFetchReturn.isFetching,
    isFinished: useFetchReturn.isFinished,
    error: useFetchReturn.error
  })
}
