import type { RouteRecordRaw } from 'vue-router'
import InteriorOptionUpdateView from '@/views/project/renderSettings/interiorOptions/InteriorOptionUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateInteriorOptionRoute: RouteRecordRaw = {
  path: ':interiorOptionId',
  name: 'updateInteriorOption',
  component: InteriorOptionUpdateView,
  meta: {
    breadcrumb: 'updateInteriorOption',
    scopes: [KeycloakScope.ASSETS_UPDATE]
  }
}

export default updateInteriorOptionRoute
