<template>
  <v-row>
    <v-col cols="12">
      <div>
        <div class="d-flex">
          <v-combobox
            v-if="allFilterResourceType.length > 0"
            clearable
            chips
            closableChips
            multiple
            label="Resource Type"
            :items="artistResourceResourceType"
            v-model="filterItems.resourceType"
          ></v-combobox>

          <v-combobox
            v-if="allFilterType.length > 0"
            clearable
            chips
            closableChips
            multiple
            label="Type"
            :items="allFilterType"
            v-model="filterItems.type"
          ></v-combobox>

          <v-combobox
            v-if="allFilterCategory.length > 0"
            clearable
            chips
            closableChips
            multiple
            label="Category"
            :items="allFilterCategory"
            v-model="filterItems.category"
          ></v-combobox>

          <v-combobox
            v-if="allFilterPossibleRooms.length > 0"
            clearable
            chips
            closableChips
            multiple
            label="Possible Rooms"
            :items="allFilterPossibleRooms"
            v-model="filterItems.possibleRooms"
          ></v-combobox>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="8">
      <div>
        <v-pagination
          v-if="filteredArtistResources?.length > 0"
          v-model="currentPage"
          :length="totalPages"
          :total-visible="5"
          @next="nextPage"
          @previous="previousPage"
        ></v-pagination>

        <v-row v-if="filteredArtistResources?.length" dense>
          <v-col v-for="resource in itemsToDisplay" :key="resource.id" sm="6" md="4" lg="3" xl="2">
            <ArtistResourceCard
              :artist-resource="resource"
              @select="$emit('OnSelect', resource)"
              @click="updatePreview(resource)"
              :key="resource.id"
            ></ArtistResourceCard>
          </v-col>
        </v-row>

        <v-pagination
          v-if="filteredArtistResources?.length > 0"
          v-model="currentPage"
          :length="totalPages"
          :total-visible="8"
          @next="nextPage"
          @previous="previousPage"
        ></v-pagination>
      </div>
    </v-col>
    <v-col cols="4">
      <ArtistResourcePreview
        v-if="previewArtistResource"
        :key="previewArtistResource?.id"
        :artist-resource="previewArtistResource"
        @onSelect="$emit('OnSelect', previewArtistResource)"
        @selectVariant="selectVariant"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, type ComputedRef, type Ref, watch } from 'vue'
import _ from 'lodash'
import type ArtistResource from '@/types/artistResource'
import ArtistResourcePreview from './ArtistResourcePreview.vue'
import ArtistResourceCard from './ArtistResourceCard.vue'

const artistResourceResourceType = ['asset', 'asset-group', 'material', 'lightbulb']

const filterItems: Ref<{ [key: string]: Array<string> }> = ref({
  resourceType: [],
  type: [],
  category: [],
  possibleRooms: []
})

const props = defineProps<{
  artistResources?: Array<ArtistResource>
  selectedResourceId?: string
  selectedResourceVariantId?: string | null
  defaultFilter?: Array<string>
}>()
const emits = defineEmits(['OnSelect', 'OnClicked'])

const previewArtistResource: Ref<ArtistResource | undefined> = ref(undefined)

onMounted(() => {
  if (props.selectedResourceId) {
    previewArtistResource.value = props.artistResources?.find(
      (resource: ArtistResource) => resource.publicId === (props.selectedResourceId as string)
    )
  }
})

const flattenedArtistResources: ComputedRef<any> = computed(() => {
  if (!props.artistResources) return []

  const sortedArtistResources = _.orderBy(props.artistResources, 'publicId', 'asc')

  return sortedArtistResources.map((resource) => {
    return { ...resource, ...resource.metadata }
  })
})

const allFilterResourceType = computed(() => {
  return _.uniq(props.artistResources?.map((resource: any) => resource.resourceType))
})

const allFilterType = computed(() => {
  return _.sortBy(
    _.compact(_.uniq(props.artistResources?.map((resource: any) => resource.metadata.type)))
  )
})

const allFilterCategory = computed(() => {
  const allCategory = _.uniq(
    props.artistResources?.map((resource: any) => resource.metadata.category)
  )

  const allCategories = _.uniq(
    _.flatten(props.artistResources?.map((resource: any) => resource.metadata.categories))
  )

  return _.sortBy(_.compact(_.uniq([...allCategory, ...allCategories])))
})

const allFilterPossibleRooms = computed(() => {
  const rooms = props.artistResources?.map((resource: any) => resource.metadata.possibleRooms)
  return _.sortBy(_.compact(_.uniq(_.flatten(rooms))))
})

const filterResourceTypes = ref<Array<string>>(artistResourceResourceType)

const containsAny = (arr1: Array<string>, arr2: Array<string>) =>
  arr1.some((item) => arr2.includes(item))

const includesValue = (val: any, obj: any) => _.some(obj, (v) => _.includes(v, val))

const filteredArtistResources: ComputedRef<ArtistResource[]> = computed(() => {
  if (!flattenedArtistResources.value) return []

  let filtered = flattenedArtistResources.value

  if (filterItems.value.resourceType.length) {
    filtered = filtered.filter((resource: any) => {
      return filterItems.value.resourceType.includes(resource.resourceType)
    })
  }

  if (filterItems.value.type.length) {
    filtered = filtered.filter((resource: any) => {
      return filterItems.value.type.includes(resource.metadata.type)
    })
  }

  if (filterItems.value.category.length) {
    filtered = filtered.filter((resource: any) => {
      if (resource.metadata.category) {
        return filterItems.value.category.includes(resource.metadata.category)
      }

      if (resource.metadata.categories) {
        const resourceCategories = resource.metadata.categories
        return containsAny(filterItems.value.category, resourceCategories)
      }

      return false
    })
  }

  if (filterItems.value.possibleRooms.length) {
    filtered = filtered.filter((resource: any) => {
      if (!resource.metadata.possibleRooms) {
        return filterItems.value.possibleRooms.includes(resource.roomType)
      }

      return includesValue(resource.metadata.possibleRooms, filterItems.value.possibleRooms)
    })
  }

  return filtered
})

const ITEMS_PER_PAGE = 36
const currentPage = ref(1)

const totalPages = computed(() => {
  return Math.ceil(filteredArtistResources.value.length / ITEMS_PER_PAGE)
})

watch(filteredArtistResources, () => {
  currentPage.value = 1
})

const nextPage = () => {
  currentPage.value = currentPage.value + 1 < totalPages.value ? currentPage.value + 1 : 1
}

const previousPage = () => {
  currentPage.value = currentPage.value - 1 > 0 ? currentPage.value - 1 : totalPages.value
}

const itemsToDisplay = computed(() => {
  return filteredArtistResources.value.slice(
    (currentPage.value - 1) * ITEMS_PER_PAGE,
    currentPage.value * ITEMS_PER_PAGE
  )
})

const updatePreview = (resource: ArtistResource) => {
  previewArtistResource.value = resource
  emits('OnClicked', resource)
}

const selectVariant = (variant: any) => {
  console.log('Select variant', variant)
  emits('OnSelect', previewArtistResource.value, variant)
}
</script>
<style scoped>
.artist-resource-list-item {
  width: 320px;
}
.single-filter {
  display: inline-block;
}
</style>
