import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Field from '@/types/field'
import executeApi from '@/utils/executeApi'

const useFieldStore = defineStore('field', () => {
  const api = useApi()

  const fieldsUrl = ref('/project')
  const fieldsApi = api<Array<Field>>(fieldsUrl).get().json()
  const fields: FetchResult<Array<Field>> = reactiveFetchResult(fieldsApi)
  const listFields = async (projectId?: number): Promise<FetchResult<Array<Field>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    fieldsUrl.value = `/project/${projectParam}/field`
    await executeApi(fieldsApi)
    return fields
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<Field>>(patchUrl).json()
  const patch: FetchResult<Array<Field>> = reactiveFetchResult(patchApi)
  const patchFields = async (
    data: Array<Partial<Field>>,
    projectId?: number
  ): Promise<FetchResult<Array<Field>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/field`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const fieldUrl = ref('/project')
  const fieldApi = api<Array<Field>>(fieldUrl).json()
  const field: FetchResult<Field> = reactiveFetchResult(fieldApi)
  const readField = async (id?: number, projectId?: number): Promise<FetchResult<Field>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || field.data?.id
    fieldUrl.value = `/project/${projectParam}/field/${idParam}`
    await executeApi(fieldApi.get(), true)
    return field
  }

  const createField = async (
    data: Partial<Field>,
    projectId?: number
  ): Promise<FetchResult<Field>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    fieldUrl.value = `/project/${projectParam}/field`
    await executeApi(fieldApi.post(data), true)
    return field
  }

  const updateField = async (
    data: Partial<Field>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Field>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || field.data?.id
    fieldUrl.value = `/project/${projectParam}/field/${idParam}`
    await executeApi(fieldApi.put(data), true)
    return field
  }

  const deleteField = async (id?: number, projectId?: number): Promise<FetchResult<Field>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || field.data?.id
    fieldUrl.value = `/project/${projectParam}/field/${idParam}`
    await executeApi(fieldApi.delete(), false)
    field.data = null
    return field
  }

  const $reset = () => {
    resetFetchResult(fields)
    resetFetchResult(field)
  }

  return {
    listFields,
    readField,
    createField,
    updateField,
    deleteField,
    patchFields,
    fields,
    field,
    patch,
    $reset
  }
})

export default useFieldStore
