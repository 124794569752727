import type { RouteRecordRaw } from 'vue-router'
import ClientCreateView from '@/views/client/ClientCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const clientCreateRoute: RouteRecordRaw = {
  path: 'new',
  name: 'clientCreate',
  component: ClientCreateView,
  meta: {
    scopes: [KeycloakScope.CLIENT_CREATE]
  }
}

export default clientCreateRoute
