<template>
  <vis-form @submit.prevent="emit('grant', email)">
    <v-row>
      <v-col cols="12">
        <v-table class="w-100">
          <tr>
            <td>
              <vis-text-field
                v-model="email"
                density="compact"
                name="email"
                :label="$t('email')"
                :placeholder="$t('email')"
              />
            </td>
            <td class="text-right">
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <vis-icon-btn type="submit" icon="mdi-key-plus" v-bind="props" />
                </template>
                {{ $t('grantAccess') }}
              </v-tooltip>
            </td>
          </tr>
          <template v-if="users && users.length > 0">
            <tr v-for="user of users" :key="user.id">
              <td>
                {{ user.email }}
              </td>
              <td class="text-right">
                <v-tooltip location="left">
                  <template #activator="{ props }">
                    <vis-icon-btn
                      icon="mdi-key-remove"
                      v-bind="props"
                      @click.stop="emit('revoke', user)"
                    />
                  </template>
                  {{ $t('revokeAccess') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="2">
              <i>{{ $t('noUsersFound') }}</i>
            </td>
          </tr>
        </v-table>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import type User from '@/types/user'
import { ref } from 'vue'

defineProps<{
  users?: Array<User>
}>()

const emit = defineEmits<{
  grant: [email: string]
  revoke: [user: User]
}>()

const email = ref('')
</script>
