import type { RouteRecordRaw } from 'vue-router'
import statusesRoute from './statuses'
import fieldsRoute from './fields'
import contactsRoute from './contacts'
import mediaRoute from './media'
import settingsRoute from './settings'

const projectSettingsRoute: RouteRecordRaw = {
  path: '/settings',
  name: 'projectSettings',
  redirect: { name: 'settings' },
  meta: {
    breadcrumb: 'projectSettings',
    menu: {
      key: 'projectSettings',
      icon: 'mdi-cog',
      name: 'projectSettings'
    }
  },
  children: [settingsRoute, statusesRoute, fieldsRoute, contactsRoute, mediaRoute]
}

export default projectSettingsRoute
