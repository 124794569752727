import type { RouteRecordRaw } from 'vue-router'
import RenderVersionsView from '@/views/project/renderSettings/RenderVersionsView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const renderVersionsRoute: RouteRecordRaw = {
  path: 'renderVersions',
  name: 'renderVersions',
  component: RenderVersionsView,
  meta: {
    breadcrumb: 'renderVersions',
    menu: {
      key: 'renderVersions',
      icon: 'mdi-bookmark-box-multiple',
      name: 'renderVersions'
    },
    scopes: [KeycloakScope.ASSETS_LIST]
  }
}

export default renderVersionsRoute
