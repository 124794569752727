<template>
  <vis-loader :loading="project.isFetching">
    <vis-sheet>
    <crm-integration-form :project="project.data!" @submit="updateProject" />
  </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import CrmIntegrationForm from '@/components/forms/CrmIntegrationForm.vue'
import useProjectStore from '@/stores/project'

const { project, updateProject } = useProjectStore()
</script>
