import type { RouteRecordRaw } from 'vue-router'
import UserUpdateView from '@/views/project/users/UserUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateUserRoute: RouteRecordRaw = {
  path: ':userId',
  name: 'updateUser',
  component: UserUpdateView,
  meta: {
    breadcrumb: 'updateUser',
    scopes: [KeycloakScope.USER_UPDATE]
  }
}

export default updateUserRoute
