<template>
  <div :key="artistResource.id" class="d-flex justify-center bg-grey-lighten-1 position-relative">
    <div class="overflow-hidden">
      <PreviewImage
        v-if="artistResource.metadata.thumbnailUrl"
        :src="artistResource.metadata.thumbnailUrl"
        :alt="artistResource.metadata.description"
        width="100%"
        height="100%"
        class="ma-4"
      />
    </div>
    <div class="position-absolute bottom-0 left-0 pa-5">
      <h5>{{ artistResource.metadata.title || artistResource.publicId }}</h5>
      <h5>Type: {{ artistResource.resourceType }}</h5>
      <h5>public ID: {{ artistResource.publicId }}</h5>
    </div>

    <div class="position-absolute bottom-0 right-0 pa-5">
      <v-btn size="x-small" @click="$emit('OnSelect', artistResource)">Select</v-btn>
    </div>
  </div>
  <v-container
    v-if="artistResource.metadata.variants && artistResource.metadata.variants.length > 1"
  >
    <v-row dense>
      <v-col
        v-for="variant in artistResource.metadata.variants"
        :key="variant.id"
        sm="6"
        md="4"
        lg="3"
        xl="2"
      >
        <div
          :class="{ 'bg-grey-lighten-1': variant.id === variantId }"
          class="d-flex flex-column align-center single-variant cursor-pointer"
          @click="selectVariant(variant)"
        >
          <PreviewImage
            :src="variant.thumbnailUrl"
            :alt="variant.name"
            width="100px"
            height="100px"
          />

          {{ variant.id }} - {{ variant.name }}
        </div>
      </v-col>
    </v-row>
  </v-container>

  <div>
    <JsonViewer v-if="artistResource" :value="artistResource" copyable />
  </div>
</template>

<script setup lang="ts">
import type ArtistResource from '@/types/artistResource'
import PreviewImage from './PreviewImage.vue'

defineProps<{
  artistResource: ArtistResource
  variantId?: string
}>()

const emits = defineEmits(['selectVariant', 'OnSelect'])

const selectVariant = (variant: any) => {
  emits('selectVariant', variant)
}
</script>

<style scoped>
.single-variant {
  border: 1px solid lightgray;
}

.single-variant:hover {
  background-color: azure;
}
</style>
