<template>
  <v-alert v-if="error" border="top" type="error" prominent style="width: 100%">
    {{ $t('thePlayerCouldNotBeLoaded') }}
  </v-alert>
  <slot v-else></slot>
</template>

<script setup lang="ts">
import { ref, onErrorCaptured } from 'vue'

const error = ref(false)

onErrorCaptured((e) => {
  error.value = true
  console.warn(e)
  return false
})
</script>
