<script setup lang="ts">
import { useMouseInElement } from '@vueuse/core'
import { computed, ref, toRef, watch } from 'vue'

interface Position {
  top?: number
  left?: number
  right?: number
  bottom?: number
}

export interface Props {
  title?: string
  subTitle?: string
  width?: number
  height?: number
  initialPosition?: Position
}

const props = withDefaults(defineProps<Props>(), {
  title: 'Position',
  subTitle: 'Click to set the position',
  width: 320,
  height: 200,
  initialPosition: () => ({ top: 0, left: 0, right: 100, bottom: 100 })
})

const emits = defineEmits(['update'])
const target = ref(null)

const { elementX, elementY, x, y, isOutside } = useMouseInElement(target)
const align = ref('left')
const positionRef = ref<Position>({})

watch(
  props.initialPosition,
  () => {
    positionRef.value = { ...props.initialPosition }

    if (positionRef.value.left) {
      align.value = 'left'
    }

    if (positionRef.value.right) {
      align.value = 'right'
    }

    if (positionRef.value.left) {
      if (positionRef.value.top) positionRef.value.bottom = 100 - positionRef.value.top
      if (positionRef.value.left) positionRef.value.right = 100 - positionRef.value.left
    }

    if (positionRef.value.right) {
      if (positionRef.value.right) positionRef.value.left = 100 - positionRef.value.right
      if (positionRef.value.bottom) positionRef.value.top = 100 - positionRef.value.bottom
    }
  },
  { immediate: true }
)

const updateValues = () => {
  if (!isOutside.value) {
    const pos = {
      top: (elementY.value / props.height) * 100,
      left: (elementX.value / props.width) * 100,
      bottom: 100 - (elementY.value / props.height) * 100,
      right: 100 - (elementX.value / props.width) * 100
    }

    positionRef.value = { ...pos }
  }

  let returnValue

  if (align.value === 'left') {
    returnValue = {
      left: positionRef.value.left,
      top: positionRef.value.top
    }
  }
  if (align.value === 'right') {
    returnValue = {
      right: positionRef.value.right,
      bottom: positionRef.value.bottom
    }
  }

  emits('update', returnValue)
}

watch(align, () => {
  updateValues()
})

const dotStyle: any = computed(() => {
  const top = positionRef.value.top
  const left = positionRef.value.left
  const right = positionRef.value.right
  const bottom = positionRef.value.bottom

  return {
    top: top ? `${top}%` : null,
    left: left ? `${left}%` : null,
    right: right ? `${right}%` : null,
    bottom: bottom ? `${bottom}%` : null,
    position: 'absolute',
    borderRadius: '50%',
    background: 'red',
    width: `5px`,
    height: `5px`
  }
})
</script>

<template>
  <div>
    <vis-sheet class="elevation-1 mt-3">
      <slot></slot>
      <div class="mb-3">{{ title }}</div>
      <div class="mb-3">{{ subTitle }}</div>
      <slot></slot>
      <v-row>
        <v-col cols="6">
          <div>
            <div
              ref="target"
              class="target-div position-relative elevation-5 cursor-pointer"
              :style="{ width: `${props.width}px`, height: `${props.height}px` }"
              @click.self="updateValues"
            >
              <span :style="dotStyle"></span>
            </div>
            <div class="mt-3">
              <v-btn-toggle v-model="align" variant="outlined" mandatory density="compact">
                <v-btn value="left" title="Align Left" size="small">
                  <v-icon>mdi mdi-align-horizontal-left</v-icon>
                </v-btn>
                <v-btn value="right" title="Align Right" size="small">
                  <v-icon>mdi-align-horizontal-right</v-icon></v-btn
                >
              </v-btn-toggle>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-row class="d-flex justify-center">
            <v-col cols="3">
              <vis-text-field
                :readonly="true"
                max-width="100"
                suffix="%"
                :label="$t('top')"
                v-model="positionRef.top"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="3">
              <vis-text-field
                :readonly="true"
                max-width="100"
                suffix="%"
                :label="$t('left')"
                v-model="positionRef.left"
              />
            </v-col>
            <v-col cols="3">
              <vis-text-field
                :readonly="true"
                max-width="100"
                suffix="%"
                :label="$t('right')"
                v-model="positionRef.right"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
            <v-col cols="3">
              <vis-text-field
                :readonly="true"
                max-width="100"
                suffix="%"
                :label="$t('bottom')"
                v-model="positionRef.bottom"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </vis-sheet>
  </div>
</template>
<style scoped>
.target-div {
  position: relative;
  overflow: hidden;
}
</style>
