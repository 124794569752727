<template>
  <template v-if="route.name === 'interiorOptions'">
    <vis-loader :loading="interiorOptions.isFetching || patch.isFetching">
      <interior-options-table
        :project="project.data!"
        :interiorOptions="interiorOptions.data!"
        v-on="{
          add: check(KeycloakScope.ASSETS_UPDATE) ? onAdd : undefined,
          select: check(KeycloakScope.ASSETS_UPDATE) ? onSelect : undefined,
          delete: check(KeycloakScope.ASSETS_UPDATE) ? onDelete : undefined,
          patch: check(KeycloakScope.ASSETS_UPDATE) ? onPatch : undefined
        }"
      />
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useInteriorOptionStore from '@/stores/interiorOption'
import InteriorOptionsTable from '@/components/tables/InteriorOptionsTable.vue'
import useProjectStore from '@/stores/project'
import type InteriorOption from '@/types/interiorOption'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()

const { interiorOptions, patch, listInteriorOptions, deleteInteriorOption, patchInteriorOptions } =
  useInteriorOptionStore()
interiorOptions.data || listInteriorOptions()

const onAdd = () => router.push({ name: 'createInteriorOption' })

const onSelect = (interiorOption: InteriorOption) =>
  router.push({
    name: 'updateInteriorOption',
    params: {
      interiorOptionId: interiorOption.id
    }
  })

const onDelete = async (interiorOption: InteriorOption) => {
  if (confirm(t('areYouSureYouWantToDeleteTheInteriorOption', [interiorOption.id]))) {
    await deleteInteriorOption(interiorOption.id)
    listInteriorOptions()
  }
}

const onPatch = async (reordered: Array<Partial<InteriorOption>>) => {
  await patchInteriorOptions(reordered)
  listInteriorOptions()
}
</script>
