import type { RouteRecordRaw } from 'vue-router'
import AddProjectView from '@/views/AddProjectView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const addProjectRoute: RouteRecordRaw = {
  path: '/addProject',
  name: 'addProject',
  component: AddProjectView,
  meta: {
    scopes: [KeycloakScope.PROJECT_CREATE]
  }
}

export default addProjectRoute
