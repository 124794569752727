<template>
  <v-navigation-drawer color="secondary" v-model="drawer">
    <v-list nav>
      <template v-for="{ key, icon, name, exact, children } of menu" :key="key">
        <v-list-item :prepend-icon="icon" :title="$t(key)" :to="{ name }" :exact="exact" link />
        <template v-if="children && children.length > 0">
          <v-list
            v-if="route.matched.map((r) => r.name).includes(name)"
            nav
            density="comfortable"
            class="bg-accent"
          >
            <v-list-item
              v-for="child of children"
              :prepend-icon="child.icon"
              :key="child.key"
              :title="$t(child.key)"
              :to="{ name: child.name }"
              :exact="child.exact"
              link
            />
          </v-list>
        </template>
      </template>
    </v-list>
    <template v-slot:append>
      <hr />
      <v-list nav>
        <v-list-item
          prepend-icon="mdi-home-switch-outline"
          :title="$t('switchProject')"
          :to="{ name: 'selectProject' }"
          exact
          link
        ></v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>

  <v-main class="main">
    <v-container fluid class="h-100 overflow-auto pa-0 d-flex flex-column">
      <v-sheet class="bg-background pa-4 d-flex align-end w-100 position-relative" height="200">
        <v-skeleton-loader
          v-if="buildings.isFetching"
          class="position-absolute position-reset w-100 h-100"
        />
        <template v-else-if="buildings.data?.length === 0">
          <div class="h-100 w-100 position-absolute position-reset bg-accent" />
        </template>
        <template v-else>
          <div class="h-100 w-100 position-absolute position-reset bg-accent" />
          <div
            v-for="(building, index) of buildings.data!"
            :key="building.id"
            class="h-100 position-absolute position-reset"
            :style="{
              backgroundImage: `url(${project.data?.assetRoot}/splash/${building.slug}.jpg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: `${Math.ceil(100 / buildings.data!.length)}%`,
              left: `${Math.floor(100 / buildings.data!.length) * index}%`
            }"
          />
        </template>
        <div
          style="background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 50%)"
          class="h-100 w-100 position-absolute position-reset"
        />
        <h1 class="text-white">{{ project.data!.name }}</h1>
      </v-sheet>
      <v-breadcrumbs :items="appStore.breadcrumbs">
        <template v-slot:divider>
          <v-icon size="x-small" icon="mdi-arrow-right"></v-icon>
        </template>
      </v-breadcrumbs>
      <v-sheet class="bg-background pa-4 pt-0 flex-fill">
        <router-view />
      </v-sheet>
    </v-container>
  </v-main>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { onBeforeRouteUpdate, useRoute } from 'vue-router'
import { Drawable, Drawer } from '@/injectionKeys'
import useProjectStore from '@/stores/project'
import useAppStore from '@/stores/app'
import useBuildingStore from '@/stores/building'
import routesToMenuConverter from '@/utils/routesToMenuConverter'
import routes from '@/configs/router/routes'

const appStore = useAppStore()

const drawer = inject(Drawer)
const drawable = inject(Drawable)
drawable!.value = true
onBeforeRouteUpdate(() => {
  drawable!.value = true
})

const route = useRoute()

const { project } = useProjectStore()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const menu = routesToMenuConverter(JSON.parse(JSON.stringify(routes)))
</script>

<style scoped>
.main {
  max-width: 100vw;
}
:deep(.position-reset) {
  top: 0;
  left: 0;
}
:deep(h1) {
  z-index: 200;
}
:deep(.v-list-group .v-list-item__prepend) {
  visibility: hidden !important;
}
hr {
  border-color: rgb(var(--v-theme-on-accent));
}
</style>
