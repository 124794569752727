import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import auth from './guards/auth'
import breadcrumb from './guards/breadcrumb'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach(auth)
router.afterEach(breadcrumb)

export default router
