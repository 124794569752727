import type { RouteRecordRaw } from 'vue-router'
import FieldListView from '@/views/project/settings/fields/FieldListView.vue'
import createFieldRoute from './createField'
import updateFieldRoute from './updateField'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const fieldsRoute: RouteRecordRaw = {
  path: 'fields',
  name: 'fields',
  component: FieldListView,
  meta: {
    breadcrumb: 'fields',
    menu: {
      key: 'fields',
      icon: 'mdi-table-row',
      name: 'fields'
    },
    scopes: [KeycloakScope.PROJECT_READ]
  },
  children: [createFieldRoute, updateFieldRoute]
}

export default fieldsRoute
