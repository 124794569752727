import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import resetFetchResult from '@/utils/resetFetchResult'
import type Project from '@/types/project'
import executeApi from '@/utils/executeApi'

const useProjectStore = defineStore('project', () => {
  const api = useApi()

  const projectsApi = api<Array<Project>>('/project').get().json()
  const projects: FetchResult<Array<Project>> = reactiveFetchResult(projectsApi)
  const listProjects = async (): Promise<FetchResult<Array<Project>>> => {
    await executeApi(projectsApi)
    return projects
  }

  const projectUrl = ref('/project')
  const projectApi = api<Array<Project>>(projectUrl).json()
  const project: FetchResult<Project> = reactiveFetchResult(projectApi)

  const readProject = async (id?: number): Promise<FetchResult<Project>> => {
    projectUrl.value = `/project/${id || project.data?.id}`
    await executeApi(projectApi.get())
    return project
  }

  const createProject = async (data: Partial<Project>): Promise<FetchResult<Project>> => {
    projectUrl.value = `/project`
    await executeApi(projectApi.post(data), true)
    return project
  }

  const updateProject = async (
    data: Partial<Project>,
    id?: number
  ): Promise<FetchResult<Project>> => {
    projectUrl.value = `/project/${id || project.data?.id}`
    await executeApi(projectApi.put(data), true)
    return project
  }

  const deleteProject = async (id?: number): Promise<FetchResult<Project>> => {
    projectUrl.value = `/project/${id || project.data?.id}`
    await executeApi(projectApi.delete(), false)
    project.data = null
    return project
  }

  const setProjectLogo = async (data: FormData, id?: number): Promise<FetchResult<Project>> => {
    projectUrl.value = `/project/${id || project.data?.id}/logo`
    const response = await executeApi(projectApi.post(data), true)
    return response.json()
  }

  const unsetProjectLogo = async (id?: number): Promise<FetchResult<Project>> => {
    projectUrl.value = `/project/${id || project.data?.id}/logo`
    const response = await executeApi(projectApi.delete(), false)
    return response.json()
  }

  const $reset = () => {
    resetFetchResult(projects)
    resetFetchResult(project)
  }

  return {
    listProjects,
    readProject,
    createProject,
    updateProject,
    deleteProject,
    setProjectLogo,
    unsetProjectLogo,
    projects,
    project,
    $reset
  }
})

export default useProjectStore
