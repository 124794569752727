import type KeycloakScope from '@/configs/keycloak/KeycloakScope'

export default function scopeCheck(
  userScopes: Array<KeycloakScope>,
  ...requiredScopes: Array<KeycloakScope>
) {
  let access = true
  for (const scope of requiredScopes) {
    if (!userScopes.includes(scope)) {
      access = false
      break
    }
  }
  return access
}
