<template>
  <v-row class="w-100">
    <v-col cols="12">
      <v-alert v-if="create.length === 0" color="success">
        {{ $t('noNewBuildingsFound') }}
      </v-alert>
      <v-alert v-if="create.length > 0" color="warning">
        {{ $t('newBuildingsWereDetected') }}:
        <ul>
          <li v-for="c of create" :key="c">
            {{ c }}
          </li>
        </ul>
      </v-alert>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { inject, ref, watchEffect, type Ref } from 'vue'
import type Building from '@/types/building'

const { data, buildings } = defineProps<{
  buildings: Array<Building>
  data: Array<{ [key: string]: any }>
}>()

const emit = defineEmits<{
  check: [{ create: Array<string>; data: Array<{ [key: string]: any }> }]
  back: []
}>()

const create = ref<Array<string>>([])
const keyedData = ref<Array<{ [key: string]: any }>>([])

const backLabel = inject<Ref<string>>('backLabel')
const backHandler = inject<Ref<undefined | (() => void)>>('backHandler')
backLabel!.value = 'back'
backHandler!.value = () => {
  emit('back')
}

const nextLabel = inject<Ref<string>>('nextLabel')
const nextHandler = inject<Ref<undefined | (() => void)>>('nextHandler')
nextLabel!.value = 'next'
nextHandler!.value = undefined

watchEffect(() => {
  const newData = [...data]
  for (const row of newData) {
    const id = row.building
    const building = buildings.find((b) => {
      return (
        b.id === id ||
        b.slug === id.toString().toLowerCase() ||
        b.name.toLowerCase() === id.toString().toLowerCase()
      )
    })
    if (!building) {
      if (!create.value.includes(id)) {
        create.value = [...create.value, id]
      }
    } else {
      row.building = building.id
    }
  }
  nextLabel!.value = create.value.length > 0 ? 'create' : 'next'
  keyedData.value = newData
})

nextHandler!.value = () => {
  emit('check', { create: create.value, data: keyedData.value })
}
</script>
