<template>
  <vis-loader :loading="building.isFetching">
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <building-form @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useBuildingStore from '@/stores/building'
import BuildingForm from '@/components/forms/BuildingForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { building, listBuildings, createBuilding } = useBuildingStore()

const onSubmit = useSubmitThenRoute(
  createBuilding,
  (b) => ({ name: 'updateBuilding', params: { buildingId: b.data!.id } }),
  () => listBuildings()
)
</script>
