import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Image from '@/types/image'
import executeApi from '@/utils/executeApi'

const useImageStore = defineStore('image', () => {
  const api = useApi()

  const imagesUrl = ref('/project')
  const imagesApi = api<Array<Image>>(imagesUrl).json()
  const images: FetchResult<Array<Image>> = reactiveFetchResult(imagesApi)

  const listImages = async (projectId?: number): Promise<FetchResult<Array<Image>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    imagesUrl.value = `/project/${projectIdParam}/image`
    await executeApi(imagesApi.get())
    return images
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<Image>>(patchUrl).json()
  const patch: FetchResult<Array<Image>> = reactiveFetchResult(patchApi)
  const patchImages = async (
    data: Array<Partial<Image>>,
    projectId?: number
  ): Promise<Array<Image>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/image`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch.data!
  }

  const imageUrl = ref('/project')
  const imageApi = api<Array<Image>>(imageUrl).json()
  const image: FetchResult<Image> = reactiveFetchResult(imageApi)

  const readImage = async (id?: number, projectId?: number): Promise<FetchResult<Image>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || image.data?.id
    imageUrl.value = `/project/${projectIdParam}/image/${idParam}`
    await executeApi(imageApi.get())
    return image
  }

  const createImage = async (data: FormData, projectId?: number): Promise<Array<Image>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    imageUrl.value = `/project/${projectIdParam}/image`
    const response = await executeApi(imageApi.post(data), true)
    return response.json()
  }

  const updateImage = async (
    data: Partial<Image>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Image>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || image.data?.id
    imageUrl.value = `/project/${projectIdParam}/image/${idParam}`
    await executeApi(imageApi.put(data), true)
    return image
  }

  const deleteImage = async (id?: number, projectId?: number): Promise<FetchResult<Image>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || image.data?.id
    imageUrl.value = `/project/${projectIdParam}/image/${idParam}`
    await executeApi(imageApi.delete(), false)
    image.data = null
    return image
  }

  const $reset = () => {
    resetFetchResult(images)
    resetFetchResult(image)
  }

  return {
    listImages,
    readImage,
    createImage,
    updateImage,
    deleteImage,
    patchImages,
    images,
    image,
    patch,
    $reset
  }
})

export default useImageStore
