import type { RouteRecordRaw } from 'vue-router'
import ProjectStyleCreateView from '@/views/project/renderSettings/projectStylesDesigner/ProjectStyleCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createProjectStyleRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createProjectStyle',
  component: ProjectStyleCreateView,
  meta: {
    breadcrumb: 'createProjectStyle',
    scopes: [KeycloakScope.ASSETS_CREATE]
  }
}

export default createProjectStyleRoute
