<template>
  <vis-loader :loading="units.isFetching">
    <vis-form @submit.prevent="onSubmit">
      <vis-sheet>
        <vis-file-input
          multiple
          accept="image/png, image/jpeg"
          density="compact"
          :label="$t('uploadFiles')"
          :placeholder="$t('uploadFiles')"
          @change="onInput"
        />
        <v-table>
          <thead>
            <tr>
              <th>{{ $t('id') }}</th>
              <th>{{ $t('title') }}</th>
              <th>{{ $t('guid') }}</th>
              <th>{{ $t('apartment') }} type</th>
              <th>{{ $t('file') }}</th>
              <th>{{ $t('progress') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unit of unitsWithFile" :key="unit.id">
              <td>{{ unit.id }}</td>
              <td>{{ unit.title }}</td>
              <td>{{ unit.unitId }}</td>
              <td>{{ unit.apartmentType }}</td>
              <td>
                <vis-select
                  :items="files.map((f) => ({ title: f.name, value: f.name }))"
                  density="compact"
                  v-model="unit.file"
                  :label="$t('selectFile')"
                  :placeholder="$t('selectFile')"
                />
              </td>
              <td>
                <span v-if="!unit.file">&nbsp;</span>
                <v-icon v-else-if="!unit.uploadStatus" icon="mdi-clock-outline" />
                <v-icon
                  v-else-if="unit.uploadStatus === 'uploading'"
                  icon="mdi-upload"
                  color="primary"
                />
                <v-icon
                  v-else-if="unit.uploadStatus === 'error'"
                  icon="mdi-close-thick"
                  color="error"
                />
                <v-icon
                  v-else-if="unit.uploadStatus === 'success'"
                  icon="mdi-check-bold"
                  color="success"
                />
              </td>
            </tr>
          </tbody>
        </v-table>
        <v-row>
          <v-col cols="12" class="text-right">
            <vis-submit-btn type="submit" :disabled="submitting">{{ $t('submit') }}</vis-submit-btn>
          </v-col>
        </v-row>
      </vis-sheet>
    </vis-form>
  </vis-loader>
</template>

<script setup lang="ts">
import { ref, watchEffect } from 'vue'
import useUnitStore from '@/stores/unit'
import type Unit from '@/types/unit'

const { units, listUnits, uploadPdfToUnit } = useUnitStore()
units.data || listUnits()

const files = ref<Array<File>>([])
const submitting = ref(false)
const unitsWithFile = ref<Array<Unit & { file?: string; uploadStatus?: string }>>([])
watchEffect(() => {
  unitsWithFile.value = (units.data || []).map((u) => ({ ...u, file: undefined }))
})

const onInput = (e: Event) => {
  const f = (e.target as HTMLInputElement).files
  if (f) {
    for (const unit of unitsWithFile.value) {
      unit.file = undefined
      unit.uploadStatus = undefined
    }
    files.value = []
    for (const ff of f) {
      files.value.push(ff)
    }
    if (files.value.length === 1) {
      for (const unit of unitsWithFile.value) {
        unit.file = files.value[0].name
      }
    } else {
      for (const unit of unitsWithFile.value) {
        for (const file of files.value) {
          if (
            file.name.toLowerCase().includes(unit.unitId.toLowerCase()) ||
            file.name.toLowerCase().includes(unit.title.toLowerCase()) ||
            (unit.apartmentType &&
              file.name.toLowerCase().includes(unit.apartmentType.toLowerCase()))
          ) {
            unit.file = file.name
          }
        }
      }
    }
    unitsWithFile.value = [...unitsWithFile.value]
  }
}

const onSubmit = async () => {
  if (!submitting.value) {
    submitting.value = true
    for (const unit of unitsWithFile.value.filter((u) => !!u.file)) {
      const formData = new FormData()
      const file = files.value.find((f) => f.name === unit.file)
      formData.append('floorplans', file!)
      unit.uploadStatus = 'uploading'
      const { error } = await uploadPdfToUnit(formData, unit.id)
      unit.uploadStatus = error ? 'error' : 'success'
    }
    await listUnits()
    submitting.value = false
  }
}
</script>
