<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12">
        <vis-text-field v-model="project.gtmID" :label="$t('googleTagManagerId')" />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-text-field v-model="project.metaPixelID" :label="$t('facebookMetaPixelId')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type Project from '@/types/project'

const props = defineProps<{ project?: Project }>()
const emit = defineEmits<{
  submit: [project: Partial<Project>]
}>()

const project = reactive<Partial<Project>>(props.project || {})

const { formRef, submit } = useFormPartials(project, (partial: Partial<Project>) => {
  emit('submit', partial)
})
</script>
