<template>
  <div class="border pa-2 elevation-3 rounded cursor-pointer overflow-hidden">
    <div class="d-flex align-center">
      <div>
        <PreviewImage
          v-if="artistResource.metadata.thumbnailUrl"
          :src="artistResource.metadata.thumbnailUrl"
          :alt="artistResource.metadata.description"
          width="100px"
          height="100px"
          class="ma-1"
        />
      </div>
      <div class="pa-1 position-relative d-block">
        <div
          :title="title(artistResource)"
          class="text-caption font-weight-bold title-div text-truncate"
        >
          {{ title(artistResource) }}
        </div>

        <div v-if="artistResource.metadata.title" class="text-caption">
          {{ artistResource.publicId }}
        </div>

        <div class="text-caption" v-if="artistResource.metadata.variants">
          <div class="d-flex">{{ artistResource.metadata.variants.length }} Variants</div>
        </div>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="d-flex justify-space-around mt-1">
      <v-btn @click="emit('select', artistResource)" size="x-small">Select</v-btn>
      <v-btn size="x-small">Preview</v-btn>
    </div>
  </div>
</template>
<script setup lang="ts">
import type ArtistResource from '@/types/artistResource'
import PreviewImage from './PreviewImage.vue'

const title = (resource: ArtistResource) => {
  if (resource.metadata.title) return resource.metadata.title
  return resource.publicId
}

const props = defineProps<{
  artistResource: ArtistResource
}>()

const emit = defineEmits(['select'])
</script>
<style scoped>
.title-div {
  width: 95%;
}
</style>
