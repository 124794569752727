<template>
  <vis-data-table :headers="columns" :items="itemsRef" @click:row="onRowClick">
    <template #top>
      <vis-sheet v-if="hasAddEventListener" class="text-end">
        <slot name="top"></slot>
        <v-tooltip location="left">
          <template #activator="{ props }">
            <vis-add-icon-btn v-bind="props" @click="emit('add')" />
          </template>
          {{ $t('create') }}
        </v-tooltip>
      </vis-sheet>
    </template>
    <template #bottom />
    <template v-if="hasDeleteEventListener" #item.actions="itemProps">
      <slot name="actions.prepend" v-bind="(itemProps as any)"></slot>
      <v-tooltip location="left">
        <template #activator="{ props }">
          <vis-remove-icon-btn v-bind="props" @click.stop="onRowDelete(itemProps.item)" />
        </template>
        {{ $t('delete') }}
      </v-tooltip>
      <slot name="actions.append" v-bind="(itemProps as any)"></slot>
    </template>
    <template
      v-for="{ type, key, customRenderer } of tableColumns"
      :key="key"
      #[key]="{ value, item }"
    >
      <data-table-cell-renderer
        :type="type"
        :project="project || (item as Project)"
        :statuses="statuses"
        :field="fieldResolver ? fieldResolver({ value, key, item }) : undefined"
        :status="statusResolver ? statusResolver({ value, key, item }) : undefined"
        :fieldStatus="fieldStatusResolver ? fieldStatusResolver({ value, key, item }) : undefined"
        :value="value"
        :customRenderer="customRenderer"
      />
    </template>
  </vis-data-table>
</template>

<script setup lang="ts" generic="T">
import { computed, getCurrentInstance } from 'vue'
import DataTableCellRenderer from '../dataTable/DataTableCellRenderer'
import useTableColumnConverter from '@/composables/useTableColumnConverter'
import type Field from '@/types/field'
import type Project from '@/types/project'
import type Status from '@/types/status'
import usePropChange from '@/composables/usePropChange'

const props = defineProps<{
  items: Array<T>
  columns: VDataTableColumns
  project?: Project
  statuses?: Array<Status>
  statusResolver?: (args: { value: any; key: string; item: T }) => Status
  fieldResolver?: (args: { value: any; key: string; item: T }) => Field
  fieldStatusResolver?: (args: { value: any; key: string; item: T }) => any
}>()

const emit = defineEmits<{
  add: []
  delete: [item: T]
  select: [item: T]
}>()

defineSlots<{
  top(props: {}): any
  'actions.prepend'(itemKeySlot: VDataTableItem<T>): any
  'actions.append'(itemKeySlot: VDataTableItem<T>): any
}>()

const hasAddEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onAdd)
const hasDeleteEventListener = computed(() => !!getCurrentInstance()?.vnode.props?.onDelete)

const { columns, project, statusResolver, fieldResolver, fieldStatusResolver } = props
const itemsRef = usePropChange(props, 'items')

const tableColumns = useTableColumnConverter(columns)

function onRowDelete(item: T) {
  emit('delete', item)
}

function onRowClick(_: PointerEvent, item: VDataTableItem<T>) {
  emit('select', item.item!)
}
</script>
