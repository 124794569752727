import type { RouteRecordRaw } from 'vue-router'
import ContactListView from '@/views/project/settings/contacts/ContactListView.vue'
import createContactRoute from './createContact'
import updateContactRoute from './updateContact'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const contactsRoute: RouteRecordRaw = {
  path: 'contacts',
  name: 'contacts',
  component: ContactListView,
  meta: {
    breadcrumb: 'contacts',
    menu: {
      key: 'contacts',
      icon: 'mdi-account-box-multiple',
      name: 'contacts'
    },
    scopes: [KeycloakScope.PROJECT_READ]
  },
  children: [createContactRoute, updateContactRoute]
}

export default contactsRoute
