import type { RouteRecordRaw } from 'vue-router'
import StatusListView from '@/views/project/settings/statuses/StatusListView.vue'
import createStatusRoute from './createStatus'
import updateStatusRoute from './updateStatus'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const statusesRoute: RouteRecordRaw = {
  path: 'statuses',
  name: 'statuses',
  component: StatusListView,
  meta: {
    breadcrumb: 'statuses',
    menu: {
      key: 'statuses',
      icon: 'mdi-list-status',
      name: 'statuses'
    },
    scopes: [KeycloakScope.PROJECT_READ]
  },
  children: [createStatusRoute, updateStatusRoute]
}

export default statusesRoute
