<template>
  <h4>Materials</h4>
  <div
    v-for="(roomSurfaceStyle, roomIndex) of materials"
    :key="`material-room-${roomSurfaceStyle.key}-${roomIndex}`"
    class="ma-0 pa-0 bg-white"
  >
    <div>
      <div class="d-flex align-center">
        <div class="roomstyle-title pa-2 border-b align-self-stretch d-flex">
          <div>
            <span class="text-bold">{{ roomSurfaceStyle.label || roomSurfaceStyle.key }} </span>
            <span class="text-caption d-block">({{ roomSurfaceStyle.key }})</span>
          </div>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn class="ml-3" size="x-small" icon="mdi-dots-vertical" v-bind="props"></v-btn>
            </template>
            <v-list density="compact" class="pa-0">
              <v-list-item
                v-for="(item, menuItemsIndex) in materialsRoomMenu(roomSurfaceStyle)"
                :value="menuItemsIndex"
                :key="menuItemsIndex"
                :class="{
                  'border-b': menuItemsIndex < materialsRoomMenu(roomSurfaceStyle).length - 1
                }"
              >
                <template v-slot:prepend>
                  <v-icon :icon="item.icon" size="x-small"></v-icon>
                </template>
                <v-list-item-title @click="item.onClick" class="text-caption">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div class="flex-grow-1">
          <div
            class="ma-0 single-specification"
            v-for="(specification, index) of roomSurfaceStyle.surfaces"
            :key="index + roomSurfaceStyle.key + specification.id"
          >
            <div class="single-specification-controls">
              <v-btn
                size="x-small"
                icon="mdi-delete"
                class="ma-2"
                @click="deleteMaterialSpecification(roomSurfaceStyle, specification.key)"
              ></v-btn>
            </div>

            <div class="single-surface d-flex align-center border pl-2 flex-grow-1">
              <div class="surface-name text-caption">
                {{ specification.key }}
              </div>
              <div class="border-s surface-spec position-relative">
                <SingleMaterialOrAsset
                  :key="`${roomSurfaceStyle.key}-${specification.key}-${roomIndex}-${index}`"
                  :artistAsset="specification"
                  :artistResources="artistResourceMaterials ? artistResourceMaterials : []"
                  @update="
                    (...args) =>
                      updateMaterialSpecification(roomSurfaceStyle, specification, args[0], args[1])
                  "
                />
              </div>
            </div>
            <v-spacer></v-spacer>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex justify-center ma-2">
    <v-btn
      @click="roomSurfaceStyleMaterialsDialog = true"
      size="small"
      class="rounded-xl"
      prependIcon="mdi-plus"
    >
      Add Room
    </v-btn>
  </div>

  <RoomSelector
    :open="roomSurfaceStyleMaterialsDialog"
    :room="RoomType.Generic"
    @save="($event: any) => addRoomStyleToMaterials($event)"
    @close="roomSurfaceStyleMaterialsDialog = false"
  >
  </RoomSelector>
</template>
<script setup lang="ts">
import type ArtistResource from '@/types/artistResource'
import {
  RoomType,
  type MaterialOrAssetSpecification,
  type RoomSurfaceStyle
} from '@/types/projectStyle'
import SingleMaterialOrAsset from './SingleMaterialOrAsset.vue'
import RoomSelector from './RoomSelector.vue'
import { watch, computed, ref, onMounted, type ComputedRef, type Ref } from 'vue'
import _ from 'lodash'

const props = defineProps(['artistResources', 'projectStyleMaterials'])
const emits = defineEmits<{
  updateMaterials: [Array<RoomSurfaceStyle>]
}>()

const materials: Ref<Array<RoomSurfaceStyle> | undefined> = ref([])
const roomSurfaceStyleMaterialsDialog = ref(false)

onMounted(() => {
  materials.value = props.projectStyleMaterials
})

const materialsRoomMenu = (roomSurface: RoomSurfaceStyle) => {
  return [
    {
      label: 'Add Surface',
      icon: 'mdi-plus',
      onClick: () => addMaterialSpecification(roomSurface)
    },
    { label: 'Edit', icon: 'mdi-pencil', onClick: () => editMaterialRoomStyle(roomSurface) },
    {
      label: 'Duplicate',
      icon: 'mdi-content-copy',
      onClick: () => duplicateMaterialRoomStyle(roomSurface)
    },
    { label: 'Delete', icon: 'mdi-delete', onClick: () => deleteMaterialRoomStyle(roomSurface) }
  ]
}

const defaultRoomSurfaceStyles: Array<RoomSurfaceStyle> = [
  {
    key: RoomType.Bedroom,
    label: 'Bedroom',
    surfaces: [
      { key: 'Wall', id: 'M000001', variant: null },
      { key: 'Floor', id: 'M000440', variant: null },
      { key: 'Ceiling', id: 'M000001', variant: null }
    ]
  },
  {
    key: RoomType.Hallway,
    label: 'Hallway',
    surfaces: [
      { key: 'Wall', id: 'M000001', variant: null },
      { key: 'Floor', id: 'M000440', variant: null },
      { key: 'Ceiling', id: 'M000001', variant: null }
    ]
  },
  {
    key: RoomType.Kitchen,
    label: 'Kitchen',
    surfaces: [
      { key: 'Wall', id: 'M000001', variant: null },
      { key: 'Floor', id: 'M000440', variant: null },
      { key: 'Ceiling', id: 'M000001', variant: null }
    ]
  },
  {
    key: RoomType.Balcony,
    label: 'Balcony',
    surfaces: [
      { key: 'Wall', id: 'M000081', variant: null },
      { key: 'Floor', id: 'M000392', variant: null },
      { key: 'Ceiling', id: 'M000081', variant: null }
    ]
  },
  {
    key: RoomType.Bathroom,
    label: 'Bathroom',
    surfaces: [
      { key: 'Wall', id: 'M000083', variant: null },
      { key: 'Floor', id: 'M000074', variant: null },
      { key: 'Ceiling', id: 'M000001', variant: null }
    ]
  }
]

const defaultSurfaces = [
  { key: 'Wall', id: 'M000001', variant: null },
  { key: 'Floor', id: 'M000440', variant: null },
  { key: 'Ceiling', id: 'M000001', variant: null }
]

const defaultRoomSurfaceStyle: RoomSurfaceStyle = {
  key: RoomType.Generic,
  label: RoomType.Generic,
  surfaces: defaultSurfaces
}

const artistResourceMaterials: ComputedRef<Array<ArtistResource>> = computed(() => {
  return props.artistResources?.filter(
    (resource: ArtistResource) => resource.resourceType === 'material'
  )
})

const addMaterialSpecification = (roomSurfaceStyle: RoomSurfaceStyle) => {
  const key = prompt('Enter a key', 'newSurface' + roomSurfaceStyle.surfaces.length)

  if (!key) return

  let newSurface: MaterialOrAssetSpecification = {
    key: key,
    id: 'M000001',
    variant: null
  }
  roomSurfaceStyle.surfaces = [...roomSurfaceStyle.surfaces, newSurface]
}

const duplicateMaterialRoomStyle = (roomSurfaceStyle: RoomSurfaceStyle) => {
  const newRoomSurafaceStyle = {
    ..._.cloneDeep(roomSurfaceStyle),
    key: (roomSurfaceStyle.key + '-copy') as RoomType,
    label: roomSurfaceStyle.label
  }

  materials.value = [...(materials.value as RoomSurfaceStyle[]), newRoomSurafaceStyle]
}

const addRoomStyleToMaterials = (roomName: string) => {
  roomSurfaceStyleMaterialsDialog.value = false

  let roomType: string = RoomType[roomName as keyof typeof RoomType] as string

  if (!roomType) {
    roomType = roomName
  }

  const currentRooms = materials.value?.map((room) => room.key as string) || []

  if (currentRooms.includes(roomType)) {
    roomType = roomType + (currentRooms.length + 1)
  }

  const defaultRoomSurfaceStyle: RoomSurfaceStyle | undefined = defaultRoomSurfaceStyles.find(
    (roomSurfaceStyle) => roomSurfaceStyle.label === roomType
  )

  let roomSurfaceStyle: RoomSurfaceStyle = {
    key: roomType as RoomType,
    label: roomName,
    surfaces: defaultSurfaces
  }

  if (defaultRoomSurfaceStyle) {
    roomSurfaceStyle = {
      ..._.cloneDeep(defaultRoomSurfaceStyle),
      key: roomType as RoomType,
      label: roomName
    }
  }

  materials.value = [...(materials.value as RoomSurfaceStyle[]), roomSurfaceStyle]
}

const editMaterialRoomStyle = (roomSurfaceStyle: RoomSurfaceStyle) => {
  const newName = prompt('Enter name', roomSurfaceStyle.label)
  if (!newName) return
  roomSurfaceStyle.label = newName
}

const updateMaterialSpecification = (
  roomSurfaceStyle: RoomSurfaceStyle,
  specification: MaterialOrAssetSpecification,
  artistResource: ArtistResource,
  variant: any
) => {
  const newSpec = roomSurfaceStyle.surfaces.map((spec) => {
    if (spec.key === specification.key) {
      spec.id = artistResource.publicId
      spec.variant = variant?.id
    }
    return spec
  })

  roomSurfaceStyle.surfaces = newSpec
}

const deleteMaterialSpecification = (
  roomSurfaceStyle: RoomSurfaceStyle,
  specificationKey: string
) => {
  if (
    !confirm(`Are you sure you want to delete ${specificationKey} for ${roomSurfaceStyle.label}?`)
  )
    return
  roomSurfaceStyle.surfaces = roomSurfaceStyle.surfaces.filter(
    (specification) => specification.key !== specificationKey
  )
}

const deleteMaterialRoomStyle = (roomSurfaceStyle: RoomSurfaceStyle) => {
  if (!confirm(`Are you sure you want to delete ${roomSurfaceStyle.key} materials?`)) return

  materials.value = materials.value?.filter((rm) => rm.key !== roomSurfaceStyle.key)
}

watch(
  materials,
  () => {
    updateMaterials(materials.value as RoomSurfaceStyle[])
  },
  {
    deep: true
  }
)

const updateMaterials = (materials: Array<RoomSurfaceStyle>) => {
  emits('updateMaterials', materials)
}
</script>
<style scoped>
.surface-spec {
  width: 100%;
}

.single-specification {
  position: relative;
}
.single-specification:hover .single-specification-controls {
  display: block;
}

.single-specification-controls {
  display: none;
  z-index: 10;
  position: absolute;
  top: 15px;
  right: 0;
  scale: 0.9;
}

.roomstyle-title {
  min-width: 200px;
}

.surface-name {
  min-width: 100px;
}
</style>
