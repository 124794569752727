import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type FieldStatusOption from '@/types/fieldStatusOption'
import useFieldStore from './field'
import executeApi from '@/utils/executeApi'

const useFieldStatusOptionStore = defineStore('fieldStatusOption', () => {
  const api = useApi()

  const fieldStatusOptionsUrl = ref('/project')
  const fieldStatusOptionsApi = api<Array<FieldStatusOption>>(fieldStatusOptionsUrl).json()
  const fieldStatusOptions: FetchResult<Array<FieldStatusOption>> =
    reactiveFetchResult(fieldStatusOptionsApi)

  const listFieldStatusOptions = async (
    fieldId?: number,
    projectId?: number
  ): Promise<FetchResult<Array<FieldStatusOption>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let fieldIdParam = fieldId
    if (!fieldIdParam) {
      const fieldStore = useFieldStore()
      fieldIdParam = fieldStore.field.data!.id
    }
    fieldStatusOptionsUrl.value = `/project/${projectIdParam}/field/${fieldIdParam}/statusOption`
    await executeApi(fieldStatusOptionsApi.get())
    return fieldStatusOptions
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<FieldStatusOption>>(patchUrl).json()
  const patch: FetchResult<Array<FieldStatusOption>> = reactiveFetchResult(patchApi)
  const patchFieldStatusOptions = async (
    data: Array<Partial<FieldStatusOption>>,
    fieldId?: number,
    projectId?: number
  ): Promise<Array<FieldStatusOption>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let fieldIdParam = fieldId
    if (!fieldIdParam) {
      const fieldStore = useFieldStore()
      fieldIdParam = fieldStore.field.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/field/${fieldIdParam}/statusOption`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch.data!
  }

  const fieldStatusOptionUrl = ref('/project')
  const fieldStatusOptionApi = api<Array<FieldStatusOption>>(fieldStatusOptionUrl).json()
  const fieldStatusOption: FetchResult<FieldStatusOption> =
    reactiveFetchResult(fieldStatusOptionApi)

  const createFieldStatusOption = async (
    data: Partial<FieldStatusOption>,
    fieldId?: number,
    projectId?: number
  ): Promise<FetchResult<FieldStatusOption>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let fieldIdParam = fieldId
    if (!fieldIdParam) {
      const fieldStore = useFieldStore()
      fieldIdParam = fieldStore.field.data!.id
    }
    fieldStatusOptionUrl.value = `/project/${projectIdParam}/field/${fieldIdParam}/statusOption`
    await executeApi(fieldStatusOptionApi.post(data), true)
    return fieldStatusOption
  }

  const $reset = () => {
    resetFetchResult(fieldStatusOptions)
    resetFetchResult(fieldStatusOption)
  }

  return {
    listFieldStatusOptions,
    createFieldStatusOption,
    patchFieldStatusOptions,
    fieldStatusOptions,
    fieldStatusOption,
    patch,
    $reset
  }
})

export default useFieldStatusOptionStore
