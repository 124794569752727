import i18n from '@/configs/i18n'
import useAppStore from '@/stores/app'
import type { BaseMessage } from '@/stores/app'
import type { UseFetchReturn } from '@vueuse/core'

export default async function executeApi<T>(
  fetchReturn: UseFetchReturn<T> & PromiseLike<UseFetchReturn<T>>,
  options?: false | true | string | { error?: BaseMessage; success?: BaseMessage }
): Promise<Response> {
  const { t } = i18n.global
  const app = useAppStore()
  let error: BaseMessage | undefined
  let success: BaseMessage | undefined
  if (options) {
    if (typeof options === 'string') {
      success = {
        color: 'success',
        message: options
      }
    } else if (typeof options === 'object') {
      error = options.error
      success = options.success
    } else if (options === true) {
      success = {
        color: 'success',
        message: t('saved')
      }
    } else {
      success = {
        color: 'success',
        message: t('deleted')
      }
    }
  }
  return fetchReturn
    ?.execute(true)
    .then((r) => {
      if (success) {
        app.addMessage(success)
      }
      return r
    })
    .catch((e) => {
      app.addMessage(error || e)
      return e
    })
}
