<template>
  <vis-loader :loading="project.isFetching">
    <vis-sheet>
      <marketing-integration-form :project="project.data!" @submit="updateProject" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import MarketingIntegrationForm from '@/components/forms/MarketingIntegrationForm.vue'
import useProjectStore from '@/stores/project'

const { project, updateProject } = useProjectStore()
</script>
