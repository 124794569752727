<template>
  <vis-sheet>
    <template v-if="items.length > 0">
      <template v-if="searchOnBottom === false && searchableColumns.length > 0">
        <v-row>
          <v-col cols="12">
            <vis-text-field
              placeholder="search"
              autofocus
              v-model="searchValue"
              :hide-details="false"
              :messages="$t('searchingIn', [searchableColumns.map((s) => s.title).join(', ')])"
              :append-inner-icon="searchValue ? 'mdi-close' : undefined"
              @click:append-inner="searchValue = ''"
              density="compact"
            />
          </v-col>
        </v-row>
      </template>

      <v-row v-for="column of filterableColumns" :key="column.key">
        <v-col cols="12">
          <template v-if="column.customRenderer">
            <vis-select
              :items="getCustomRendererOptions(column)"
              multiple
              v-model="filterValues[column.key]"
              :placeholder="column.title"
              density="compact"
            >
              <template v-slot:item="{ props, item: { value } }">
                <v-list-item v-bind="props">
                  {{ renderNode(column, value) }}
                </v-list-item>
              </template>
              <template v-slot:selection="{ item: { value } }">
                <v-chip color="primary">
                  {{ renderNode(column, value) }}
                </v-chip>
              </template>
            </vis-select>
          </template>
          <template v-else-if="checkType(column.type, 'number', 'area', 'currency')">
            <template v-for="[min, max, step] of [getNumberRange(column)]" :key="min">
              <vis-range-slider
                strict
                :step="step"
                :min="min"
                :max="max"
                v-model="filterValues[column.key]"
                density="compact"
              />
              <v-list-item-subtitle
                >{{ column.title }}:&nbsp;
                <template v-for="(filterValue, index) of [filterValues[column.key]]" :key="index">
                  <template v-if="filterValue">
                    <data-table-cell-renderer
                      :value="filterValue[0]"
                      :type="column.type!"
                      :project="project"
                      :statuses="statuses"
                    />&nbsp;-&nbsp;<data-table-cell-renderer
                      :value="filterValue[1]"
                      :type="column.type!"
                      :project="project"
                      :statuses="statuses"
                    />
                  </template>
                </template>
              </v-list-item-subtitle>
            </template>
          </template>
          <template v-else-if="checkType(column.type, 'string', 'html', 'url')">
            <vis-select
              :items="getStringSelector(column)"
              multiple
              v-model="filterValues[column.key]"
              :placeholder="column.title"
              density="compact"
            >
              <template v-slot:selection="{ item: { value } }">
                <v-chip color="primary">
                  {{ value }}
                </v-chip>
              </template>
            </vis-select>
          </template>
          <template v-else-if="checkType(column.type, 'id')">
            <vis-select
              :items="getIdSelector(column)"
              multiple
              v-model="filterValues[column.key]"
              :placeholder="column.title"
              density="compact"
            >
              <template v-slot:selection="{ item: { value } }">
                <v-chip color="primary">
                  {{ value }}
                </v-chip>
              </template>
            </vis-select></template
          >
          <template v-else-if="checkType(column.type, 'status')">
            <template v-if="statuses">
              <vis-select
                :items="statuses.map((s) => ({ value: s.id, title: translator(s.label) }))"
                multiple
                v-model="filterValues[column.key]"
                :placeholder="column.title"
                density="compact"
              >
                <template v-slot:item="{ props }">
                  <v-list-item v-bind="props" />
                </template>
                <template v-slot:selection="{ item: { title } }">
                  <v-chip color="primary">
                    {{ title }}
                  </v-chip>
                </template>
              </vis-select>
            </template>
            <template v-else> {{ $t('cannotLoadStatuses') }} </template>
          </template>
          <template v-else-if="checkType(column.type, 'date')">
            <template v-for="[min, max] of [getDateRange(column)]" :key="min">
              <vue-date-picker
                :format="
                  (date: [Date, Date]) =>
                    date
                      ? (date[0]?.toLocaleDateString() || '...') +
                        ' - ' +
                        (date[1]?.toLocaleDateString() || '...')
                      : '-'
                "
                range
                :min-date="min"
                :max-date="max"
                utc
                :enable-time-picker="false"
                v-model="filterValues[column.key]"
              />
            </template>
          </template>
          <template v-else-if="checkType(column.type, 'boolean')">
            <v-btn-toggle v-model="filterValues[column.key]" divided density="compact">
              <vis-btn :value="true">{{ $t('yes') }}</vis-btn>
              <vis-btn :value="false">{{ $t('no') }}</vis-btn>
              <vis-btn :value="null">{{ $t('all') }}</vis-btn>
            </v-btn-toggle>
          </template>
        </v-col>
      </v-row>
      <template v-if="searchOnBottom !== false && searchableColumns.length > 0">
        <v-row>
          <v-col cols="12">
            <vis-text-field
              placeholder="search"
              autofocus
              v-model="searchValue"
              :hide-details="false"
              :messages="$t('searchingIn', [searchableColumns.map((s) => s.title).join(', ')])"
              :append-inner-icon="searchValue ? 'mdi-close' : undefined"
              @click:append-inner="searchValue = ''"
              density="compact"
            />
          </v-col>
        </v-row>
      </template>
    </template>
    <template v-else>
      <v-row>
        <v-col cols="12">
          <i>{{ $t('needMoreData') }}</i>
        </v-col>
      </v-row>
    </template>
  </vis-sheet>
</template>

<script setup lang="ts" generic="T extends { [key: string]: any }">
import { isVNode, reactive, computed, watch, ref } from 'vue'
import { onKeyStroke } from '@vueuse/core'
import DataTableCellRenderer from '@/components/dataTable/DataTableCellRenderer'
import { VListItem } from 'vuetify/components/VList'
import useI18nTranslator from '@/composables/useI18nTranslator'
import type Project from '@/types/project'
import type Status from '@/types/status'

const { columns, items, project, statuses, searchOnBottom } = defineProps<{
  columns: VDataTableColumns
  items: Array<T>
  project?: Project
  statuses?: Array<Status>
  searchOnBottom?: boolean
}>()

const emit = defineEmits<{
  update: [Array<T>]
}>()

const translator = useI18nTranslator()

const searchableColumns = computed(() => columns.filter((c) => !!c.type && c.searchable === true))
const searchValue = ref('')
const filterableColumns = computed(() => columns.filter((c) => !!c.type && c.filterable !== false))
const filterValues: { [key: string]: any } = reactive({})

watch(
  [items, filterValues, searchValue],
  () => {
    let filtered = [...items]
    for (const filterValueKey in filterValues) {
      const filterValue = filterValues[filterValueKey]
      if (filterValue !== undefined && filterValue !== null && filterValue !== '') {
        const column = columns.find((c) => c.key === filterValueKey)
        if (column) {
          const type = typeof column.type === 'string' ? column.type : column.type?.type
          const key = column.key
          if (column.customRenderer) {
            if (filterValue.length > 0) {
              filtered = filtered.filter((row) => filterValue.includes(row[key]))
            }
          } else {
            switch (type) {
              case 'area':
              case 'currency':
              case 'number':
                filtered = filtered.filter(
                  (row) => row[key] >= filterValue[0] && row[key] <= filterValue[1]
                )
                break
              case 'boolean':
                filtered = filtered.filter((row) => row[key] === filterValue)
                break
              case 'date':
                filtered = filtered.filter(
                  (row) =>
                    new Date(row[key]).getTime() >= new Date(filterValue[0]).getTime() &&
                    new Date(row[key]).getTime() <= new Date(filterValue[1]).getTime()
                )
                break
              case 'html':
              case 'string':
              case 'url':
                if (filterValue.length > 0) {
                  filtered = filtered.filter((row) => filterValue.includes(translator(row[key])))
                }
                break
              case 'status':
                if (filterValue.length > 0) {
                  filtered = filtered.filter((row) => filterValue.includes(row[key]))
                }
                break
              case 'id':
                if (filterValue.length > 0) {
                  filtered = filtered.filter((row) => filterValue.includes(row[key]))
                }
                break
            }
          }
        }
      }
    }
    if (searchValue.value.trim()) {
      filtered = filtered.filter((row) => {
        let valid = false
        for (const searchableColumn of searchableColumns.value) {
          const key = searchableColumn.key
          const value = row[key]
          const type =
            typeof searchableColumn.type === 'string'
              ? searchableColumn.type
              : searchableColumn.type?.type
          const s = searchValue.value.toLowerCase()
          if (searchableColumn.customRenderer) {
            valid = renderNode(searchableColumn, value).toLowerCase().includes(s)
          } else {
            switch (type) {
              case 'html':
              case 'string':
              case 'url':
                valid = translator(value).toLowerCase().includes(s)
                break
              default:
                valid = value.toString().toLowerCase().includes(s)
                break
            }
          }
          if (valid) {
            return true
          }
        }
        return false
      })
    }

    emit('update', filtered)
  },
  {
    immediate: true
  }
)

const checkType = (type: VDataTableColumn['type'], ...checks: Array<string>) => {
  if (typeof type === 'string') {
    return checks.includes(type)
  } else {
    return checks.includes(type!.type)
  }
}

const renderNode = (column: VDataTableColumn, value: any) => {
  const nodeOrString =
    typeof column.customRenderer === 'function'
      ? column.customRenderer({ value })
      : column.customRenderer
  if (isVNode(nodeOrString)) {
    return nodeOrString.props?.data?.label || '-'
  } else {
    return nodeOrString || '-'
  }
}

const getCustomRendererOptions = (column: VDataTableColumn) => {
  let options: Array<{ value: any; title: string }> = []
  for (const item of items) {
    const value = item[column.key]
    if (!options.find((o) => o.value === value)) {
      options.push({ value, title: '' })
    }
  }
  if (!filterValues[column.key]) {
    filterValues[column.key] = []
  }
  return options
}

const getNumberRange = (column: VDataTableColumn) => {
  let min = Infinity
  let max = -Infinity
  let maxDecimals = 0
  for (const item of items) {
    const value = item[column.key]
    if (value < min) {
      min = value
    }
    if (value > max) {
      max = value
    }
    if (Math.floor(value) !== value) {
      const d = value.toString().split('.')[1].length || 0
      maxDecimals = Math.max(d, maxDecimals)
    }
  }
  if (!filterValues[column.key]) {
    filterValues[column.key] = [min, max]
  }
  return [min, max, 1 / Math.pow(10, maxDecimals)]
}

const getDateRange = (column: VDataTableColumn) => {
  let min = Infinity
  let max = -Infinity
  for (const item of items) {
    const value = new Date(item[column.key])
    if (value.getTime() < min) {
      min = value.getTime()
    }
    if (value.getTime() > max) {
      max = value.getTime()
    }
  }
  if (!filterValues[column.key]) {
    filterValues[column.key] = [min, max]
  }
  return [new Date(min), new Date(max)]
}

const getIdSelector = (column: VDataTableColumn) => {
  const values: Array<string> = []
  for (const item of items) {
    const value = item[column.key]
    if (!values.includes(value)) {
      values.push(value)
    }
  }
  if (!filterValues[column.key]) {
    filterValues[column.key] = []
  }
  return values
}

const getStringSelector = (column: VDataTableColumn) => {
  const values: Array<string> = []
  for (const item of items) {
    const value = translator(item[column.key])
    if (!values.includes(value)) {
      values.push(value)
    }
  }
  if (!filterValues[column.key]) {
    filterValues[column.key] = []
  }
  return values
}

onKeyStroke('Escape', (e) => {
  searchValue.value = ''
  e.preventDefault()
})
</script>
