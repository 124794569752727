<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <vis-select
          name="projectFieldId"
          :items="fields"
          :item-title="(f) => t(f.label)"
          item-value="id"
          :placeholder="$t('field')"
          :label="$t('field')"
          v-model="integrationField.projectFieldId"
        />
      </v-col>
      <v-col cols="12">
        <vis-text-field
          name="jsonPath"
          :placeholder="$t('jsonPath')"
          :label="$t('jsonPath')"
          v-model="integrationField.jsonPath"
        />
      </v-col>
      <v-col cols="12">
        <vis-switch
          name="isIdentifierKey"
          :placeholder="$t('isIdentifierKey')"
          :label="$t('isIdentifierKey')"
          v-model="integrationField.isIdentifierKey"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import useFormPartials from '@/composables/useFormPartials'
import type IntegrationField from '@/types/integrationField'
import type Field from '@/types/field'
import useI18nTranslator from '@/composables/useI18nTranslator'

const props = defineProps<{
  integrationField?: IntegrationField
  fields: Array<Field>
}>()
const emit = defineEmits<{
  submit: [integrationField: Partial<IntegrationField>]
}>()

const t = useI18nTranslator()

const integrationField = reactive<Partial<IntegrationField>>(
  props.integrationField || { isIdentifierKey: false }
)

const { formRef, submit } = useFormPartials(
  integrationField,
  (partial: Partial<IntegrationField>) => emit('submit', partial)
)
</script>
