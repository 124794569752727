import type { RouteRecordRaw } from 'vue-router'
import StatusUpdateView from '@/views/project/settings/statuses/StatusUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateStatusRoute: RouteRecordRaw = {
  path: ':statusId',
  name: 'updateStatus',
  component: StatusUpdateView,
  meta: {
    breadcrumb: 'updateStatus',
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default updateStatusRoute
