import { useI18n } from 'vue-i18n'

export default function useBuildingTableDefinitions(): VDataTableColumns {
  const { t } = useI18n()
  return [
    { title: t('id'), key: 'id', type: 'number', searchable: true, filterable: false },
    { title: t('name'), key: 'name', type: 'id', searchable: true, filterable: false },
    { title: t('slug'), key: 'slug', type: 'id', searchable: true, filterable: false },
    { title: t('active'), key: 'active', type: 'boolean', searchable: true, filterable: false },
    { title: t('createdAt'), key: 'createdAt', type: 'date', filterable: false },
    { title: t('updatedAt'), key: 'updatedAt', type: 'date', filterable: false },
    { title: '', key: 'actions', sortable: false }
  ]
}
