<template>
  <template v-if="route.name === 'projectStyleDesigner'">
    <vis-loader :loading="projectStyles.isFetching || patch.isFetching">
      <project-styles-table
        :project="project.data!"
        :projectStyles="projectStyles.data!"
        v-on="{
          add: check(KeycloakScope.PROJECT_UPDATE) ? onAdd : undefined,
          select: check(KeycloakScope.PROJECT_UPDATE) ? onSelect : undefined,
          delete: check(KeycloakScope.PROJECT_UPDATE) ? onDelete : undefined,
          patch: check(KeycloakScope.PROJECT_UPDATE) ? onPatch : undefined
        }"
      />
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useProjectStyleStore from '@/stores/projectStyle'
import ProjectStylesTable from '@/components/tables/ProjectStylesTable.vue'
import useProjectStore from '@/stores/project'
import type ProjectStyle from '@/types/projectStyle'
import { useI18n } from 'vue-i18n'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import useArtistResourceStore from '@/stores/artistResources'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { project } = useProjectStore()
const { artistResources, listArtistResources } = useArtistResourceStore()

const { projectStyles, patch, listProjectStyles, deleteProjectStyle, patchProjectStyles } =
  useProjectStyleStore()
projectStyles.data || listProjectStyles()

artistResources.data || listArtistResources()

const onAdd = () => router.push({ name: 'createProjectStyle' })

const onSelect = (projectStyle: ProjectStyle) =>
  router.push({
    name: 'updateProjectStyle',
    params: {
      projectStyleId: projectStyle.id
    }
  })

const onDelete = async (projectStyle: ProjectStyle) => {
  if (confirm(t('areYouSureYouWantToDeleteTheProjectStyle', [projectStyle.id]))) {
    await deleteProjectStyle(projectStyle.id)
    listProjectStyles()
  }
}

const onPatch = async (reordered: Array<Partial<ProjectStyle>>) => {
  await patchProjectStyles(reordered)
  listProjectStyles()
}
</script>
