<template>
  <vis-form @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12">
        <vis-text-field
          name="key"
          :placeholder="$t('key')"
          :label="$t('key')"
          v-model="field.key"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-select
          name="type"
          :placeholder="$t('type')"
          :label="$t('type')"
          v-model="field.type"
          :items="types"
        />
      </v-col>
      <v-col sm="6" cols="12">
        <vis-localized-input :input="field.label!" #default="{ input, locale }">
          <vis-text-field
            name="label"
            :placeholder="$t('label')"
            :label="$t('label')"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
      <v-col sm="6" cols="12">
        <vis-localized-input :input="field.prefix!" #default="{ input, locale }">
          <vis-text-field
            name="prefix"
            :placeholder="$t('prefix')"
            :label="$t('prefix')"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
      <v-col sm="6" cols="12">
        <vis-localized-input :input="field.suffix!" #default="{ input, locale }">
          <vis-text-field
            name="suffix"
            :placeholder="$t('suffix')"
            :label="$t('suffix')"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
    </v-row>
    <v-row v-for="(status, index) of statuses" :key="status.id">
      <v-col cols="3">
        <span :style="`color: ${status.textColor}`">{{ translator(status.label) }}</span>
      </v-col>
      <v-col cols="3">
        <vis-switch
          :name="`fieldStatusOptions[${index}][hide]`"
          :label="$t('hide')"
          v-model="statusOptions[status.id!].hide"
        />
      </v-col>
      <v-col cols="3">
        <vis-text-field
          :name="`fieldStatusOptions[${index}][customRenderer]`"
          :label="$t('customRenderer')"
          v-model="statusOptions[status.id!].customRenderer"
        />
      </v-col>
      <v-col cols="3">
        <vis-text-field
          :name="`fieldStatusOptions[${index}][hideRenderer]`"
          :label="$t('hideRenderer')"
          v-model="statusOptions[status.id!].hideRenderer"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import VisLocalizedInput from '../inputs/VisLocalizedInput.vue'
import useI18nTranslator from '@/composables/useI18nTranslator'
import { ProjectFieldType } from '@/types/field'
import type FieldStatusOption from '@/types/fieldStatusOption'
import type Status from '@/types/status'
import type Field from '@/types/field'

const props = defineProps<{
  field?: Field
  statusOptions?: Array<FieldStatusOption>
  statuses: Array<Status>
}>()

const emit = defineEmits<{
  submit: [payload: { field: Partial<Field>; statusOptions: Array<Partial<FieldStatusOption>> }]
}>()

const { t } = useI18n()
const translator = useI18nTranslator()

const field: Partial<Field> = reactive(props.field || {})
field.label = field.label || {}
field.prefix = field.prefix || {}
field.suffix = field.suffix || {}

const statusOptions = reactive(
  props.statuses.reduce<{ [id: number]: Partial<FieldStatusOption> }>((acc, s) => {
    const statusOption = (props.statusOptions || []).find(
      ({ projectStatusId }) => s.id! === projectStatusId
    )
    acc[s.id!] = statusOption || { projectStatusId: s.id }
    return acc
  }, {})
)

const types = Object.values(ProjectFieldType).map((type) => ({ value: type, title: t(type) }))

const submit = () => {
  emit('submit', { field, statusOptions: Object.values(statusOptions) })
}
</script>
