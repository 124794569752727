import type { RouteRecordRaw } from 'vue-router'
import ClientUpdateView from '@/views/client/ClientUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const clientUpdateRoute: RouteRecordRaw = {
  path: ':clientId',
  name: 'clientUpdate',
  component: ClientUpdateView,
  meta: {
    scopes: [KeycloakScope.CLIENT_UPDATE]
  }
}

export default clientUpdateRoute
