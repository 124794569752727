import type { RouteRecordRaw } from 'vue-router'
import WebhookIntegrationFieldCreateView from '@/views/project/integrations/webhookIntegrations/webhookIntegrationFields/WebhookIntegrationFieldCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createWebhookIntegrationFieldRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createWebhookIntegrationField',
  component: WebhookIntegrationFieldCreateView,
  meta: {
    breadcrumb: 'createWebhookIntegrationField',
    scopes: [KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  }
}

export default createWebhookIntegrationFieldRoute
