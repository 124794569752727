import type { RouteRecordRaw } from 'vue-router'
import UnitListView from '@/views/project/units/UnitListView.vue'
import createUnitRoute from './createUnit'
import updateUnitRoute from './updateUnit'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const unitsRoute: RouteRecordRaw = {
  path: '/units',
  name: 'units',
  component: UnitListView,
  meta: {
    breadcrumb: 'units',
    menu: {
      key: 'units',
      icon: 'mdi-floor-plan',
      name: 'units'
    },
    scopes: [KeycloakScope.UNIT_LIST]
  },
  children: [createUnitRoute, updateUnitRoute]
}

export default unitsRoute
