import type { RouteRecordRaw } from 'vue-router'
import ProjectIndexView from '@/views/project/ProjectIndexView.vue'
import dashboardRoute from './dashboard'
import buildingsRoute from './buildings'
import unitsRoute from './units'
import integrationsRoute from './integrations'
import projectSettingsRoute from './projectSettings'
import renderSettingsRoute from './renderSettings'
import toolsRoute from './tools'
import usersRoute from './users'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const projectIndexRoute: RouteRecordRaw = {
  path: '/',
  name: 'projectIndex',
  component: ProjectIndexView,
  meta: {
    needProject: true,
    breadcrumb: 'project',
    scopes: [KeycloakScope.PROJECT_READ]
  },
  children: [
    dashboardRoute,
    projectSettingsRoute,
    buildingsRoute,
    unitsRoute,
    usersRoute,
    toolsRoute,
    integrationsRoute,
    renderSettingsRoute
  ]
}

export default projectIndexRoute
