<template>
  <vis-loader :loading="interiorOption.isFetching">
    <interior-option-form @submit="onSubmit" />
  </vis-loader>
</template>

<script setup lang="ts">
import useInteriorOptionStore from '@/stores/interiorOption'
import InteriorOptionForm from '@/components/forms/InteriorOptionForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { interiorOption, listInteriorOptions, createInteriorOption } = useInteriorOptionStore()

const onSubmit = useSubmitThenRoute(
  createInteriorOption,
  (b) => ({ name: 'updateInteriorOption', params: { interiorOptionId: b.data!.id } }),
  () => listInteriorOptions()
)
</script>
