import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import type Asset from '@/types/asset'
import executeApi from '@/utils/executeApi'
import resetFetchResult from '@/utils/resetFetchResult'

const useAssetStore = defineStore('asset', () => {
  const api = useApi()

  const assetsUrl = ref('')
  const assetsApi = api<Array<Asset>>(assetsUrl).get().json()
  const assets: FetchResult<Array<Asset>> = reactiveFetchResult(assetsApi)

  const listAssets = async (): Promise<FetchResult<Array<Asset>>> => {
    assetsUrl.value = `/artistResource`
    await executeApi(assetsApi)
    return assets
  }

  const assetUrl = ref('/artistResource')
  const assetApi = api<Array<Asset>>(assetUrl).json()
  const asset: FetchResult<Asset> = reactiveFetchResult(assetApi)

  const readAsset = async (id?: number): Promise<FetchResult<Asset>> => {
    const idParam = id || asset.data?.id
    assetUrl.value = `/artistResource/${idParam}`
    await executeApi(assetApi.get())
    return asset
  }

  const createAsset = async (data: Partial<Asset>): Promise<FetchResult<Asset>> => {
    assetUrl.value = `/artistResource`
    await executeApi(assetApi.post(data), true)
    return asset
  }

  const updateAsset = async (data: Partial<Asset>, id?: number): Promise<FetchResult<Asset>> => {
    const idParam = id || asset.data?.id
    assetUrl.value = `/artistResource/${idParam}`
    await executeApi(assetApi.put(data), true)
    return asset
  }

  const deleteAsset = async (id?: number): Promise<FetchResult<Asset>> => {
    const idParam = id || asset.data?.id
    assetUrl.value = `/artistResource/${idParam}`
    await executeApi(assetApi.delete(), false)
    asset.data = null
    return asset
  }

  const patchUrl = ref('')
  const patchApi = api<Array<Asset>>(patchUrl).json()
  const patch: FetchResult<Array<Asset>> = reactiveFetchResult(patchApi)

  const patchAssets = async (data: Array<Partial<Asset>>): Promise<FetchResult<Array<Asset>>> => {
    patchUrl.value = `/artistResource`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const $reset = () => {
    resetFetchResult(assets)
    resetFetchResult(asset)
  }

  return {
    listAssets,
    readAsset,
    createAsset,
    updateAsset,
    deleteAsset,
    patchAssets,
    assets,
    asset,
    patch,
    $reset
  }
})

export default useAssetStore
