import type { RouteRecordRaw } from 'vue-router'
import UserCreateView from '@/views/project/users/UserCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createUserRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createUser',
  component: UserCreateView,
  meta: {
    breadcrumb: 'createUser',
    scopes: [KeycloakScope.USER_CREATE]
  }
}

export default createUserRoute
