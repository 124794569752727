<template>
  <vis-loader
    :loading="
      projectVersions.isFetching ||
      buildings.isFetching ||
      buildingVersions.isFetching ||
      units.isFetching ||
      unitVersions.isFetching
    "
  >
    <v-table density="compact" class="w-100">
      <thead>
        <tr>
          <th>{{ $t('project') }}</th>
          <th>{{ $t('building') }}</th>
          <th>{{ $t('unit') }}</th>
          <th>{{ $t('interiorOption') }}</th>
          <th>{{ $t('version') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(buildingVersionData, index0) of versionsArray" :key="index0">
          <template v-for="(unitVersionData, index1) of buildingVersionData.units" :key="index1">
            <template
              v-for="(ioVersionData, index2) of unitVersionData.interiorOptions"
              :key="index2"
            >
              <template
                v-for="(history, index3) of ioVersionData.history.length > 0
                  ? ioVersionData.history
                  : [false]"
                :key="index3"
              >
                <tr>
                  <td
                    v-if="index0 === 0 && index1 === 0 && index2 === 0 && index3 === 0"
                    :rowspan="getProjectRowCount(versionsArray)"
                  >
                    {{ project.data!.name }}
                    <template v-for="(v, i) of projectVersionsArray" :key="i">
                      <br />
                      <v-chip density="compact" :color="i === 0 ? 'success' : 'grey'">{{
                        v.version
                      }}</v-chip>
                      @
                      {{ new Date(v.date).toLocaleString() }}
                    </template>
                  </td>
                  <td
                    v-if="index1 === 0 && index2 === 0 && index3 === 0"
                    :rowspan="getBuildingRowCount(buildingVersionData)"
                  >
                    {{
                      typeof buildingVersionData.building === 'string'
                        ? buildingVersionData.building
                        : buildingVersionData.building.name
                    }}
                    <template v-for="(v, i) of buildingVersionData.history" :key="i">
                      <br />
                      <v-chip density="compact" :color="i === 0 ? 'success' : 'grey'">{{
                        v.version
                      }}</v-chip>
                      @
                      {{ new Date(v.date).toLocaleString() }}
                    </template>
                  </td>
                  <td
                    v-if="index2 === 0 && index3 === 0"
                    :rowspan="getUnitRowCount(unitVersionData)"
                  >
                    {{
                      typeof unitVersionData.unit === 'string'
                        ? unitVersionData.unit
                        : unitVersionData.unit.title
                    }}
                  </td>
                  <td v-if="index3 === 0" :rowspan="getInteriorOptionsRowCount(ioVersionData)">
                    {{
                      typeof ioVersionData.interiorOption === 'string'
                        ? ioVersionData.interiorOption
                        : translator(ioVersionData.interiorOption.title)
                    }}
                  </td>
                  <td v-if="history === false">&nbsp;</td>
                  <td v-else>
                    <v-chip density="compact" :color="index3 === 0 ? 'success' : 'grey'">{{
                      (history as VersionHistoryEntry).version
                    }}</v-chip>
                    @
                    {{ new Date((history as VersionHistoryEntry).date).toLocaleString() }}
                  </td>
                </tr>
              </template>
            </template>
          </template>
        </template>
        <tr></tr>
      </tbody>
    </v-table>
  </vis-loader>
</template>

<script setup lang="ts">
import { computed, type Ref } from 'vue'
import useProjectVersionStore from '@/stores/projectVersion'
import useBuildingStore from '@/stores/building'
import useBuildingVersionStore from '@/stores/buildingVersion'
import useUnitStore from '@/stores/unit'
import useUnitVersionStore from '@/stores/unitVersion'
import type Building from '@/types/building'
import type Unit from '@/types/unit'
import useInteriorOptionStore from '@/stores/interiorOption'
import type InteriorOption from '@/types/interiorOption'
import useI18nTranslator from '@/composables/useI18nTranslator'
import useProjectStore from '@/stores/project'

const { project } = useProjectStore()

const { projectVersions, listProjectVersions } = useProjectVersionStore()
projectVersions.data || listProjectVersions()

const { interiorOptions, listInteriorOptions } = useInteriorOptionStore()
interiorOptions.data || listInteriorOptions()

const { buildings, listBuildings } = useBuildingStore()
const { buildingVersions, listBuildingVersions } = useBuildingVersionStore()
buildings.data || listBuildings()
buildingVersions.data || listBuildingVersions()

const { units, listUnits } = useUnitStore()
const { unitVersions, listUnitVersions } = useUnitVersionStore()
units.data || listUnits()
unitVersions.data || listUnitVersions()

const translator = useI18nTranslator()

type VersionHistoryEntry = { version: string; date: Date }
type VersionHistory = Array<VersionHistoryEntry>

const projectVersionsArray: Ref<VersionHistory> = computed(() => {
  if (projectVersions.data) {
    const array = projectVersions.data!.map(({ version, createdAt }) => ({
      version,
      date: new Date(createdAt)
    }))
    array.sort((a, b) => {
      return b.date.getTime() - a.date.getTime()
    })
    return array
  } else {
    return []
  }
})

const versionsArray: Ref<
  Array<{
    building: Building | string
    history: VersionHistory
    units: Array<{
      unit: Unit | string
      interiorOptions: Array<{ interiorOption: InteriorOption | string; history: VersionHistory }>
    }>
  }>
> = computed(() => {
  if (
    buildingVersions.data &&
    buildings.data &&
    unitVersions.data &&
    units.data &&
    interiorOptions.data
  ) {
    // buildings
    const buildingsArray: Array<{
      building: Building | string
      history: VersionHistory
      units: Array<{
        unit: Unit | string
        interiorOptions: Array<{ interiorOption: InteriorOption | string; history: VersionHistory }>
      }>
    }> = buildings.data!.map((building) => ({ building, history: [], units: [] }))
    for (const buildingVersion of buildingVersions.data!) {
      let bIndex = buildingsArray.findIndex((b) => {
        return (
          (typeof b.building === 'string' && b.building === buildingVersion.slug) ||
          (typeof b.building !== 'string' && b.building.slug === buildingVersion.slug)
        )
      })
      if (bIndex < 0) {
        bIndex = buildingsArray.length
        buildingsArray.push({
          building: buildingVersion.slug,
          history: [],
          units: []
        })
      }

      buildingsArray[bIndex].history.push({
        version: buildingVersion.version,
        date: new Date(buildingVersion.createdAt)
      })
    }

    //units
    const unitsArray: Array<{
      unit: Unit | string
      interiorOptions: Array<{ interiorOption: InteriorOption | string; history: VersionHistory }>
    }> = units.data!.map((unit) => ({
      unit,
      interiorOptions: interiorOptions.data!.map((interiorOption) => ({
        interiorOption,
        history: []
      }))
    }))
    for (const unitVersion of unitVersions.data!) {
      let uIndex = unitsArray.findIndex((u) => {
        return (
          (typeof u.unit === 'string' && u.unit === unitVersion.unitId) ||
          (typeof u.unit !== 'string' && u.unit.unitId === unitVersion.unitId)
        )
      })
      if (uIndex < 0) {
        uIndex = unitsArray.length
        unitsArray.push({
          unit: unitVersion.unitId,
          interiorOptions: interiorOptions.data!.map((interiorOption) => ({
            interiorOption,
            history: []
          }))
        })
      }

      let ioIndex = unitsArray[uIndex].interiorOptions.findIndex((io) => {
        return (
          (typeof io.interiorOption === 'string' &&
            io.interiorOption === unitVersion.interiorOptionGuid) ||
          (typeof io.interiorOption !== 'string' &&
            io.interiorOption.guid === unitVersion.interiorOptionGuid)
        )
      })
      if (ioIndex < 0) {
        ioIndex = unitsArray[uIndex].interiorOptions.length
        unitsArray[uIndex].interiorOptions.push({
          interiorOption: unitVersion.interiorOptionGuid,
          history: []
        })
      }
      unitsArray[uIndex].interiorOptions[ioIndex].history.push({
        version: unitVersion.version,
        date: new Date(unitVersion.createdAt)
      })
    }

    // bind
    const unknowns = []
    for (const unit of unitsArray) {
      let unknown = false
      if (!(typeof unit.unit === 'string')) {
        const unitBuildingId = unit.unit.buildingId
        let bIndex = buildingsArray.findIndex(
          (b) => typeof b.building !== 'string' && b.building.id === unitBuildingId
        )
        if (bIndex < 0) {
          unknown = true
        } else {
          buildingsArray[bIndex].units.push(unit)
        }
      } else {
        unknown = true
      }
      if (unknown) {
        unknowns.push(unit)
      }
    }

    // sorts
    buildingsArray.sort((a, b) => {
      if (typeof a.building === 'string' && typeof b.building === 'string') {
        return a.building.localeCompare(b.building)
      } else if (typeof a.building !== 'string' && typeof b.building !== 'string') {
        return a.building.name.localeCompare(b.building.name)
      } else if (typeof a.building === 'string') {
        return -1
      } else {
        return 1
      }
    })
    if (unknowns.length > 0) {
      buildingsArray.push({ building: 'unknown', history: [], units: unknowns })
    }

    for (let i = 0; i < buildingsArray.length; i++) {
      buildingsArray[i].units.sort((a, b) => {
        if (typeof a.unit === 'string' && typeof b.unit === 'string') {
          return a.unit.localeCompare(b.unit)
        } else if (typeof a.unit !== 'string' && typeof b.unit !== 'string') {
          return a.unit.title.localeCompare(b.unit.title)
        } else if (typeof a.unit === 'string') {
          return -1
        } else {
          return 1
        }
      })
      for (let j = 0; j < buildingsArray[i].units.length; j++) {
        buildingsArray[i].units[j].interiorOptions.sort((a, b) => {
          if (typeof a.interiorOption === 'string' && typeof b.interiorOption === 'string') {
            return a.interiorOption.localeCompare(b.interiorOption)
          } else if (typeof a.interiorOption !== 'string' && typeof b.interiorOption !== 'string') {
            return translator(a.interiorOption.title).localeCompare(
              translator(b.interiorOption.title)
            )
          } else if (typeof a.interiorOption === 'string') {
            return -1
          } else {
            return 1
          }
        })
        for (let k = 0; k < buildingsArray[i].units[j].interiorOptions.length; k++) {
          buildingsArray[i].units[j].interiorOptions[k].history.sort((a, b) => {
            return b.date.getTime() - a.date.getTime()
          })
        }
      }

      buildingsArray[i].history.sort((a, b) => {
        return b.date.getTime() - a.date.getTime()
      })
    }

    return buildingsArray
  } else {
    return []
  }
})

const getProjectRowCount = (
  projectVersions: Array<{
    building: Building | string
    history: VersionHistory
    units: Array<{
      unit: Unit | string
      interiorOptions: Array<{ interiorOption: InteriorOption | string; history: VersionHistory }>
    }>
  }>
) => {
  let rowCount = 0
  for (const b of projectVersions) {
    for (const u of b.units) {
      for (const io of u.interiorOptions) {
        rowCount += Math.max(io.history.length, 1)
      }
    }
  }
  return rowCount
}

const getBuildingRowCount = (buildingVersion: {
  building: Building | string
  history: VersionHistory
  units: Array<{
    unit: Unit | string
    interiorOptions: Array<{ interiorOption: InteriorOption | string; history: VersionHistory }>
  }>
}) => {
  let rowCount = 0
  for (const u of buildingVersion.units) {
    for (const io of u.interiorOptions) {
      rowCount += Math.max(io.history.length, 1)
    }
  }
  return rowCount
}

const getUnitRowCount = (unitVersion: {
  unit: Unit | string
  interiorOptions: Array<{ interiorOption: InteriorOption | string; history: VersionHistory }>
}) => {
  let rowCount = 0
  for (const io of unitVersion.interiorOptions) {
    rowCount += Math.max(io.history.length, 1)
  }
  return rowCount
}

const getInteriorOptionsRowCount = (unitVersion: {
  interiorOption: InteriorOption | string
  history: VersionHistory
}) => {
  return Math.max(unitVersion.history.length, 1)
}
</script>

<style scoped>
th {
  font-weight: bold !important;
}
tr > * {
  border-right: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}
tr > *:last-child {
  border-right: none;
}
</style>
