import { ref } from 'vue'
import { defineStore } from 'pinia'
import useKeycloakProvider from '@/composables/useKeycloakProvider'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const useAuthStore = defineStore('auth', () => {
  const keycloakProvider = useKeycloakProvider()

  const ready = ref<boolean>(false)
  const authenticated = ref<boolean>(false)
  const username = ref<string | null>(null)
  const firstname = ref<string | null>(null)
  const lastname = ref<string | null>(null)
  const email = ref<string | null>(null)
  const token = ref<string | null>(null)
  const refreshToken = ref<string | null>(null)
  const scopes = ref<Array<KeycloakScope>>([])
  const isAdmin = ref<boolean | null>(null)

  const setFromProvider = () => {
    authenticated.value = keycloakProvider.keycloak.authenticated || false
    username.value = keycloakProvider.keycloak.idTokenParsed?.preferred_username
    firstname.value = keycloakProvider.keycloak.idTokenParsed?.given_name
    lastname.value = keycloakProvider.keycloak.idTokenParsed?.family_name
    email.value = keycloakProvider.keycloak.idTokenParsed?.email
    token.value = keycloakProvider.keycloak.token || null
    refreshToken.value = keycloakProvider.keycloak.refreshToken || null
    scopes.value = keycloakProvider.keycloak.tokenParsed!.scope.split(' ')
    isAdmin.value = keycloakProvider.keycloak.hasRealmRole('QiAdmin')
  }

  const $reset = () => {}

  return {
    setFromProvider,
    ready,
    authenticated,
    username,
    firstname,
    lastname,
    email,
    token,
    refreshToken,
    scopes,
    isAdmin,
    $reset
  }
})

export default useAuthStore
