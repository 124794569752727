import { h } from 'vue'
import type Client from '@/types/client'
import { useI18n } from 'vue-i18n'

export default function useProjectTableDefinitions(clients: Array<Client>): VDataTableColumns {
  const { t } = useI18n()
  return [
    { title: t('id'), key: 'id', type: 'number', searchable: true, filterable: false },
    {
      title: t('client'),
      key: 'clientId',
      type: 'number',
      customRenderer: ({ value }: any) => {
        const client = clients?.find((c) => c.id === value)
        return client ? h('span', { data: { label: client.name } }, [client.name]) : h('span', '-')
      }
    },
    { title: t('name'), key: 'name', type: 'id', searchable: true, filterable: false },
    { title: t('slug'), key: 'slug', type: 'id', searchable: true, filterable: false },
    { title: t('createdAt'), key: 'createdAt', type: 'date', filterable: false },
    { title: t('updatedAt'), key: 'updatedAt', type: 'date', filterable: false },
    { title: '', key: 'actions', sortable: false }
  ]
}
