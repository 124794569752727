import type { RouteRecordRaw } from 'vue-router'
import IfcUploadsView from '@/views/project/tools/IfcUploadsView.vue'

const ifcUploadsRoute: RouteRecordRaw = {
  path: 'ifcUploads',
  name: 'ifcUploads',
  component: IfcUploadsView,
  meta: {
    breadcrumb: 'ifcUploads'
    // scopes: [KeycloakScope.USER_CREATE],
    // menu: {
    //   key: 'ifcUploads',
    //   icon: 'mdi-cube',
    //   name: 'ifcUploads'
    // }
  }
}

export default ifcUploadsRoute
