import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Integration from '@/types/integration'
import executeApi from '@/utils/executeApi'

const useIntegrationStore = defineStore('integration', () => {
  const api = useApi()

  const integrationsUrl = ref('/project')
  const integrationsApi = api<Array<Integration>>(integrationsUrl).get().json()
  const integrations: FetchResult<Array<Integration>> =
    reactiveFetchResult(integrationsApi)
  const listIntegrations = async (
    projectId?: number
  ): Promise<FetchResult<Array<Integration>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    integrationsUrl.value = `/project/${projectParam}/integration`
    await executeApi(integrationsApi)
    return integrations
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<Integration>>(patchUrl).json()
  const patch: FetchResult<Array<Integration>> = reactiveFetchResult(patchApi)
  const patchIntegrations = async (
    data: Array<Partial<Integration>>,
    projectId?: number
  ): Promise<FetchResult<Array<Integration>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/integration`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const integrationUrl = ref('/project')
  const integrationApi = api<Array<Integration>>(integrationUrl).json()
  const integration: FetchResult<Integration> = reactiveFetchResult(integrationApi)
  const readIntegration = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Integration>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || integration.data?.id
    integrationUrl.value = `/project/${projectParam}/integration/${idParam}`
    await executeApi(integrationApi.get())
    return integration
  }

  const createIntegration = async (
    data: Partial<Integration>,
    projectId?: number
  ): Promise<FetchResult<Integration>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    integrationUrl.value = `/project/${projectParam}/integration`
    await executeApi(integrationApi.post(data), true)
    return integration
  }

  const updateIntegration = async (
    data: Partial<Integration>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Integration>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || integration.data?.id
    integrationUrl.value = `/project/${projectParam}/integration/${idParam}`
    await executeApi(integrationApi.put(data), true)
    return integration
  }

  const deleteIntegration = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Integration>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || integration.data?.id
    integrationUrl.value = `/project/${projectParam}/integration/${idParam}`
    await executeApi(integrationApi.delete(), false)
    integration.data = null
    return integration
  }

  const $reset = () => {
    resetFetchResult(integrations)
    resetFetchResult(integration)
  }

  return {
    listIntegrations,
    readIntegration,
    createIntegration,
    updateIntegration,
    deleteIntegration,
    patchIntegrations,
    integrations,
    integration,
    patch,
    $reset
  }
})

export default useIntegrationStore
