<template>
  <v-row class="w-100">
    <v-col cols="12">
      <v-alert border="top" colored-border type="info" color="primary" elevation="2">
        <p>{{ $t('someGuidelinesWhenImportingDataFromCsvFiles') }}:</p>
        <ul>
          <li>
            {{ $t('theStatusFieldShouldBeOneOfTheFollowingValues', [validStatuses]) }}
          </li>
          <li>
            {{ $t('numericFieldsShouldNotHaveGroupingSeparatorsAndUseAPeriodAsADecimalSeparator') }}
          </li>
          <li>{{ $t('yesNoFieldsShouldBeEitherYesNoOrEmpty') }}</li>
          <li>{{ $t('dateFieldsShouldBeInTheFormatYYYYMMDD') }}</li>
          <li>
            {{ $t('urlFieldsCanBePlainUrlsOrInTheFormatShortUrlToHaveShortNames') }}
          </li>
        </ul>
      </v-alert>
    </v-col>
    <v-col sm="4" cols="12">
      <vis-select
        v-model="delimiter"
        :items="[',', ';']"
        :placeholder="$t('delimiter')"
        :label="$t('delimiter')"
      />
    </v-col>
    <v-col sm="4" cols="12">
      <vis-select
        v-model="newline"
        :items="['\\n', '\\r\\n']"
        :placeholder="$t('newline')"
        :label="$t('newline')"
      />
    </v-col>
    <v-col sm="4" cols="12">
      <vis-file-input
        accept="text/csv"
        @change="change"
        :placeholder="$t('csv')"
        :label="$t('csv')"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { ref, inject, type Ref } from 'vue'
import Papa from 'papaparse'
import type Status from '@/types/status'
import useI18nTranslator from '@/composables/useI18nTranslator'
import { useI18n } from 'vue-i18n'

const props = defineProps<{
  statuses: Array<Status>
}>()

const emit = defineEmits<{
  uploaded: [data: Array<{ [key: string]: string }>]
}>()

const translator = useI18nTranslator()
const { t } = useI18n()

const validStatuses = props.statuses.map((s) => `'${s.id}', '${translator(s.label)}'`).join(', ')

const backLabel = inject<Ref<string>>('backLabel')
const backHandler = inject<Ref<undefined | (() => void)>>('backHandler')
backLabel!.value = ''
backHandler!.value = undefined

const nextLabel = inject<Ref<string>>('nextLabel')
const nextHandler = inject<Ref<undefined | (() => void)>>('nextHandler')
nextLabel!.value = 'import'
nextHandler!.value = undefined

const delimiter = ref(',')
const newline = ref('\\n')

const change = (e: Event) => {
  const files = (e.target as HTMLInputElement).files
  if (files && files.item(0)) {
    const file = files.item(0)
    Papa.parse(file as any, {
      delimiter: delimiter.value,
      newline: newline.value === '\\n' ? '\n' : '\r\n',
      header: true,
      skipEmptyLines: true,
      complete(results) {
        if (results.errors.length === 0) {
          if (results.data.length === 0) {
            alert(t('emptyCsv'))
          } else {
            nextHandler!.value = () => {
              emit('uploaded', results.data as any)
            }
          }
        } else {
          alert(t('somethingWentWrongWithParsingTheCsvFile'))
        }
      }
    })
  }
}
</script>
