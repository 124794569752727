import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type ProjectStyle from '@/types/projectStyle'
import executeApi from '@/utils/executeApi'

const useProjectStyleStore = defineStore('projectStyle', () => {
  const api = useApi()

  const projectStylesUrl = ref('/project')
  const projectStylesApi = api<Array<ProjectStyle>>(projectStylesUrl).get().json()
  const projectStyles: FetchResult<Array<ProjectStyle>> = reactiveFetchResult(projectStylesApi)
  const listProjectStyles = async (
    projectId?: number
  ): Promise<FetchResult<Array<ProjectStyle>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    projectStylesUrl.value = `/project/${projectParam}/style`
    await executeApi(projectStylesApi)
    return projectStyles
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<ProjectStyle>>(patchUrl).json()
  const patch: FetchResult<Array<ProjectStyle>> = reactiveFetchResult(patchApi)
  const patchProjectStyles = async (
    data: Array<Partial<ProjectStyle>>,
    projectId?: number
  ): Promise<FetchResult<Array<ProjectStyle>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/style`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const projectStyleUrl = ref('/project')
  const projectStyleApi = api<Array<ProjectStyle>>(projectStyleUrl).json()
  const projectStyle: FetchResult<ProjectStyle> = reactiveFetchResult(projectStyleApi)
  const readProjectStyle = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<ProjectStyle>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || projectStyle.data?.id
    projectStyleUrl.value = `/project/${projectParam}/style/${idParam}`
    await executeApi(projectStyleApi.get())
    return projectStyle
  }

  const createProjectStyle = async (
    data: Partial<ProjectStyle>,
    projectId?: number
  ): Promise<FetchResult<ProjectStyle>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    projectStyleUrl.value = `/project/${projectParam}/style`
    await executeApi(projectStyleApi.post(data), true)
    return projectStyle
  }

  const updateProjectStyle = async (
    data: Partial<ProjectStyle>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<ProjectStyle>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || projectStyle.data?.id
    projectStyleUrl.value = `/project/${projectParam}/style/${idParam}`
    await executeApi(projectStyleApi.put(data), true)
    return projectStyle
  }

  const deleteProjectStyle = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<ProjectStyle>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || projectStyle.data?.id
    projectStyleUrl.value = `/project/${projectParam}/style/${idParam}`
    await executeApi(projectStyleApi.delete(), false)
    projectStyle.data = null
    return projectStyle
  }

  const $reset = () => {
    resetFetchResult(projectStyles)
    resetFetchResult(projectStyle)
  }

  return {
    listProjectStyles,
    readProjectStyle,
    createProjectStyle,
    updateProjectStyle,
    deleteProjectStyle,
    patchProjectStyles,
    projectStyles,
    projectStyle,
    patch,
    $reset
  }
})

export default useProjectStyleStore
