<template>
  <vis-loader :loading="projectStyle.isFetching">
    <ProjectStylesDesignerForm :artistResources="artistResources.data!" @submit="onSubmit" />
  </vis-loader>
</template>

<script setup lang="ts">
import useProjectStyleStore from '@/stores/projectStyle'
import ProjectStylesDesignerForm from '@/components/forms/ProjectStylesDesignerForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useArtistResourceStore from '@/stores/artistResources'

const { projectStyle, listProjectStyles, createProjectStyle } = useProjectStyleStore()
const { artistResources, listArtistResources } = useArtistResourceStore()
artistResources.data || listArtistResources()

const onSubmit = useSubmitThenRoute(
  createProjectStyle,
  (b) => ({ name: 'updateProjectStyle', params: { projectStyleId: b.data!.id } }),
  () => listProjectStyles()
)
</script>
