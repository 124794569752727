import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

const useContextStore = defineStore('context', () => {
  const projectId = useLocalStorage<number | null>('context.projectId', null, {
    serializer: { read: (raw) => parseInt(raw), write: (v) => (v ? v.toString() : '') }
  })
  const selectProjectId = (id: number | null) => (projectId.value = id)

  const $reset = () => {}

  return {
    projectId,
    selectProjectId,
    $reset
  }
})

export default useContextStore
