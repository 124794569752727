<template>
  <vis-loader :loading="status.isFetching">
    <vis-sheet>
      <status-form :status="status.data!" @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useStatusStore from '@/stores/status'
import StatusForm from '@/components/forms/StatusForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { status, listStatuses, readStatus, updateStatus } = useStatusStore()

const onSubmit = useSubmitThenRoute(updateStatus, { name: 'statuses' }, () => listStatuses())

const statusId = useRouteId('statusId')
readStatus(statusId)
</script>
