import type { RouteRecordRaw } from 'vue-router'
import MarketingIntegrationView from '@/views/project/integrations/MarketingIntegrationView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const marketingIntegrationRoute: RouteRecordRaw = {
  path: 'marketingIntegration',
  name: 'marketingIntegration',
  component: MarketingIntegrationView,
  meta: {
    breadcrumb: 'marketingIntegration',
    menu: {
      key: 'marketingIntegration',
      icon: 'mdi-bullhorn',
      name: 'marketingIntegration'
    },
    scopes: [KeycloakScope.PROJECT_UPDATE, KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  }
}

export default marketingIntegrationRoute
