<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col cols="12">
        <v-label class="small">{{ $t('dateTime') }}</v-label>
        <vue-date-picker
          :format="
            (date: Date) =>
              date.toLocaleDateString() + ' ' + date.toLocaleTimeString().substring(0, 5)
          "
          utc
          name="dateTime"
          v-model="timeOfDayOption.dateTime"
        />
      </v-col>
      <v-col cols="12">
        <vis-text-field
          name="northDirectionAngle"
          :placeholder="$t('northDirectionAngle')"
          :label="$t('northDirectionAngle')"
          v-model="timeOfDayOption.northDirectionAngle"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import useFormPartials from '@/composables/useFormPartials'
import type TimeOfDayOption from '@/types/timeOfDayOption'
import { reactive } from 'vue'

const props = defineProps<{ timeOfDayOption?: TimeOfDayOption }>()
const emit = defineEmits<{
  submit: [timeOfDayOption: Partial<TimeOfDayOption>]
}>()

const timeOfDayOption = reactive<Partial<TimeOfDayOption>>(props.timeOfDayOption || {})

const { formRef, submit } = useFormPartials(
  timeOfDayOption,
  (partial: Partial<TimeOfDayOption>) => emit('submit', partial),
  'dateTime'
)
</script>
