import type { RouteRecordRaw } from 'vue-router'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'
import ProjectStylesListView from '@/views/project/renderSettings/projectStylesDesigner/ProjectStylesListView.vue'
import createProjectStyleRoute from './createProjectStyle'
import updateProjectStyleRoute from './updateProjectStyle'

const projectStylesDesigner: RouteRecordRaw = {
  path: 'projectStyles',
  name: 'projectStyleDesigner',
  component: ProjectStylesListView,
  meta: {
    breadcrumb: 'projectStyleDesigner',
    menu: {
      key: 'projectStyleDesigner',
      icon: 'mdi-palette-swatch-outline',
      name: 'projectStyleDesigner',
      exact: false
    },
    scopes: [KeycloakScope.ASSETS_LIST]
  },
  children: [createProjectStyleRoute, updateProjectStyleRoute]
}

export default projectStylesDesigner
