import type { RouteRecordRaw } from 'vue-router'
import UnitUpdateView from '@/views/project/units/UnitUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateUnitRoute: RouteRecordRaw = {
  path: ':unitId',
  name: 'updateUnit',
  component: UnitUpdateView,
  meta: {
    breadcrumb: 'updateUnit',
    scopes: [KeycloakScope.UNIT_UPDATE]
  }
}

export default updateUnitRoute
