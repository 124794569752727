import type { RouteRecordRaw } from 'vue-router'
import WebhookIntegrationCreateView from '@/views/project/integrations/webhookIntegrations/WebhookIntegrationCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createWebhookIntegrationRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createWebhookIntegration',
  component: WebhookIntegrationCreateView,
  meta: {
    breadcrumb: 'createWebhookIntegration',
    scopes: [KeycloakScope.PROJECT_UPDATE_INTEGRATION]
  }
}

export default createWebhookIntegrationRoute
