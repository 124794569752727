import type { RouteRecordRaw } from 'vue-router'
import TimeOfDayOptionUpdateView from '@/views/project/renderSettings/timeOfDayOptions/TimeOfDayOptionUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateTimeOfDayOptionRoute: RouteRecordRaw = {
  path: ':timeOfDayOptionId',
  name: 'updateTimeOfDayOption',
  component: TimeOfDayOptionUpdateView,
  meta: {
    breadcrumb: 'updateTimeOfDayOption',
    scopes: [KeycloakScope.ASSETS_UPDATE]
  }
}

export default updateTimeOfDayOptionRoute
