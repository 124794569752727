<template>
  <vis-loader
    :loading="fields.isFetching || buildings.isFetching || units.isFetching || statuses.isFetching"
  >
    <vis-sheet>
      <v-row>
        <v-col cols="2">
          <vis-btn v-if="backLabel" class="w-100" :disabled="!backHandler" @click="backHandler">{{
            backLabel
          }}</vis-btn>
        </v-col>
        <v-col cols="8">
          <v-stepper :model-value="getStepperValue()" flat :height="44">
            <v-stepper-header>
              <template v-for="(step, index) of stepperItems" :key="index">
                <v-divider v-if="index !== 0" />
                <v-stepper-item
                  :complete="getStepperValue() > index + 1"
                  :title="step"
                  :value="index + 1"
                ></v-stepper-item>
              </template>
            </v-stepper-header>
          </v-stepper>
        </v-col>
        <v-col cols="2">
          <vis-btn v-if="nextLabel" class="w-100" :disabled="!nextHandler" @click="nextHandler">{{
            nextLabel
          }}</vis-btn>
        </v-col>
      </v-row>
    </vis-sheet>
    <import-step-upload-csv v-if="!data" :statuses="statuses.data!" @uploaded="uploaded" />
    <import-step-create-bindings
      v-else-if="data && !bindings && !buildingsCheck"
      :data="data"
      :fields="fields.data!"
      @bounded="bounded"
      @back="
        () => {
          data = undefined
        }
      "
    />
    <import-step-check-buildings
      v-else-if="keyedData && bindings && !buildingsCheck"
      :data="keyedData"
      :buildings="buildings.data!"
      @check="checkBuilding"
      @back="
        () => {
          keyedData = undefined
          bindings = undefined
        }
      "
    />
    <import-step-check-units
      v-else-if="fullKeyData && !unitsCheck"
      :previous="units.data!"
      :data="fullKeyData"
      @commit="commit"
      @back="
        () => {
          keyedData = undefined
          bindings = undefined
          preppedKeyData = undefined
          fullKeyData = undefined
          buildingsCheck = undefined
        }
      "
    />
    <import-step-result
      v-else-if="fullKeyData && unitsCheck"
      :previous="units.data!"
      :data="unitsCheck"
      @again="
        () => {
          data = undefined
          keyedData = undefined
          bindings = undefined
          preppedKeyData = undefined
          fullKeyData = undefined
          buildingsCheck = undefined
          unitsCheck = undefined
        }
      "
    />
  </vis-loader>
</template>

<script setup lang="ts">
import { ref, watch, provide } from 'vue'
import ImportStepUploadCsv from '@/components/import/ImportStepUploadCsv.vue'
import ImportStepCreateBindings from '@/components/import/ImportStepCreateBindings.vue'
import ImportStepCheckBuildings from '@/components/import/ImportStepCheckBuildings.vue'
import ImportStepCheckUnits from '@/components/import/ImportStepCheckUnits.vue'
import ImportStepResult from '@/components/import/ImportStepResult.vue'
import type Building from '@/types/building'
import useFieldStore from '@/stores/field'
import type Field from '@/types/field'
import useBuildingStore from '@/stores/building'
import slugify from '@/utils/slugify'
import useUnitRowStore from '@/stores/unitRow'
import useStatusStore from '@/stores/status'

const { fields, listFields } = useFieldStore()
fields.data || listFields()

const { buildings, listBuildings, patchBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { units, listUnits } = useUnitRowStore()
units.data || listUnits()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { patchUnits } = useUnitRowStore()

const backLabel = ref('')
const nextLabel = ref('Next')
const backHandler = ref(undefined)
const nextHandler = ref(undefined)

provide('backLabel', backLabel)
provide('nextLabel', nextLabel)
provide('backHandler', backHandler)
provide('nextHandler', nextHandler)

const stepperItems = ['upload csv', 'create bindings', 'create buildings', 'import units', 'review']

const getStepperValue = () => {
  if (!data.value) {
    return 1
  } else if (!bindings.value && !keyedData.value) {
    return 2
  } else if (!preppedKeyData.value && !fullKeyData.value && !buildingsCheck.value) {
    return 3
  } else if (!unitsCheck.value) {
    return 4
  } else {
    return 5
  }
}

/**
 * Uploading step
 */
const data = ref<Array<{ [key: string]: string }>>()
const uploaded = (uploaded: Array<{ [key: string]: string }>) => {
  data.value = uploaded
}

/**
 * Binding step
 */
const bindings = ref<Array<Field & { header: string; code: string | null; check: string }>>()
const keyedData = ref<Array<{ [key: string]: any }>>()
const bounded = (data: Array<Field & { header: string; code: string | null; check: string }>) => {
  bindings.value = data
}
watch([data, bindings], () => {
  if (data.value && bindings.value) {
    const keyedDataArray: Array<{ [key: string]: any }> = []
    for (const row of data.value) {
      const keyedRow: { [key: string]: any } = {}
      for (const key in row) {
        let fieldKey: string
        let objectKey: string | undefined
        const binding = bindings.value.find((b) => b.header === key)
        if (binding) {
          fieldKey = binding.key
          if (binding.code) {
            objectKey = binding.code
          }
          if (objectKey) {
            if (typeof keyedRow[fieldKey] !== 'object') {
              keyedRow[fieldKey] = {}
            }
            keyedRow[fieldKey][objectKey] = row[key]
          } else {
            keyedRow[fieldKey] = row[key]
          }
        }
      }
      keyedDataArray.push(keyedRow)
    }
    keyedData.value = keyedDataArray
  }
})

/**
 * Building step
 */
const preppedKeyData = ref<Array<{ [key: string]: any }>>()
const fullKeyData = ref<Array<{ [key: string]: any }>>()
const buildingsCheck = ref<Array<string>>()
const checkBuilding = ({
  create,
  data
}: {
  create: Array<string>
  data: Array<{ [key: string]: any }>
}) => {
  keyedData.value = data
  buildingsCheck.value = create
}
watch([buildingsCheck], async () => {
  if (buildingsCheck.value) {
    const payload: Array<Partial<Building>> = []
    for (const buildingName of buildingsCheck.value) {
      payload.push({
        slug: slugify(buildingName),
        name: buildingName
      })
    }
    const patched = await patchBuildings(payload)
    await listBuildings()
    const copy = [...keyedData.value!]

    for (const row of copy) {
      for (const key in row) {
        const field = fields.data!.find((f) => f.key === key)!
        if (key === 'building') {
          const identifier = row[key]
          const building = patched.find((b) => {
            return (
              b.id === parseInt(identifier) ||
              b.slug === identifier.toString().toLowerCase() ||
              b.name.toLowerCase() === identifier.toString().toLowerCase()
            )
          })
          if (building) {
            row[key] = building.id
          }
        } else if (['number', 'area', 'currency'].includes(field.type)) {
          row[key] = parseFloat(row[key])
        } else if (field.type === 'status') {
          const identifier = row[key]
          const status = statuses.data!.find((s) => {
            if (s.id === parseInt(identifier)) {
              return true
            } else {
              for (const lang in s.label) {
                if (s.label[lang].toLowerCase() === identifier.toString().toLowerCase()) {
                  return true
                }
              }
            }
            return false
          })
          row[key] = status?.id || statuses.data![0].id
        } else if (field.type === 'date') {
          row[key] = new Date(row[key]).toISOString()
        }
      }
    }

    preppedKeyData.value = copy
    fullKeyData.value = await patchUnits(preppedKeyData.value, undefined, false)
  }
})

/**
 * Unit step
 */
const unitsCheck = ref<Array<{ [key: string]: any }>>()
const commit = async (selected: Array<string>) => {
  unitsCheck.value = await patchUnits(
    preppedKeyData.value!.filter((p) => selected.includes(p.unitId))
  )
  await listUnits()
}
</script>

<style scoped>
:deep(.v-stepper-item) {
  padding-top: 10px;
  padding-bottom: 10px;
}
:deep(.v-stepper-header) {
  box-shadow: none;
}
</style>
