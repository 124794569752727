import { useFetch } from '@vueuse/core'
import { createFetch } from '@vueuse/core'
import type { App, InjectionKey } from 'vue'
import { Api } from '@/injectionKeys'

export const QUEUE_SESSION_ID = crypto.randomUUID()

function createApi(options: { symbol: InjectionKey<typeof useFetch> }) {
  return (app: App) => {
    const keycloakProvider = app.config.globalProperties.$keycloak
    const apiFetch = createFetch({
      baseUrl: import.meta.env.VITE_API_URL,
      options: {
        async beforeFetch(ctx) {
          if (keycloakProvider.keycloak.isTokenExpired(60)) {
            await keycloakProvider.refreshToken()
          }

          if (keycloakProvider.keycloak.authenticated) {
            const headers: any = {}
            headers.Authorization = `Bearer ${keycloakProvider.keycloak.token}`
            if (!(ctx.options.body instanceof FormData)) {
              headers.Accept = 'application/json'
              headers['Content-Type'] = 'application/json'
              headers['x-queue-session-token'] = QUEUE_SESSION_ID
            }
            ctx.options.headers = {
              ...ctx.options.headers,
              ...headers
            }
          }
        },
        immediate: false
      },
      fetchOptions: {
        mode: 'cors',
        credentials: 'include',
        headers: {}
      }
    })

    app.provide(options.symbol, apiFetch)
    app.config.globalProperties.$api = apiFetch
  }
}

const api = createApi({ symbol: Api })
export default api
