import type { RouteRecordRaw } from 'vue-router'
import crmIntegrationRoute from './crmIntegration'
import marketingIntegrationRoute from './marketingIntegration'
import webhookIntegrationRoute from './webhookIntegration'

const integrationsRoute: RouteRecordRaw = {
  path: '/integrations',
  name: 'integrations',
  redirect: { name: 'crmIntegration' },
  meta: {
    breadcrumb: 'integrations',
    menu: {
      key: 'integrations',
      icon: 'mdi-lightning-bolt',
      name: 'integrations'
    }
  },
  children: [crmIntegrationRoute, marketingIntegrationRoute, webhookIntegrationRoute]
}

export default integrationsRoute
