import type { RouteRecordRaw } from 'vue-router'
import FieldCreateView from '@/views/project/settings/fields/FieldCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createFieldRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createField',
  component: FieldCreateView,
  meta: {
    breadcrumb: 'createField',
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default createFieldRoute
