import type { RouteRecordRaw } from 'vue-router'
import ContactUpdateView from '@/views/project/settings/contacts/ContactUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateContactRoute: RouteRecordRaw = {
  path: ':contactId',
  name: 'updateContact',
  component: ContactUpdateView,
  meta: {
    breadcrumb: 'updateContact',
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default updateContactRoute
