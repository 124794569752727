<template>
  <v-row class="w-100">
    <v-col cols="12">
      <v-table>
        <thead>
          <tr>
            <th>{{ $t('id') }}</th>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('unitId') }}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) of data" :key="index">
            <td>
              {{ row.id }}
            </td>
            <td>
              {{ row.title }}
            </td>
            <td>
              {{ row.unitId }}
            </td>
            <td class="text-right">
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <router-link
                    v-bind="props"
                    :to="{ name: 'updateUnit', params: { unitId: row.id } }"
                  >
                    <vis-view-icon-btn />
                  </router-link>
                </template>
                {{ $t('view') }}
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { inject, type Ref } from 'vue'
import type { RouterLink } from 'vue-router'

const { data } = defineProps<{
  previous: Array<{ [key: string]: any }>
  data: Array<{ [key: string]: any }>
}>()

const emit = defineEmits<{
  again: []
}>()

const backLabel = inject<Ref<string>>('backLabel')
const backHandler = inject<Ref<undefined | (() => void)>>('backHandler')
backLabel!.value = ''
backHandler!.value = undefined

const nextLabel = inject<Ref<string>>('nextLabel')
const nextHandler = inject<Ref<undefined | (() => void)>>('nextHandler')
nextLabel!.value = 'again'
nextHandler!.value = () => {
  emit('again')
}
</script>
