import { useRouter, type RouteLocationRaw } from 'vue-router'

export default function useSubmitThenRoute<Input extends Array<any>, Output>(
  promise: (...input: Input) => Promise<Output>,
  routeAction:
    | RouteLocationRaw
    | ((output: Output) => RouteLocationRaw | Promise<RouteLocationRaw>),
  afterAction?: (output: Output) => void
) {
  const router = useRouter()
  return async (...input: Input) => {
    const output = await promise(...input)
    const target = typeof routeAction === 'function' ? await routeAction(output) : routeAction
    router.push(target)
    if (afterAction) {
      afterAction(output)
    }
  }
}
