import type { RouteRecordRaw } from 'vue-router'
import UnitCreateView from '@/views/project/units/UnitCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createUnitRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createUnit',
  component: UnitCreateView,
  meta: {
    breadcrumb: 'createUnit',
    scopes: [KeycloakScope.UNIT_CREATE]
  }
}

export default createUnitRoute
