<template>
  <vis-data-table :headers="columns" :items="[...cameras]">
    <template #bottom />
    <template #body="{ items }">
      <template v-if="cameras.length > 0">
        <draggable :list="[...items]" item-key="id" tag="tbody" @change="onReorder">
          <template #item="{ element: camera }: { element: any }">
            <tr class="v-data-table__tr v-data-table__tr--clickable">
              <td class="v-data-table__td v-data-table-column--align-center">
                <v-btn color="primary" @click="emit('camera', camera.id)">{{
                  camera.title || camera.id
                }}</v-btn>
              </td>
              <td
                v-for="interiorOption of interiorOptions"
                :class="{
                  'v-data-table__td v-data-table-column--align-center': true,
                  'bg-success': isSelected(interiorOption.guid, camera.id)
                }"
                :key="interiorOption.id"
              >
                <div
                  v-if="getImage(interiorOption.guid, camera.id)"
                  class="pa-2 pb-0 position-relative remove-button-container"
                >
                  <img :src="getImage(interiorOption.guid, camera.id)!" />
                  <vis-remove-icon-btn
                    class="remove-button"
                    @click.stop="emit('delete', interiorOption.guid, camera.id)"
                  />
                </div>
                <span v-else>{{ $t('useTheVirtualTourPlayerToMakeASnapshot') }}</span>
              </td>
              <td class="v-data-table__td v-data-table-column--align-end">
                <v-tooltip location="left" v-if="hasImages(camera.id)">
                  <template #activator="{ props }">
                    <vis-drag-icon-btn v-bind="props" />
                  </template>
                  {{ $t('dragToReorder') }}
                </v-tooltip>
              </td>
            </tr>
          </template>
        </draggable>
      </template>
      <template v-else>
        <div class="pa-4">{{ $t('empty') }}</div></template
      >
    </template>
  </vis-data-table>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import Draggable from 'vuedraggable'
import type InteriorOption from '@/types/interiorOption'
import type Project from '@/types/project'
import type Unit from '@/types/unit'
import useI18nTranslator from '@/composables/useI18nTranslator'

const props = defineProps<{
  project: Project
  unit: Unit
  interiorOptions: Array<InteriorOption>
  apartmentData: { cameras: Array<{ id: string; type: string }> }
  thumbnailData: { cameras: Array<{ id: string }> }
  currentInteriorId?: string
  currentCameraId?: string
}>()

const emit = defineEmits<{
  camera: [cameraId: string]
  delete: [interiorId: string, cameraId: string]
  reorder: [cameraIds: Array<string>]
}>()

const t = useI18nTranslator()

const currentInteriorId = computed(() => props.currentInteriorId)
const currentCameraId = computed(() => props.currentCameraId)
const cameras = ref(
  [...props.apartmentData.cameras].sort((a, b) => {
    const indexA = props.thumbnailData.cameras.findIndex((c) => a.id === c.id)
    const indexB = props.thumbnailData.cameras.findIndex((c) => b.id === c.id)
    if (indexA === -1 && indexB === -1) {
      return 0
    } else if (indexA === -1) {
      return 1
    } else if (indexB === -1) {
      return -1
    } else {
      return indexA - indexB
    }
  })
)

const columns: any = [
  { title: '', key: 'cross', sortable: false },
  ...props.interiorOptions.map((c: any) => ({
    title: t(c.title),
    key: c.guid,
    sortable: false,
    align: 'center'
  })),
  { title: '', key: 'actions', sortable: false }
]

const onReorder = (event: Moved<any>) => {
  const copy = [...cameras.value]
  const left = copy[event.moved.oldIndex]
  const right = copy[event.moved.newIndex]
  copy[event.moved.oldIndex] = right
  copy[event.moved.newIndex] = left
  cameras.value = copy
  emit(
    'reorder',
    copy.map((c) => c.id)
  )
}

function isSelected(interiorId: string, cameraId: string) {
  return interiorId === currentInteriorId.value && cameraId === currentCameraId.value
}

function getImage(interiorId: string, cameraId: string) {
  const apartment = props.apartmentData.cameras.find((c) => c.id === cameraId)
  if (apartment?.type === 'feature') {
    return `${props.project.dataRoot}/${interiorId}/${props.unit.unitId}/thumbs/150/${cameraId}.jpg`
  } else {
    return null
  }
}

function hasImages(cameraId: string) {
  const apartment = props.apartmentData.cameras.find((c) => c.id === cameraId)
  return apartment?.type === 'feature'
}

onMounted(() => {
  if (props.interiorOptions.length > 0) {
    const sortableTbody = document.querySelector('.v-table__wrapper > table > tbody > tbody')
    const tbody = document.querySelector('.v-table__wrapper > table > tbody')
    tbody!.parentNode!.append(sortableTbody!)
    tbody!.remove()
  }
})
</script>

<style scoped>
.remove-button-container .remove-button {
  position: absolute;
  right: 0;
  visibility: hidden;
}

.remove-button-container:hover .remove-button {
  visibility: visible;
}
</style>
