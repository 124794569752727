import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Status from '@/types/status'
import executeApi from '@/utils/executeApi'

const useStatusStore = defineStore('status', () => {
  const api = useApi()

  const statusesUrl = ref('/project')
  const statusesApi = api<Array<Status>>(statusesUrl).get().json()
  const statuses: FetchResult<Array<Status>> = reactiveFetchResult(statusesApi)
  const listStatuses = async (projectId?: number): Promise<FetchResult<Array<Status>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    statusesUrl.value = `/project/${projectParam}/status`
    await executeApi(statusesApi)
    return statuses
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<Status>>(patchUrl).json()
  const patch: FetchResult<Array<Status>> = reactiveFetchResult(patchApi)
  const patchStatuses = async (
    data: Array<Partial<Status>>,
    projectId?: number
  ): Promise<FetchResult<Array<Status>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/status`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const statusUrl = ref('/project')
  const statusApi = api<Array<Status>>(statusUrl).json()

  const status: FetchResult<Status> = reactiveFetchResult(statusApi)
  const readStatus = async (id?: number, projectId?: number): Promise<FetchResult<Status>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || status.data?.id
    statusUrl.value = `/project/${projectParam}/status/${idParam}`
    await executeApi(statusApi.get())
    return status
  }

  const createStatus = async (
    data: Partial<Status>,
    projectId?: number
  ): Promise<FetchResult<Status>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    statusUrl.value = `/project/${projectParam}/status`
    await executeApi(statusApi.post(data), true)
    return status
  }

  const updateStatus = async (
    data: Partial<Status>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<Status>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || status.data?.id
    statusUrl.value = `/project/${projectParam}/status/${idParam}`
    await executeApi(statusApi.put(data), true)
    return status
  }

  const deleteStatus = async (id?: number, projectId?: number): Promise<FetchResult<Status>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || status.data?.id
    statusUrl.value = `/project/${projectParam}/status/${idParam}`
    await executeApi(statusApi.delete(), false)
    status.data = null
    return status
  }

  const $reset = () => {
    resetFetchResult(statuses)
    resetFetchResult(status)
  }

  return {
    listStatuses,
    readStatus,
    createStatus,
    updateStatus,
    deleteStatus,
    patchStatuses,
    statuses,
    status,
    patch,
    $reset
  }
})

export default useStatusStore
