<template>
  <vis-loader :loading="user.isFetching || buildings.isFetching || roles.isFetching">
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <user-form :user="user.data!" :roles="roles.data!" @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useUserStore from '@/stores/user'
import UserForm from '@/components/forms/UserForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'
import useBuildingStore from '@/stores/building'
import useRoleStore from '@/stores/role'

const { user, listUsers, readUser, updateUser } = useUserStore()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { roles, listRoles } = useRoleStore()
roles.data || listRoles()

const onSubmit = useSubmitThenRoute(updateUser, { name: 'users' }, () => listUsers())

const userId = useRouteId('userId')
readUser(userId)
</script>
