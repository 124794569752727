<template>
  <vis-form ref="formRef" @submit.prevent="submit">
    <v-row>
      <v-col sm="6" cols="12" v-for="field of fields" :key="field.key">
        <vis-localized-input
          :input="apartmentDatasheetNotesObject[field.key]"
          #default="{ input, locale }"
        >
          <vis-text-field
            :placeholder="translator(field.label)"
            :label="translator(field.label)"
            v-model="input[locale]"
          />
        </vis-localized-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-right">
        <vis-submit-btn type="submit">{{ $t('submit') }}</vis-submit-btn>
      </v-col>
    </v-row>
  </vis-form>
</template>

<script setup lang="ts">
import { reactive } from 'vue'
import type ApartmentDatasheetNote from '@/types/apartmentDatasheetNote'
import VisLocalizedInput from '../inputs/VisLocalizedInput.vue'
import type Field from '@/types/field'
import useI18nTranslator from '@/composables/useI18nTranslator'

const props = defineProps<{
  fields: Array<Field>
  apartmentDatasheetNotes?: Array<ApartmentDatasheetNote>
}>()

const emit = defineEmits<{
  submit: [apartmentDatasheetNotes: Array<Partial<ApartmentDatasheetNote>>]
}>()

const translator = useI18nTranslator()

const fields = props.fields.filter(
  ({ key }) => !['id', 'building', 'unit', 'description', 'updatedAt'].includes(key)
)

const apartmentDatasheetNotesObject = reactive(
  fields.reduce<{ [key: string]: Translatable }>((acc, field) => {
    acc[field.key] = {}
    const adn = props.apartmentDatasheetNotes?.find((a) => a.field === field.key)
    if (adn) {
      acc[field.key] = adn.note
    }
    return acc
  }, {})
)

const submit = () => {
  const data: Array<Partial<ApartmentDatasheetNote>> = []
  for (const field of fields) {
    const key = field.key
    const note = key in apartmentDatasheetNotesObject ? apartmentDatasheetNotesObject[key] : {}
    const id = props.apartmentDatasheetNotes?.find((a) => a.field === key)?.id
    data.push({
      id,
      field: key,
      note
    })
  }
  emit('submit', data)
}
</script>
