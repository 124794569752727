<template>
  <vis-loader :loading="client.isFetching">
    <vis-sheet>
      <h4>{{ $t('general') }}</h4>
      <client-form @submit="onSubmit" />
    </vis-sheet>
  </vis-loader>
</template>

<script setup lang="ts">
import useClientStore from '@/stores/client'
import ClientForm from '@/components/forms/ClientForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { client, listClients, createClient } = useClientStore()

const onSubmit = useSubmitThenRoute(
  createClient,
  (b) => ({ name: 'clientUpdate', params: { clientId: b.data!.id } }),
  () => listClients()
)
</script>
