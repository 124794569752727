import { type PiniaPluginContext, type Store } from 'pinia'

const stores: Array<Store> = []

export function resetOnProjectChange({ store }: PiniaPluginContext) {
  if (store.$id !== 'project') {
    stores.push(store)
  } else {
    store.$onAction(({ name }) => {
      if (name !== 'listProjects') {
        stores.forEach((s) => s.$reset())
      }
    })
  }
}
