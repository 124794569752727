import type { RouteRecordRaw } from 'vue-router'
import BuildingCreateView from '@/views/project/buildings/BuildingCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createBuildingRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createBuilding',
  component: BuildingCreateView,
  meta: {
    breadcrumb: 'createBuilding',
    scopes: [KeycloakScope.BUILDING_CREATE]
  }
}
export default createBuildingRoute
