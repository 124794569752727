import Keycloak, { type KeycloakConfig } from 'keycloak-js'
import KeycloakScope from './KeycloakScope'

export default class KeycloakProvider {
  public keycloak: Keycloak

  constructor(options: KeycloakConfig, onReady: (authenticated: boolean) => void) {
    this.keycloak = new Keycloak(options)
    this.keycloak.onTokenExpired = () => {
      this.keycloak.updateToken(60)
    }
    this.keycloak.onReady = onReady
  }

  async init(onInitCallback: (keycloak: this) => void) {
    const scope = Object.values(KeycloakScope).join(' ')
    await this.keycloak.init({ onLoad: 'login-required', scope })
    onInitCallback(this)
  }

  async logout(url?: string) {
    await this.keycloak.logout({ redirectUri: url })
  }

  async refreshToken() {
    await this.keycloak.updateToken(60)
  }
}
