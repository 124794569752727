import type { RouteRecordRaw } from 'vue-router'
import SelectProjectView from '@/views/SelectProjectView.vue'

const selectProjectRoute: RouteRecordRaw = {
  path: '/selectProject',
  name: 'selectProject',
  component: SelectProjectView
}

export default selectProjectRoute
