<template>
  <vis-loader :loading="project.isFetching">
    <vis-sheet>
      <h4>{{ $t('projectSettings') }}</h4>
      <project-form
        :project="project.data!"
        @submit="updateProject"
        @set-logo="setLogo"
        @unset-logo="unsetLogo"
      />
    </vis-sheet>
    <vis-loader
      class="w-100"
      :loading="units.isFetching || buildings.isFetching || statuses.isFetching"
    >
      <vis-sheet>
        <h4>{{ $t('buildingChooser') }}</h4>
        <div class="w-100 d-flex align-center justify-center">
          <building-chooser
            :project="project.data!"
            :units="units.data!"
            :buildings="buildings.data!"
            :statuses="statuses.data!"
            :version="
              projectVersions.data && projectVersions.data.length > 0
                ? projectVersions.data[0].version
                : undefined
            "
          />
        </div>
      </vis-sheet>
    </vis-loader>
  </vis-loader>
</template>

<script setup lang="ts">
import ProjectForm from '@/components/forms/ProjectForm.vue'
import BuildingChooser from '@/components/players/BuildingChooser.vue'
import useBuildingStore from '@/stores/building'
import useProjectStore from '@/stores/project'
import useProjectVersionStore from '@/stores/projectVersion'
import useStatusStore from '@/stores/status'
import useUnitStore from '@/stores/unit'

const { project, updateProject, setProjectLogo, unsetProjectLogo } = useProjectStore()

const { units, listUnits } = useUnitStore()
units.data || listUnits()

const { buildings, listBuildings } = useBuildingStore()
buildings.data || listBuildings()

const { statuses, listStatuses } = useStatusStore()
statuses.data || listStatuses()

const { projectVersions, listProjectVersions } = useProjectVersionStore()
listProjectVersions()

function setLogo(file: File) {
  const formData = new FormData()
  formData.append('logo', file)
  setProjectLogo(formData)
}

function unsetLogo() {
  unsetProjectLogo()
}
</script>
