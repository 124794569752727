<template>
  <vis-loader :loading="timeOfDayOption.isFetching">
    <time-of-day-option-form @submit="onSubmit" />
  </vis-loader>
</template>

<script setup lang="ts">
import useTimeOfDayOptionStore from '@/stores/timeOfDayOption'
import TimeOfDayOptionForm from '@/components/forms/TimeOfDayOptionForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { timeOfDayOption, listTimeOfDayOptions, createTimeOfDayOption } = useTimeOfDayOptionStore()

const onSubmit = useSubmitThenRoute(
  createTimeOfDayOption,
  (b) => ({ name: 'updateTimeOfDayOption', params: { timeOfDayOptionId: b.data!.id } }),
  () => listTimeOfDayOptions()
)
</script>
