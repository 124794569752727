import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import type User from '@/types/user'
import useBuildingStore from './building'
import { ref } from 'vue'
import resetFetchResult from '@/utils/resetFetchResult'
import executeApi from '@/utils/executeApi'

const useBuildingPermissionStore = defineStore('buildingPermission', () => {
  const api = useApi()

  const buildingUsersUrl = ref('/project')
  const buildingUsersApi = api<Array<User>>(buildingUsersUrl).get().json()
  const buildingUsers: FetchResult<Array<User>> = reactiveFetchResult(buildingUsersApi)
  const listBuildingUsers = async (
    buildingId?: number,
    projectId?: number
  ): Promise<FetchResult<Array<User>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    buildingUsersUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/permission`
    await executeApi(buildingUsersApi.get())
    return buildingUsers
  }

  const grantBuildingPermissions = async (
    email: string,
    buildingId?: number,
    projectId?: number
  ): Promise<boolean> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    const response = await executeApi(
      api<boolean>(
        `/project/${projectIdParam}/building/${buildingIdParam}/permission/${email}`
      ).put(),
      true
    )
    return response.json()
  }

  const revokeBuildingPermissions = async (
    email: string,
    buildingId?: number,
    projectId?: number
  ): Promise<boolean> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    const response = await executeApi(
      api<boolean>(
        `/project/${projectIdParam}/building/${buildingIdParam}/permission/${email}`
      ).delete(),
      true
    )
    return response.json()
  }

  const $reset = () => {
    resetFetchResult(buildingUsers)
  }

  return {
    buildingUsers,
    listBuildingUsers,
    grantBuildingPermissions,
    revokeBuildingPermissions,
    $reset
  }
})

export default useBuildingPermissionStore
