import type { App, InjectionKey } from 'vue'
import { Keycloak } from '@/injectionKeys'
import useAuthStore from '@/stores/auth'
import KeycloakProvider from './KeycloakProvider'

function createKeycloak(options: {
  symbol: InjectionKey<KeycloakProvider>
  onReady: (authenticated: boolean) => void
}) {
  return (app: App) => {
    const keycloakProvider = new KeycloakProvider(
      {
        realm: import.meta.env.VITE_KC_REALM,
        url: import.meta.env.VITE_KC_URL,
        clientId: import.meta.env.VITE_KC_CLIENT_ID
      },
      options.onReady
    )
    app.provide(options.symbol, keycloakProvider)
    app.config.globalProperties.$keycloak = keycloakProvider
  }
}

const keycloak = createKeycloak({
  symbol: Keycloak,
  onReady: (authenticated) => {
    const authStore = useAuthStore()
    authStore.ready = true
    authStore.authenticated = authenticated
  }
})

export default keycloak
