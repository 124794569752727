import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type InteriorOption from '@/types/interiorOption'
import executeApi from '@/utils/executeApi'

const useInteriorOptionStore = defineStore('interiorOption', () => {
  const api = useApi()

  const interiorOptionsUrl = ref('/project')
  const interiorOptionsApi = api<Array<InteriorOption>>(interiorOptionsUrl).get().json()
  const interiorOptions: FetchResult<Array<InteriorOption>> =
    reactiveFetchResult(interiorOptionsApi)
  const listInteriorOptions = async (
    projectId?: number
  ): Promise<FetchResult<Array<InteriorOption>>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    interiorOptionsUrl.value = `/project/${projectParam}/interiorOption`
    await executeApi(interiorOptionsApi)
    return interiorOptions
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<InteriorOption>>(patchUrl).json()
  const patch: FetchResult<Array<InteriorOption>> = reactiveFetchResult(patchApi)
  const patchInteriorOptions = async (
    data: Array<Partial<InteriorOption>>,
    projectId?: number
  ): Promise<FetchResult<Array<InteriorOption>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/interiorOption`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch
  }

  const interiorOptionUrl = ref('/project')
  const interiorOptionApi = api<Array<InteriorOption>>(interiorOptionUrl).json()
  const interiorOption: FetchResult<InteriorOption> = reactiveFetchResult(interiorOptionApi)
  const readInteriorOption = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<InteriorOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || interiorOption.data?.id
    interiorOptionUrl.value = `/project/${projectParam}/interiorOption/${idParam}`
    await executeApi(interiorOptionApi.get())
    return interiorOption
  }

  const createInteriorOption = async (
    data: Partial<InteriorOption>,
    projectId?: number
  ): Promise<FetchResult<InteriorOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    interiorOptionUrl.value = `/project/${projectParam}/interiorOption`
    await executeApi(interiorOptionApi.post(data), true)
    return interiorOption
  }

  const updateInteriorOption = async (
    data: Partial<InteriorOption>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<InteriorOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || interiorOption.data?.id
    interiorOptionUrl.value = `/project/${projectParam}/interiorOption/${idParam}`
    await executeApi(interiorOptionApi.put(data), true)
    return interiorOption
  }

  const deleteInteriorOption = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<InteriorOption>> => {
    let projectParam = projectId
    if (!projectParam) {
      const projectStore = useProjectStore()
      projectParam = projectStore.project.data!.id
    }
    const idParam = id || interiorOption.data?.id
    interiorOptionUrl.value = `/project/${projectParam}/interiorOption/${idParam}`
    await executeApi(interiorOptionApi.delete(), false)
    interiorOption.data = null
    return interiorOption
  }

  const $reset = () => {
    resetFetchResult(interiorOptions)
    resetFetchResult(interiorOption)
  }

  return {
    listInteriorOptions,
    readInteriorOption,
    createInteriorOption,
    updateInteriorOption,
    deleteInteriorOption,
    patchInteriorOptions,
    interiorOptions,
    interiorOption,
    patch,
    $reset
  }
})

export default useInteriorOptionStore
