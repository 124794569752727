export enum ProjectImagesCategory {
  ProjectLogo = 'projectLogo',
  Contacts = 'contacts',
  UnitFeaturedImage = 'unitFeaturedImage',
  ContentAssets = 'contentAssets',
  FloorPlans = 'floorplans'
}

export default interface Image {
  id: number
  projectId: number
  category: ProjectImagesCategory
  filename: string
  order: number
  updatedAt: string
  createdAt: string
}
