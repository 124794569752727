import { ref } from 'vue'
import { defineStore } from 'pinia'

export type BaseMessage = { message: string; color: string; action?: string }
export type Message = BaseMessage & { id: string }
type Breadcrumb = { title: string; to: string }

const useAppStore = defineStore('app', () => {
  const appVersion = import.meta.env.VITE_APP_VERSION
  const messages = ref<Array<Message>>([])
  const breadcrumbs = ref<Array<Breadcrumb>>([])

  const addMessage = (message: string | Error | BaseMessage) => {
    const m: Partial<Message> = {
      id: crypto.randomUUID()
    }
    if (message instanceof Error) {
      m.message = message.message
      m.color = 'error'
    } else if (typeof message === 'string') {
      m.message = message
      m.color = 'success'
    } else {
      m.message = message.message
      m.color = message.color
      m.action = message.action
    }
    messages.value.push(m as Message)
  }

  const removeMessage = (message: Message | string) => {
    messages.value = messages.value.filter(
      (m) => m.id !== (typeof message === 'string' ? message : message.id)
    )
  }

  const setBreadcrumbs = (crumbs: Array<Breadcrumb>) => {
    breadcrumbs.value = crumbs
  }

  const $reset = () => {
    messages.value = []
    breadcrumbs.value = []
  }

  return {
    messages,
    breadcrumbs,
    addMessage,
    removeMessage,
    setBreadcrumbs,
    appVersion,
    $reset
  }
})

export default useAppStore
