import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type Unit from '@/types/unit'
import executeApi from '@/utils/executeApi'

const useUnitRowStore = defineStore('unitRow', () => {
  const api = useApi()

  const unitsUrl = ref('/project')
  const unitsApi = api<Array<{ [key: string]: any }>>(unitsUrl).json()
  const units: FetchResult<Array<{ [key: string]: any }>> = reactiveFetchResult(unitsApi)

  const listUnits = async (
    projectId?: number
  ): Promise<FetchResult<Array<{ [key: string]: any }>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    unitsUrl.value = `/project/${projectIdParam}/unit/row`
    await executeApi(unitsApi.get())
    return units
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<{ [key: string]: any }>>(patchUrl).json()
  const patch: FetchResult<Array<{ [key: string]: any }>> = reactiveFetchResult(patchApi)

  const patchUnits = async (
    data: Array<Partial<{ [key: string]: any }>>,
    projectId?: number,
    commit: boolean = true
  ): Promise<Array<{ [key: string]: any }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/unit/row/unitId${commit === false ? '?commit=false' : ''}`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch.data!
  }

  const unitUrl = ref('/project')
  const unitApi = api<Array<{ [key: string]: any }>>(unitUrl).json()

  const unit: FetchResult<{ [key: string]: any }> = reactiveFetchResult(unitApi)
  const readUnit = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<{ [key: string]: any }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/row/${idParam}`
    await executeApi(unitApi.get())
    return unit
  }

  const createUnit = async (
    data: Partial<Unit>,
    projectId?: number
  ): Promise<FetchResult<{ [key: string]: any }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    unitUrl.value = `/project/${projectIdParam}/unit/row`
    await executeApi(unitApi.post(data), true)
    return unit
  }

  const updateUnit = async (
    data: Partial<{ [key: string]: any }>,
    id?: number,
    projectId?: number
  ): Promise<FetchResult<{ [key: string]: any }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/row/${idParam}`
    await executeApi(unitApi.put(data), true)
    return unit
  }

  const deleteUnit = async (
    id?: number,
    projectId?: number
  ): Promise<FetchResult<{ [key: string]: any }>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const idParam = id || unit.data?.id
    unitUrl.value = `/project/${projectIdParam}/unit/row/${idParam}`
    await executeApi(unitApi.delete(), false)
    unit.data = null
    return unit
  }

  const $reset = () => {
    resetFetchResult(units)
    resetFetchResult(unit)
  }

  return {
    listUnits,
    readUnit,
    createUnit,
    updateUnit,
    deleteUnit,
    patchUnits,
    units,
    unit,
    $reset
  }
})

export default useUnitRowStore
