import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import resetFetchResult from '@/utils/resetFetchResult'
import type ApartmentDatasheetNote from '@/types/apartmentDatasheetNote'
import useBuildingStore from './building'
import executeApi from '@/utils/executeApi'

const useApartmentDatasheetNoteStore = defineStore('apartmentDatasheetNote', () => {
  const api = useApi()

  const apartmentDatasheetNotesUrl = ref('/project')
  const apartmentDatasheetNotesApi = api<Array<ApartmentDatasheetNote>>(
    apartmentDatasheetNotesUrl
  ).json()
  const apartmentDatasheetNotes: FetchResult<Array<ApartmentDatasheetNote>> = reactiveFetchResult(
    apartmentDatasheetNotesApi
  )

  const listApartmentDatasheetNotes = async (
    buildingId?: number,
    projectId?: number
  ): Promise<FetchResult<Array<ApartmentDatasheetNote>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    apartmentDatasheetNotesUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/apartmentDatasheetNote`
    await executeApi(apartmentDatasheetNotesApi.get())
    return apartmentDatasheetNotes
  }

  const patchUrl = ref('/project')
  const patchApi = api<Array<ApartmentDatasheetNote>>(patchUrl).json()
  const patch: FetchResult<Array<ApartmentDatasheetNote>> = reactiveFetchResult(patchApi)

  const patchApartmentDatasheetNotes = async (
    data: Array<Partial<ApartmentDatasheetNote>>,
    buildingId?: number,
    projectId?: number
  ): Promise<Array<ApartmentDatasheetNote>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    patchUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/apartmentDatasheetNote`
    await executeApi(patchApi.patch(JSON.stringify(data)), true)
    return patch.data!
  }

  const apartmentDatasheetNoteUrl = ref('/project')
  const apartmentDatasheetNoteApi =
    api<Array<ApartmentDatasheetNote>>(apartmentDatasheetNoteUrl).json()
  const apartmentDatasheetNote: FetchResult<ApartmentDatasheetNote> =
    reactiveFetchResult(apartmentDatasheetNoteApi)

  const readApartmentDatasheetNote = async (
    id?: number,
    buildingId?: number,
    projectId?: number
  ): Promise<FetchResult<ApartmentDatasheetNote>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    const idParam = id || apartmentDatasheetNote.data?.id
    apartmentDatasheetNoteUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/apartmentDatasheetNote/${idParam}`
    await executeApi(apartmentDatasheetNoteApi.get())
    return apartmentDatasheetNote
  }

  const createApartmentDatasheetNote = async (
    data: Partial<ApartmentDatasheetNote>,
    buildingId?: number,
    projectId?: number
  ): Promise<FetchResult<ApartmentDatasheetNote>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    apartmentDatasheetNoteUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/apartmentDatasheetNote`
    await executeApi(apartmentDatasheetNoteApi.post(data))
    return apartmentDatasheetNote
  }

  const updateApartmentDatasheetNote = async (
    data: Partial<ApartmentDatasheetNote>,
    id?: number,
    buildingId?: number,
    projectId?: number
  ): Promise<FetchResult<ApartmentDatasheetNote>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    const idParam = id || apartmentDatasheetNote.data?.id
    apartmentDatasheetNoteUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/apartmentDatasheetNote/${idParam}`
    await executeApi(apartmentDatasheetNoteApi.put(data))
    return apartmentDatasheetNote
  }

  const deleteApartmentDatasheetNote = async (
    id?: number,
    buildingId?: number,
    projectId?: number
  ): Promise<FetchResult<ApartmentDatasheetNote>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    let buildingIdParam = buildingId
    if (!buildingIdParam) {
      const buildingStore = useBuildingStore()
      buildingIdParam = buildingStore.building.data!.id
    }
    const idParam = id || apartmentDatasheetNote.data?.id
    apartmentDatasheetNoteUrl.value = `/project/${projectIdParam}/building/${buildingIdParam}/apartmentDatasheetNote/${idParam}`
    await executeApi(apartmentDatasheetNoteApi.delete(), false)
    apartmentDatasheetNote.data = null
    return apartmentDatasheetNote
  }

  const $reset = () => {
    resetFetchResult(apartmentDatasheetNotes)
    resetFetchResult(apartmentDatasheetNote)
  }

  return {
    listApartmentDatasheetNotes,
    readApartmentDatasheetNote,
    createApartmentDatasheetNote,
    updateApartmentDatasheetNote,
    deleteApartmentDatasheetNote,
    patchApartmentDatasheetNotes,
    apartmentDatasheetNotes,
    apartmentDatasheetNote,
    $reset
  }
})

export default useApartmentDatasheetNoteStore
