import type { RouteRecordRaw } from 'vue-router'
import TimeOfDayOptionCreateView from '@/views/project/renderSettings/timeOfDayOptions/TimeOfDayOptionCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createTimeOfDayOptionRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createTimeOfDayOption',
  component: TimeOfDayOptionCreateView,
  meta: {
    breadcrumb: 'createTimeOfDayOption',
    scopes: [KeycloakScope.ASSETS_CREATE]
  }
}

export default createTimeOfDayOptionRoute
