import type { RouteRecordRaw } from 'vue-router'
import BuildingUpdateView from '@/views/project/buildings/BuildingUpdateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const updateBuildingRoute: RouteRecordRaw = {
  path: ':buildingId',
  name: 'updateBuilding',
  component: BuildingUpdateView,
  meta: {
    breadcrumb: 'updateBuilding',
    scopes: [KeycloakScope.BUILDING_UPDATE]
  }
}

export default updateBuildingRoute
