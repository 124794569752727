import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import resetFetchResult from '@/utils/resetFetchResult'
import type BuildingVersion from '@/types/buildingVersion'
import useProjectStore from './project'
import executeApi from '@/utils/executeApi'

const useBuildingVersionStore = defineStore('buildingVersion', () => {
  const api = useApi()

  const buildingVersionsUrl = ref('/building')
  const buildingVersionsApi = api<Array<BuildingVersion>>(buildingVersionsUrl).get().json()
  const buildingVersions: FetchResult<Array<BuildingVersion>> =
    reactiveFetchResult(buildingVersionsApi)
  const listBuildingVersions = async (
    projectId?: number
  ): Promise<FetchResult<Array<BuildingVersion>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    buildingVersionsUrl.value = `/project/${projectIdParam}/building/version`
    await executeApi(buildingVersionsApi)
    return buildingVersions
  }

  const $reset = () => resetFetchResult(buildingVersions)

  return {
    listBuildingVersions,
    buildingVersions,
    $reset
  }
})

export default useBuildingVersionStore
