import type { RouteRecordRaw } from 'vue-router'
import ValidationsView from '@/views/project/renderSettings/ValidationsView.vue'

const validationsRoute: RouteRecordRaw = {
  path: 'validations',
  name: 'validations',
  component: ValidationsView,
  meta: {
    breadcrumb: 'validations'
    // menu: {
    // {
    //   key: 'validations',
    //   icon: 'mdi-check-outline',
    //   name: 'validations'
    // }
    //},
    // scopes: [KeycloakScope.USER_CREATE]
  }
}

export default validationsRoute
