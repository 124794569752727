<template>
  <vis-loader :loading="timeOfDayOption.isFetching">
    <time-of-day-option-form :timeOfDayOption="timeOfDayOption.data!" @submit="onSubmit" />
  </vis-loader>
</template>

<script setup lang="ts">
import useRouteId from '@/composables/useRouteId'
import useTimeOfDayOptionStore from '@/stores/timeOfDayOption'
import TimeOfDayOptionForm from '@/components/forms/TimeOfDayOptionForm.vue'
import useSubmitThenRoute from '@/composables/useSubmitThenRoute'

const { timeOfDayOption, listTimeOfDayOptions, readTimeOfDayOption, updateTimeOfDayOption } =
  useTimeOfDayOptionStore()

const onSubmit = useSubmitThenRoute(updateTimeOfDayOption, { name: 'timeOfDayOptions' }, () =>
  listTimeOfDayOptions()
)

const timeOfDayOptionId = useRouteId('timeOfDayOptionId')
readTimeOfDayOption(timeOfDayOptionId)
</script>
