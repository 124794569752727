import type { RouteRecordRaw } from 'vue-router'
import ContactCreateView from '@/views/project/settings/contacts/ContactCreateView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const createContactRoute: RouteRecordRaw = {
  path: 'new',
  name: 'createContact',
  component: ContactCreateView,
  meta: {
    breadcrumb: 'createContact',
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default createContactRoute
