import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import useProjectStore from './project'
import type User from '@/types/user'
import { ref } from 'vue'
import resetFetchResult from '@/utils/resetFetchResult'
import executeApi from '@/utils/executeApi'

const useProjectPermissionStore = defineStore('projectPermission', () => {
  const api = useApi()

  const projectUsersUrl = ref('/project')
  const projectUsersApi = api<Array<User>>(projectUsersUrl).get().json()
  const projectUsers: FetchResult<Array<User>> = reactiveFetchResult(projectUsersApi)
  const listProjectUsers = async (projectId?: number): Promise<FetchResult<Array<User>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    projectUsersUrl.value = `/project/${projectIdParam}/permission`
    await executeApi(projectUsersApi.get())
    return projectUsers
  }

  const grantProjectPermissions = async (
    email: string,
    projectId?: number
  ): Promise<FetchResult<boolean>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const response = await executeApi(
      api<boolean>(`/project/${projectIdParam}/permission/${email}`).put(),
      true
    )
    return response.json()
  }

  const revokeProjectPermissions = async (
    email: string,
    projectId?: number
  ): Promise<FetchResult<boolean>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    const response = await executeApi(
      api<boolean>(`/project/${projectIdParam}/permission/${email}`).delete(),
      true
    )
    return response.json()
  }

  const $reset = () => {
    resetFetchResult(projectUsers)
  }

  return {
    projectUsers,
    listProjectUsers,
    grantProjectPermissions,
    revokeProjectPermissions,
    $reset
  }
})

export default useProjectPermissionStore
