import type { RouteRecordRaw } from 'vue-router'
import MediaView from '@/views/project/settings/MediaView.vue'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const mediaRoute: RouteRecordRaw = {
  path: 'media',
  name: 'media',
  component: MediaView,
  meta: {
    breadcrumb: 'media',
    menu: {
      key: 'media',
      icon: 'mdi-folder-multiple-image',
      name: 'media'
    },
    scopes: [KeycloakScope.PROJECT_UPDATE]
  }
}

export default mediaRoute
