import { h } from 'vue'
import type Building from '@/types/building'
import { RouterLink } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default function useProjectTableDefinitions(buildings: Array<Building>): VDataTableColumns {
  const { t } = useI18n()
  return [
    { title: t('id'), key: 'id', type: 'number', searchable: true, filterable: false },
    {
      title: t('building'),
      key: 'buildingId',
      type: 'number',
      customRenderer: ({ value }: any) => {
        const building = buildings?.find((b) => b.id === value)
        return building
          ? h('span', { data: { label: building.name } }, [
              h(
                RouterLink,
                {
                  class: 'text-primary',
                  to: { name: 'updateBuilding', params: { buildingId: building.id } }
                },
                () => building.name
              )
            ])
          : h('span', '-')
      }
    },
    { title: t('unitId'), key: 'unitId', type: 'id', searchable: true, filterable: false },
    { title: t('title'), key: 'title', type: 'id', searchable: true, filterable: false },
    { title: t('status'), key: 'status', type: 'status' },
    { title: t('createdAt'), key: 'createdAt', type: 'date', filterable: false },
    { title: t('updatedAt'), key: 'updatedAt', type: 'date', filterable: false },
    { title: '', key: 'actions', sortable: false }
  ]
}
