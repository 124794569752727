import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import resetFetchResult from '@/utils/resetFetchResult'
import type ArtistResource from '@/types/artistResource'
import executeApi from '@/utils/executeApi'

import { useFetch } from '@vueuse/core'

const useArtistResourceStore = defineStore('ArtistResource', () => {
  const api = useApi()

  const artistResourcesUrl = ref('/artistResource')
  const artistResourcesApi = api<Array<ArtistResource>>(artistResourcesUrl).get().json()
  const artistResources: FetchResult<Array<ArtistResource>> =
    reactiveFetchResult(artistResourcesApi)
  const listArtistResources = async (): Promise<FetchResult<Array<ArtistResource>>> => {
    await executeApi(artistResourcesApi)
    return artistResources
  }

  const artistResourceUrl = ref('/artistResource')
  const artistResourceApi = api<Array<ArtistResource>>(artistResourceUrl).json()
  const artistResource: FetchResult<ArtistResource> = reactiveFetchResult(artistResourceApi)
  const readArtistResource = async (id?: number): Promise<FetchResult<ArtistResource>> => {
    const idParam = id || artistResource.data?.id
    artistResourceUrl.value = `/artistResource/${idParam}/`
    await executeApi(artistResourceApi.get())
    return artistResource
  }

  const getThumbnail = async (url: string): Promise<any> => {
    try {
      const thumbnail = await useFetch(url).blob()

      return thumbnail
    } catch (e) {
      console.log(e)
    }
  }

  const $reset = () => {
    resetFetchResult(artistResources)
    resetFetchResult(artistResource)
  }

  return {
    listArtistResources,
    readArtistResource,
    artistResources,
    artistResource,
    getThumbnail,
    $reset
  }
})

export default useArtistResourceStore
