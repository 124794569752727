<template>
  <template v-if="route.name === 'users'">
    <vis-loader :loading="users.isFetching">
      <v-row>
        <v-col sm="3" cols="12">
          <data-table-filter
            :columns="columns"
            :items="items"
            @update="filtered = $event"
            search-on-bottom
          />
        </v-col>
        <v-col sm="9" cols="12">
          <data-table
            :columns="columns"
            :items="filtered"
            v-on="{
              add: check(KeycloakScope.USER_ASSIGN_PROJECTS) ? onAdd : undefined,
              select: check(KeycloakScope.USER_UPDATE) ? onSelect : undefined,
              delete: check(KeycloakScope.USER_ASSIGN_PROJECTS) ? onDelete : undefined
            }"
          >
            <template #top>
              <vis-form @submit.prevent="onSubmit" style="width: 50% !important">
                <vis-text-field
                  v-model="email"
                  :label="$t('addUserByEmail')"
                  :placeholder="$t('addUserByEmail')"
                  density="compact"
                />
              </vis-form>
            </template>
            <template #actions.append="{ item }">
              <v-tooltip location="left">
                <template #activator="{ props }">
                  <vis-icon-btn
                    class="ml-2"
                    icon="mdi-key-remove"
                    v-bind="props"
                    @click.stop="onRevoke(item)"
                  />
                </template>
                {{ $t('revoke') }}
              </v-tooltip>
            </template>
          </data-table>
        </v-col>
      </v-row>
    </vis-loader>
  </template>
  <template v-else>
    <router-view />
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import useUserStore from '@/stores/user'
import useUserTableDefinitions from '@/composables/tableDefinitions/useUserTableDefinitions'
import useWatchFetchResult from '@/composables/useWatchFetchResult'
import DataTable from '@/components/dataTable/DataTable.vue'
import DataTableFilter from '@/components/dataTable/DataTableFilter.vue'
import type User from '@/types/user'
import { useI18n } from 'vue-i18n'
import useProjectPermissionStore from '@/stores/projectPermission'
import useScopeCheck from '@/composables/useScopeCheck'
import KeycloakScope from '@/configs/keycloak/KeycloakScope'

const check = useScopeCheck()
const route = useRoute()
const router = useRouter()
const { t } = useI18n()

const { users, listUsers, deleteUser } = useUserStore()
users.data || listUsers()

const items = useWatchFetchResult(users, [])
const columns = computed(() => useUserTableDefinitions())
const filtered = ref<Array<User>>([])

const onAdd = () => router.push({ name: 'createUser' })

const onSelect = (user: User) =>
  router.push({
    name: 'updateUser',
    params: {
      userId: user.id
    }
  })

const onDelete = async (user: User) => {
  if (confirm(t('areYouSureYouWantToDeleteTheUser', [user.id]))) {
    await deleteUser(user.id)
    listUsers()
  }
}

const { grantProjectPermissions, revokeProjectPermissions } = useProjectPermissionStore()
const email = ref('')

const onSubmit = async () => {
  await grantProjectPermissions(email.value)
  email.value = ''
  listUsers()
}

const onRevoke = async (user: User) => {
  await revokeProjectPermissions(user.email)
  listUsers()
}
</script>
