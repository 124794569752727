enum KeycloakScope {
  USER_CREATE = 'user:create',
  USER_ASSIGN_CLIENTS = 'user:assign:clients',
  USER_ASSIGN_PROJECTS = 'user:assign:projects',
  USER_ASSIGN_BUILDINGS = 'user:assign:buildings',
  USER_ASSIGN_UNITS = 'user:assign:units',
  USER_ASSIGN_ROLE_ALL = 'user:assign:role:all',
  USER_ASSIGN_ROLE_CLIENTADMIN = 'user:assign:role:clientadmin',
  USER_ASSIGN_ROLE_ADMINISTRATOR = 'user:assign:role:administrator',
  USER_ASSIGN_ROLE_PROJECT_MANAGER = 'user:assign:role:project_manager',
  USER_ASSIGN_ROLE_MARKETER = 'user:assign:role:marketer',
  USER_ASSIGN_ROLE_BROKER = 'user:assign:role:broker',

  USER_READ = 'user:read',
  USER_UPDATE = 'user:update',
  USER_DELETE = 'user:delete',
  USER_LIST = 'user:list',

  CLIENT_CREATE = 'client:create',
  CLIENT_READ = 'client:read',
  CLIENT_UPDATE = 'client:update',
  CLIENT_DELETE = 'client:delete',
  CLIENT_LIST = 'client:list',

  PROJECT_CREATE = 'project:create',
  PROJECT_READ = 'project:read',
  PROJECT_UPDATE = 'project:update',
  PROJECT_DELETE = 'project:delete',
  PROJECT_LIST = 'project:list',

  PROJECT_UPDATE_INTEGRATION = 'project:update:integration',
  PROJECT_VALIDATION = 'project:validation',
  PROJECT_IMPORT = 'project:import',

  BUILDING_CREATE = 'building:create',
  BUILDING_READ = 'building:read',
  BUILDING_UPDATE = 'building:update',
  BUILDING_DELETE = 'building:delete',
  BUILDING_LIST = 'building:list',

  UNIT_CREATE = 'unit:create',
  UNIT_READ = 'unit:read',
  UNIT_UPDATE = 'unit:update',
  UNIT_DELETE = 'unit:delete',
  UNIT_LIST = 'unit:list',

  ASSETS_LIST = 'assets:list',
  ASSETS_CREATE = 'assets:create',
  ASSETS_READ = 'assets:read',
  ASSETS_UPDATE = 'assets:update',
  ASSETS_DELETE = 'assets:delete'
}

export default KeycloakScope
