import { ref } from 'vue'
import { defineStore } from 'pinia'
import useApi from '@/composables/useApi'
import reactiveFetchResult from '@/utils/reactiveFetchResult'
import resetFetchResult from '@/utils/resetFetchResult'
import type UnitVersion from '@/types/unitVersion'
import useProjectStore from './project'
import executeApi from '@/utils/executeApi'

const useUnitVersionStore = defineStore('unitVersion', () => {
  const api = useApi()

  const unitVersionsUrl = ref('/unit')
  const unitVersionsApi = api<Array<UnitVersion>>(unitVersionsUrl).get().json()
  const unitVersions: FetchResult<Array<UnitVersion>> = reactiveFetchResult(unitVersionsApi)
  const listUnitVersions = async (projectId?: number): Promise<FetchResult<Array<UnitVersion>>> => {
    let projectIdParam = projectId
    if (!projectIdParam) {
      const projectStore = useProjectStore()
      projectIdParam = projectStore.project.data!.id
    }
    unitVersionsUrl.value = `/project/${projectIdParam}/unit/version`
    await executeApi(unitVersionsApi)
    return unitVersions
  }

  const $reset = () => resetFetchResult(unitVersions)

  return {
    listUnitVersions,
    unitVersions,
    $reset
  }
})

export default useUnitVersionStore
